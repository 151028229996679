import {
  VehicleTelematicsProvider,
  VehicleOptionsQuery,
} from '~/graphql/generated/types'

export const isBluetoothBike = (
  supplierId: string,
  modelId: string,
  suppliers: Supplier[] | undefined
): [boolean, VehicleTelematicsProvider | null] => {
  const resolvedSupplierById = suppliers?.find(
    (supplier) => supplier.id === supplierId
  )
  const resolvedModelById = resolvedSupplierById?.vehicleModels.nodes.find(
    (model) => model.id === modelId
  )
  /*
    Why do we only check axa here?
    Why having axa=true means bike has bluetooth?
    Is this needs to be changed to something generic?
  */
  if (resolvedModelById?.axa) {
    return [true, VehicleTelematicsProvider.Axa]
  }

  return [false, null]
}

export type Supplier = NonNullable<VehicleOptionsQuery['suppliers']>['nodes'][0]
