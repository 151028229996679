import { Link } from 'react-router-dom'
import { Supplier, VehicleModel, Vehicle } from '~/graphql/generated/types'
import Table from '~/components/Table'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Permission, usePermissions } from '~/hooks'
import ArchiveVehicles from '~/components/ArchiveVehicle/ArchiveVehicles'

export type TableVehicle = Pick<
  Vehicle,
  'id' | 'sku' | 'operationalStatus' | 'hubSlug' | 'bluetooth'
> & {
  vehicleSupplier?: Pick<Supplier, 'name'> | null
  vehicleModel?: Pick<VehicleModel, 'name'> | null
  damages?: { totalCount: number }
}

type Props = {
  vehicles: TableVehicle[]
  refetchVehicles: () => void
}

export const COLUMNS = [
  'sku',
  'operationalStatus',
  'supplier',
  'model',
  'hubSlug',
  'bluetooth',
  'reportedDamages',
]

const VehicleTable = ({ vehicles, refetchVehicles }: Props) => {
  const { t } = useTranslation()
  const { isAllowed } = usePermissions()

  const [selectedVehicles, setSelectedVehicles] = useState<Array<TableVehicle>>(
    []
  )

  const headers = COLUMNS.map((column) => t(`vehicle.model.${column}`))

  const toggleAllCheckboxes = (checked: boolean) => {
    if (checked) {
      setSelectedVehicles(vehicles)
    } else {
      setSelectedVehicles([])
    }
  }

  const toggleCheckbox = (vehicle: TableVehicle, checked: boolean) => {
    if (checked) {
      setSelectedVehicles([...selectedVehicles, vehicle])
    } else {
      setSelectedVehicles(
        selectedVehicles.filter(
          (selectedVehicle: TableVehicle) => selectedVehicle.id !== vehicle.id
        )
      )
    }
  }

  const isChecked = (vehicle: TableVehicle) =>
    !!selectedVehicles.find(
      (selectedVehicle: TableVehicle) => selectedVehicle.id === vehicle.id
    )

  const refetchAndClear = () => {
    setSelectedVehicles([])
    refetchVehicles()
  }

  const cells = (vehicle: TableVehicle) => (
    <>
      <td className="p-1">
        <input
          className="border border-gray-300 rounded-sm focus:rounded-sm bg-white focus:outline-none mt-1 mr-2 cursor-pointer"
          type="checkbox"
          title={t('ArchiveVehicles.checkbox.tooltip')}
          onChange={(e) => {
            toggleCheckbox(vehicle, e.target.checked)
          }}
          checked={isChecked(vehicle)}
          data-testid={`checkbox-${vehicle.id}`}
        />
      </td>
      <td className="max-w-32">
        <Link
          to={vehicle.id}
          className="p-4 pl-1 block truncate"
          title={vehicle.sku ?? ''}
        >
          {vehicle.sku}
        </Link>
      </td>
      <td>
        <Link to={vehicle.id} className="p-4 pl-1 block">
          {t(`vehicle.operationalStatus.${vehicle.operationalStatus}`)}
        </Link>
      </td>
      <td>
        <Link to={vehicle.id} className="p-4 pl-1 block">
          {vehicle.vehicleSupplier?.name || '-'}
        </Link>
      </td>
      <td>
        <Link to={vehicle.id} className="p-4 pl-1 block">
          {vehicle.vehicleModel?.name || '-'}
        </Link>
      </td>
      <td>
        <Link to={vehicle.id} className="p-4 pl-1 block">
          {vehicle.hubSlug || '-'}
        </Link>
      </td>
      <td>
        <Link to={vehicle.id} className="p-4 pl-1 block">
          {t(vehicle.bluetooth.toString())}
        </Link>
      </td>
      <td>
        <Link to={vehicle.id} className="p-4 pl-1 block">
          {vehicle?.damages?.totalCount || '-'}
        </Link>
      </td>
    </>
  )

  return (
    <div className="mt-8 max-w-full overflow-x-auto overflow-y-hidden relative">
      <ArchiveVehicles
        vehicles={selectedVehicles}
        isDisabled={
          !selectedVehicles.length ||
          !isAllowed([Permission.WRITE_VEHICLES_ALL])
        }
        refetchVehicles={refetchAndClear}
      />
      <Table
        headers={headers}
        rows={vehicles}
        cells={cells}
        toggleAllCheckboxes={toggleAllCheckboxes}
      />
    </div>
  )
}

export default VehicleTable
