import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Table from '~/components/Table'
import { Spinner } from '~/components/Spinner'
import Pagination from '~/components/Pagination'
import {
  useVehicleAssignmentsQuery,
  VehicleAssignment,
} from '~/graphql/generated/types'
import { formatRiderName } from '~/utils/riders'
import format from 'date-fns/format'

const COLUMNS = ['name', 'id', 'employeeNumber', 'assignedAt', 'unassignedAt']
const LIMIT = 10

type Props = {
  vehicleId: string
}

const AssignmentHistoryTable = ({ vehicleId }: Props) => {
  const { t } = useTranslation()
  const [pagination, setPagination] = useState({ page: 0, limit: LIMIT })

  const { data, previousData, loading, error } = useVehicleAssignmentsQuery({
    variables: {
      limit: pagination.limit,
      offset: pagination.page * pagination.limit,
      id: vehicleId,
    },
    fetchPolicy: 'no-cache',
  })

  const pageCount = Math.ceil(
    (data?.vehicleAssignments?.totalCount || 0) / pagination.limit
  )

  const assignments = loading
    ? previousData?.vehicleAssignments?.nodes
    : data?.vehicleAssignments?.nodes

  const headers = COLUMNS.map((column) => t(`rider.${column}`))

  const cells = (assignment: VehicleAssignment) => {
    const cellDecorationCSS = !assignment.unassignedAt
      ? 'border-y border-green p-1 first-of-type:border-l first-of-type:rounded-l last-of-type:border-r last-of-type:rounded-r'
      : ''

    return (
      <>
        <td className={`relative p-3 ${cellDecorationCSS}`}>
          <div className="w-max">
            {!assignment.unassignedAt && (
              <div className="h-3 w-3 bg-green rounded-full absolute -left-6 top-0 bottom-0 m-auto"></div>
            )}
            {assignment.rider?.firstName && assignment?.rider?.lastName
              ? formatRiderName(
                  assignment.rider.firstName,
                  assignment.rider.lastName
                )
              : '-'}
          </div>
        </td>
        <td className={` p-3 ${cellDecorationCSS}`}>
          <div>{assignment.riderId}</div>
        </td>
        <td className={`p-3 ${cellDecorationCSS}`}>
          <div>{assignment.rider?.employeeNumber ?? '-'}</div>
        </td>
        <td className={`p-3 ${cellDecorationCSS}`}>
          <div className="w-max">
            {format(new Date(assignment.assignedAt), 'PP HH:mm')}
          </div>
        </td>
        <td className={`p-3 ${cellDecorationCSS}`}>
          <div className="w-max">
            {assignment.unassignedAt
              ? format(new Date(assignment.unassignedAt), 'PP HH:mm')
              : '-'}
          </div>
        </td>
      </>
    )
  }

  return (
    <div className="relative">
      {loading && (
        <div className="absolute inset-0 w-screen h-screen flex justify-center items-center z-50">
          <Spinner size={10} />
        </div>
      )}
      {error && (
        <div className="flex flex-col justify-center text-white text-xs">
          {t(error.message)}
        </div>
      )}
      {assignments && (
        <Table
          headers={headers}
          rows={(assignments as VehicleAssignment[]) || []}
          cells={cells}
          customRowStyling={'border-0'}
        />
      )}
      {pageCount > 0 && (
        <Pagination
          totalCount={data?.vehicleAssignments?.totalCount || 0}
          pageLimit={pagination.limit}
          pageIndex={pagination.page}
          pageCount={pageCount}
          setPageSize={(size) => setPagination({ page: 0, limit: size })}
          gotoPage={(page) => setPagination({ ...pagination, page })}
          previousPage={() =>
            setPagination({ ...pagination, page: pagination.page - 1 })
          }
          nextPage={() =>
            setPagination({ ...pagination, page: pagination.page + 1 })
          }
          canPreviousPage={pagination.page > 0}
          canNextPage={pagination.page < pageCount - 1}
        />
      )}
    </div>
  )
}

export default AssignmentHistoryTable
