import {
  VehicleTelematicsProvider,
  VehicleKind,
} from '~/graphql/generated/types'

export const vehicleTelematicsProviders: {
  value: VehicleTelematicsProvider
  label: string
}[] = Object.values(VehicleTelematicsProvider).map((value) => ({
  value,
  label: value,
}))

export const vehicleKinds: {
  value: VehicleKind
  label: string
}[] = [
  { value: VehicleKind.Bicycle, label: VehicleKind.Bicycle },
  { value: VehicleKind.Scooter, label: VehicleKind.Scooter },
  { value: VehicleKind.Cargo, label: VehicleKind.Cargo },
]
