import { MinusCircleIcon } from '@heroicons/react/outline'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Modal from '~/components/Modal'
import {
  useArchiveVehicleMutation,
  useUpdateOperationalStatusMutation,
  Vehicle,
  VehicleOperationalStatus,
  VehicleDamagesDocument,
} from '~/graphql/generated/types'

type Props = {
  vehicle: Pick<Vehicle, 'id' | 'operationalStatus' | 'sku'>
  isDisabled?: boolean
}

const ArchiveVehicle = ({ vehicle, isDisabled }: Props) => {
  const { t } = useTranslation()
  const [isModalVisible, showModal] = useState(false)
  const [archiveVehicleMutation, { loading: isArchiving }] =
    useArchiveVehicleMutation({
      variables: { id: vehicle.id },
      onCompleted: () => {
        showModal(false)
      },
      refetchQueries: [
        { query: VehicleDamagesDocument, variables: { id: vehicle.id } },
      ],
    })
  const [updateOperationalStatusMutation, { loading: isUpdating }] =
    useUpdateOperationalStatusMutation({
      variables: {
        id: vehicle.id,
        operationalStatus: VehicleOperationalStatus.InTransfer,
      },
      onCompleted: () => {
        showModal(false)
      },
    })

  const canArchive =
    vehicle.operationalStatus === VehicleOperationalStatus.InTransfer

  return (
    <>
      <div className="text-sm flex flex-col w-fit">
        <span className="text-base text-center font-medium mb-1">
          {t('ArchiveVehicle.title')}
        </span>
        <button
          className="py-2 h-11 px-6 text-center cursor-pointer border-2 rounded border-darkBlue bg-charcoal"
          onClick={() => showModal(true)}
          disabled={isDisabled}
          data-testid="button"
        >
          <MinusCircleIcon className="w-5 h-5 inline text-gray-light" />
        </button>
      </div>
      <Modal
        isOpen={isModalVisible}
        onCancel={() => showModal(false)}
        submitText={
          canArchive
            ? t('ArchiveVehicle.archive.submit')
            : t('ArchiveVehicle.changeStatus.submit')
        }
        cancelText={t('cancel')}
        onConfirm={
          canArchive ? archiveVehicleMutation : updateOperationalStatusMutation
        }
        isDisabled={isArchiving || isUpdating}
      >
        <div className="px-6 pb-12">
          <div className="flex items-start">
            <div className="text-left">
              <h2 className="text-lg font-bold">
                {canArchive
                  ? t('ArchiveVehicle.archive.header')
                  : t('ArchiveVehicle.changeStatus.header')}
              </h2>
              <div className="mt-2">
                <p className="text-sm">
                  {canArchive
                    ? t('ArchiveVehicle.archive.message', {
                        sku: vehicle.sku,
                      })
                    : t('ArchiveVehicle.changeStatus.message')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ArchiveVehicle
