import { TFunction } from 'i18next'
import { VehicleOperationalStatus } from '~/graphql/generated/types'

export const operationalStatusOptions = (t: TFunction) =>
  Object.values(VehicleOperationalStatus).map((value) => ({
    value,
    label: t(`vehicle.operationalStatus.${value}`),
  }))

export const bluetoothOptions = (t: TFunction) =>
  ['all', 'true', 'false'].map((value) => ({
    value: value === 'all' ? '' : value,
    label: t(`${value}`),
  }))
