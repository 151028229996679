import { useEffect, useMemo } from 'react'
import { Link, useOutletContext, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import VehicleView from '~/components/VehicleView'
import { Spinner } from '~/components/Spinner'
import { useVehiclesQuery } from '~/graphql/generated/types'
import { usePermissions, Permission } from '~/hooks'
import { getSelectedHubSlug } from '~/utils/hubs'
import { getSearchParams } from '~/utils/search-params'
import { SearchParams } from './types'
import { useFilterOptions } from '~/utils/filter-options'
import { FORBIDDEN, GraphileError } from '~/utils/errors'
import { getInstance } from '@eppo/js-client-sdk'
import { FeatureFlags } from '~/utils/eppo'

export default function VehiclesIndex() {
  const { t } = useTranslation()
  const { permissions, isAllowed } = usePermissions()
  const eppoClient = getInstance()

  const portalHub = getSelectedHubSlug() as string
  const { search } = useOutletContext<{ search: string }>()
  const [searchParams, setSearchParams] = useSearchParams()

  const [hubOptions, supplierOptions] = useFilterOptions()

  const isMandatoryBikeAssigmentActive = useMemo(() => {
    return eppoClient.getBooleanAssignment(
      FeatureFlags.MANDATORY_BIKE_ASSIGNMENT_EPPO,
      portalHub?.toLowerCase(),
      {
        hub_slug: portalHub?.toLowerCase(),
      },
      false
    )
  }, [])

  const { limit, page, operationalStatus, supplier, bluetooth, hub } =
    getSearchParams(searchParams)

  const {
    data: vehicleData,
    loading,
    previousData: prevVehicleData,
    refetch,
    error,
  } = useVehiclesQuery({
    skip: !isAllowed([
      Permission.READ_VEHICLES_ALL,
      Permission.READ_VEHICLES_OWN,
    ]),
    variables: {
      limit,
      offset: page * limit,
      // fields with null are ignored when resolving the query (not interpreted as SQL NULL)
      operationalStatus: operationalStatus.length ? operationalStatus : null,
      unArchived: true,
      search: search && search !== '' ? search : null,
      supplierId: supplier.length ? supplier : null,
      bluetooth: bluetooth ? bluetooth === 'true' : null,
      hub: hub.length ? hub : null,
    },
    fetchPolicy: 'no-cache',
  })

  const vehicles = loading ? prevVehicleData?.vehicles : vehicleData?.vehicles

  const setParams = (searchParams: SearchParams) =>
    setSearchParams(
      {
        limit: (searchParams.limit ?? limit).toString(),
        page: (searchParams.page ?? page).toString(),
        operationalStatus:
          searchParams.operationalStatus ?? operationalStatus ?? '',
        search: searchParams.search ?? search ?? '',
        supplier: searchParams.supplier ?? supplier ?? '',
        bluetooth: searchParams.bluetooth ?? bluetooth ?? '',
        hub: searchParams.hub ?? hub ?? '',
      },
      { replace: true }
    )

  useEffect(() => {
    setParams({ search, page: 0 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    // filter vehicles by portal's hub if permissions are missing
    if (
      !isAllowed([Permission.READ_VEHICLES_ALL]) &&
      !hub.length &&
      portalHub
    ) {
      setParams({ hub: [portalHub] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions, portalHub, hub.length])

  const unauthorizedError =
    error && (error?.graphQLErrors as GraphileError[])[0]?.errcode === FORBIDDEN

  return (
    <>
      <div className="flex gap-x-2 text-center mb-3 items-center">
        <Link
          to="/vehicles/import"
          className={`primary-btn font-bold text-base ${
            !isAllowed([Permission.WRITE_VEHICLES_ALL]) &&
            'pointer-events-none bg-gray-200 text-gray-400 opacity-60'
          }`}
        >
          {t('VehicleImport.heading')}
        </Link>
        <Link
          to="/vehicles/new"
          className={`secondary-btn ml-3 font-bold text-white text-lg ${
            !isAllowed([Permission.WRITE_VEHICLES_ALL]) &&
            'pointer-events-none text-gray-400 opacity-60'
          }`}
        >
          {t('addVehicle')}
        </Link>
        {!isMandatoryBikeAssigmentActive && (
          <Link
            to="/vehicles/unassign"
            className={`secondary-btn ml-3 font-bold text-white text-lg ${
              !isAllowed([
                Permission.WRITE_VEHICLE_ASSIGNMENTS_ALL,
                Permission.WRITE_VEHICLE_ASSIGNMENTS_OWN,
              ]) && 'pointer-events-none text-gray-400 opacity-60'
            }`}
          >
            {t('VehicleUnassign.heading')}
          </Link>
        )}
      </div>
      {loading && (
        <div className="absolute inset-0 w-screen h-screen flex justify-center items-center z-50">
          <Spinner size={28} />
        </div>
      )}
      {unauthorizedError && (
        <div className="flex flex-col justify-center items-center text-white">
          <h1 className="font-semibold text-md mb-5">{t('unauthorized')}</h1>
          <p className="text-sm text-center">{t(error.message)}</p>
        </div>
      )}
      {vehicles && (
        <VehicleView
          vehicles={vehicles}
          refetchVehicles={refetch}
          setParams={setParams}
          pageLimit={limit}
          pageIndex={page}
          operationalStatus={operationalStatus}
          hubOptions={hubOptions}
          supplierOptions={supplierOptions}
          supplier={supplier}
          bluetooth={bluetooth}
          hub={hub}
        />
      )}
    </>
  )
}
