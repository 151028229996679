import { useEffect, useState } from 'react'

import { init } from '@eppo/js-client-sdk'

const EPPO_SDK_KEY = process.env.EPPO_SDK_KEY

interface EppoProviderProvider {
  waitForInitialization?: boolean
  children: JSX.Element
  loadingComponent?: JSX.Element
}

export default function EppoProvider({
  waitForInitialization = true,
  children,
  loadingComponent = <div></div>,
}: EppoProviderProvider): JSX.Element {
  const [isInitialized, setIsInitialized] = useState(false)
  useEffect(() => {
    init({
      apiKey: EPPO_SDK_KEY || '',
      assignmentLogger: {
        logAssignment(assignment) {
          console.log(assignment)
        },
      },
    }).then(() => {
      return setIsInitialized(true)
    })
  }, [])

  if (!waitForInitialization || isInitialized) {
    return children
  }
  return loadingComponent
}
