import {
  VehicleOperationalStatus,
  VehicleAvailabilityStatus,
  DamageStatus,
} from '~/graphql/generated/types'

type BadgeColors = {
  [key: string]: string
}

export const BADGE_COLOR: BadgeColors = {
  [VehicleOperationalStatus['MaintenanceRequired']]: 'bg-red',
  [VehicleOperationalStatus['InTransfer']]: 'bg-red',
  [VehicleOperationalStatus['Operational']]: 'bg-green',
  [VehicleOperationalStatus['PartlyOperational']]: 'bg-green',
  [VehicleOperationalStatus['AtSupplier']]: 'bg-red',
  [VehicleOperationalStatus['InStorage']]: 'bg-red',
  [VehicleOperationalStatus['Stolen']]: 'bg-red',
  [VehicleAvailabilityStatus['Available']]: 'bg-green',
  [VehicleAvailabilityStatus['Assigned']]: 'bg-red',
  [VehicleAvailabilityStatus['Unavailable']]: 'bg-red',
  [DamageStatus['Archived']]: 'bg-gray-light',
  [DamageStatus['Fixed']]: 'bg-blue-500',
  [DamageStatus['Reported']]: 'bg-yellow-400',
  [DamageStatus['FixedPendingApproval']]: 'bg-orange-500',
}
