import { useEffect, useState, useContext } from 'react'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { AuthClientContext } from '~/context/AuthClientContext'

export enum Permission {
  READ_VEHICLES_ALL = 'read:vehicles:all',
  READ_VEHICLES_OWN = 'read:vehicles:own',
  WRITE_VEHICLES_ALL = 'write:vehicles:all',
  READ_VEHICLE_ASSIGNMENTS_ALL = 'read:vehicle_assignments:all',
  READ_VEHICLE_ASSIGNMENTS_OWN = 'read:vehicle_assignments:own',
  WRITE_VEHICLE_ASSIGNMENTS_ALL = 'write:vehicle_assignments:all',
  WRITE_VEHICLE_ASSIGNMENTS_OWN = 'write:vehicle_assignments:own',
  READ_DAMAGES_ALL = 'read:damages:all',
  WRITE_DAMAGES_ALL = 'write:damages:all',
}

type ReturnType = {
  permissions: {
    [key in Permission]?: boolean
  }
  isAllowed(permissionList: Permission[]): boolean
}

const DEVELOPMENT_MODE = process.env.NODE_ENV === 'development'
const DEVELOPMENT_PERMISSIONS = process.env.DEVELOPMENT_PERMISSIONS

const usePermissions = (): ReturnType => {
  const [permissions, setPermissions] = useState<{
    [key in Permission]?: boolean
  }>({})
  const authClient = useContext(AuthClientContext)

  useEffect(() => {
    const parseUserPermissions = async (): Promise<void> => {
      try {
        if (authClient) {
          const token = await authClient.getTokenSilently({
            audience: process.env.AUTH_AUDIENCE,
          })
          const decodedToken =
            jwtDecode<JwtPayload & { permissions?: Permission[] }>(token) || {}
          const { permissions: jwtPermissions = [] } = decodedToken

          const authorized = jwtPermissions.reduce(
            (acc, permission) => ({ ...acc, [permission]: true }),
            {}
          )

          setPermissions(authorized)
        }
      } catch (err) {
        console.error(err)
      }
    }

    parseUserPermissions()
  }, [authClient])

  return {
    permissions,
    isAllowed: (permissionList) => {
      /**
       * TODO:
       * this is a workaround to bypass permissions check on e2e tests
       * there is probably a better way to do it
       */
      if (DEVELOPMENT_MODE && !authClient) {
        return (permissionList || []).some(
          (p) => (DEVELOPMENT_PERMISSIONS || '').indexOf(p) >= 0
        )
      }
      return (permissionList || []).some((p) => permissions[p] === true)
    },
  }
}

export default usePermissions
