import { FC, DragEvent, ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Papa from 'papaparse'
import { ExclamationCircleIcon, UploadIcon } from '@heroicons/react/outline'
import { Spinner } from '~/components/Spinner'

export type VehicleRow = {
  licensePlate?: string
  model?: string
  supplier?: string
  hubSlug?: string
}

type UploadState =
  | 'formatError'
  | 'filetypeError'
  | 'uploadError'
  | 'uploading'
  | 'idle'

const ERROR_STATES = ['formatError', 'filetypeError', 'uploadError']

const transformHeader = (header: string): string => {
  switch (header.toLowerCase().replace(/ /g, '')) {
    case 'bikeid':
    case 'vehicleid': // Not currently used, added for future
      return 'licensePlate'
    case 'hub':
    case 'hubslug':
    case 'flinkhubslug':
      return 'hubSlug'
    default:
      return header.toLowerCase()
  }
}

const readFile = (
  file: File,
  onRead: (data: string) => void,
  onError: () => void
): void => {
  const fr = new FileReader()

  // readAsText should always return the result as string
  fr.addEventListener('load', () => onRead(fr.result as string))
  fr.addEventListener('abort', onError)
  fr.addEventListener('error', onError)
  fr.readAsText(file)
}

const isVehicleRow = (row: VehicleRow | unknown): row is VehicleRow => {
  return (
    !!row &&
    typeof row === 'object' &&
    ('licensePlate' in row ||
      'model' in row ||
      'supplier' in row ||
      'hubSlug' in row)
  )
}

interface Props {
  onChange: (vehicles: VehicleRow[]) => void
  submitting?: boolean
}

const VehicleUpload: FC<Props> = ({ onChange, submitting = false }) => {
  const { t } = useTranslation('translation')
  const [state, setState] = useState<UploadState>('idle')
  const importing = state === 'uploading' || submitting

  const onLoad = (result: string) => {
    const { data } = Papa.parse(result, {
      header: true,
      transformHeader,
      skipEmptyLines: 'greedy',
    })

    // Require at least one vehicle, the rest will show missing columns in the table
    if (data.some(isVehicleRow)) {
      onChange(data as VehicleRow[])
      setState('idle')
    } else {
      setState('formatError')
    }
  }

  const uploadFile = (file: File) => {
    if (file.type === 'text/csv') {
      setState('uploading')
      readFile(file, onLoad, () => setState('uploadError'))
    } else {
      setState('filetypeError')
    }
  }

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (
      !event.target.files ||
      !event.target.files[0] ||
      state === 'uploading'
    ) {
      return
    }

    uploadFile(event.target.files[0])
  }

  const onDrop = (event: DragEvent) => {
    event.preventDefault()
    if (
      !event.dataTransfer.files ||
      !event.dataTransfer.files[0] ||
      state === 'uploading'
    ) {
      return
    }

    uploadFile(event.dataTransfer.files[0])
  }

  return (
    <>
      <div className="grid grid-cols-1 space-y-2 mb-10">
        <div className="flex items-center justify-center w-full">
          <div className="flex flex-col rounded-lg border-4 border-dashed w-full h-60 p-10 group text-center select-none relative">
            <div
              className="text-gray-500 h-full w-full text-center flex flex-col items-center justify-center"
              onDrop={onDrop}
              onDragOver={(e) => {
                e.preventDefault()
              }}
            >
              <div className="flex flex-auto max-h-48 w-2/5 mx-auto justify-center">
                {importing ? (
                  <Spinner size={28} />
                ) : (
                  <UploadIcon className="text-gray-400 h-28" />
                )}
              </div>
              <p className="mt-1">{t(`VehicleUpload.drag`)}</p>
              <p className="text-sm my-1">{t(`VehicleUpload.or`)}</p>
              <label className="text-sm primary-btn cursor-pointer">
                {t(`VehicleUpload.browse`)}
                <input
                  name="import-input"
                  onChange={onInputChange}
                  type="file"
                  className="hidden"
                  disabled={importing}
                />
              </label>
              <p className="text-sm text-gray-500 text-left self-start absolute left-0 -bottom-8">
                <a
                  href={`${process.env.BASE_API_ENDPOINT}/sample_infleeting.csv`}
                  className="underline"
                >
                  {t(`VehicleUpload.downloadExample`)}
                </a>
              </p>
            </div>
          </div>
        </div>
        {ERROR_STATES.includes(state) && (
          <div className="flex items-center justify-center text-sm">
            <ExclamationCircleIcon className="text-red-500 h-6" />
            <p className="ml-2 text-red-500">{t(`VehicleUpload.${state}`)}</p>
          </div>
        )}
      </div>
    </>
  )
}

export default VehicleUpload
