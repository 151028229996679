import { type Option } from '~/components/CustomSelect'

type Props = {
  options?: Option[]
  value?: string
  onChange?: (value: string) => void
  isDisabled?: boolean
  name?: string
}

const Select = ({ name, options = [], value, onChange, isDisabled }: Props) => (
  <select
    name={name}
    value={value || ''}
    onChange={(e) => onChange && onChange(e.target.value)}
    className="text-base h-11 px-1"
    disabled={isDisabled || !options.length}
    data-testid="select"
  >
    {options.map((option) => (
      <option
        key={option.value}
        value={option.value}
        disabled={option?.isDisabled}
      >
        {option.label}
      </option>
    ))}
  </select>
)

export default Select
