import translationEn from './locales/en.json'
import translationDe from './locales/de.json'
import translationFr from './locales/fr.json'
import translationNl from './locales/nl.json'
import { format as formatDate, isDate, Locale } from 'date-fns'
import { enUS as en, de, fr, nl } from 'date-fns/locale'
import i18n, { InitOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'

const locales: { [key: string]: Locale } = { en, de, fr, nl }

export const resources = {
  en: {
    translation: translationEn,
  },
  de: {
    translation: translationDe,
  },
  fr: {
    translation: translationFr,
  },
  nl: {
    translation: translationNl,
  },
}

export const options: InitOptions = {
  lng: 'en',
  fallbackLng: 'en',
  ns: ['translation'],
  defaultNS: 'translation',

  interpolation: {
    escapeValue: false,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    format: (value: any, format?: string, lng?: string): string =>
      isDate(value)
        ? formatDate(value, format || 'YYYY-MM-dd', {
            locale: locales[lng || 'en'],
          })
        : value,
  },
}

i18n.use(initReactI18next).init({
  resources,
  ...options,
})

export default i18n
