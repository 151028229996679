import { useOutletContext } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import DamageList from '~/components/DamageList'
import NewDamage from '~/components/NewDamage'

import { useState } from 'react'
import { VehicleById } from '../types'
import { Permission, usePermissions } from '~/hooks'

const DamageReports = () => {
  const { t } = useTranslation()
  const { isAllowed } = usePermissions()

  const { id, operationalStatus, damages, archivedAt } =
    useOutletContext<VehicleById>()
  const vehiclePartial = {
    id,
    operationalStatus,
    damages,
  }
  const [isNewDamageModalVisible, setNewDamageModalVisible] = useState(false)
  const [isStatusUpdateModalVisible, setStatusModalVisible] = useState(false)

  return (
    <>
      <h2 className="font-medium text-2xl">
        {t('vehicle.damageReports.header')}
      </h2>
      <h3>{t('vehicle.damageReports.subheader')}</h3>

      <button
        className="primary-btn mt-4"
        onClick={() => setNewDamageModalVisible(true)}
        disabled={!!archivedAt || !isAllowed([Permission.WRITE_DAMAGES_ALL])}
      >
        {t('vehicle.damageReports.new')}
      </button>

      <NewDamage
        setDamageModalVisible={setNewDamageModalVisible}
        isDamageModalVisible={isNewDamageModalVisible}
        setStatusModalVisible={setStatusModalVisible}
        isStatusModalVisible={isStatusUpdateModalVisible}
        vehicle={vehiclePartial}
      />

      <div className="overflow-x-auto overflow-y-hidden mt-10">
        {damages?.nodes && (
          <DamageList
            damages={damages.nodes}
            vehicle={vehiclePartial}
            canEdit={true}
          />
        )}
      </div>
    </>
  )
}

export default DamageReports
