import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import CustomSelect from '~/components/CustomSelect'
import UnassignVehicleTable from '~/components/UnassignVehicleTable'
import SearchField from '~/components/SearchField'
import { RiderStatusFilter, riderStatusOptions } from './helpers'
import { useDebounce } from '~/hooks'
import { UnassignVehicle } from '../UnassignVehicleTable/types'

type Props = {
  vehicles: UnassignVehicle[]
  riderStatus: RiderStatusFilter
  search: string
  setRiderStatusFilter: (status: RiderStatusFilter) => void
  setSkuSearchText: (search: string) => void
}

const UnassignVehicleView = ({
  vehicles,
  riderStatus,
  search,
  setRiderStatusFilter,
  setSkuSearchText,
}: Props) => {
  const { t } = useTranslation()
  const [debouncedSearchText, searchText, setDebouncedSearchText] =
    useDebounce<string>(search ?? '', 250)

  useEffect(() => {
    setSkuSearchText(debouncedSearchText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText])

  useEffect(() => {
    setDebouncedSearchText(search)
  }, [search, setDebouncedSearchText])

  const initialRiderStatusOption = useMemo(() => {
    return (
      riderStatusOptions(t).find((option) => riderStatus == option.value) ||
      riderStatusOptions(t)[0]
    )
  }, [riderStatus, t])

  return (
    <>
      <div className="flex flex-col gap-y-5 mt-5">
        <SearchField
          placeholder={t('vehicle.search')}
          value={searchText}
          onChange={(value) => {
            setDebouncedSearchText(value)
          }}
        />
        <CustomSelect
          options={riderStatusOptions(t)}
          displayValue={`${t('vehicle.filters.riderStatus.placeholder')}: ${
            initialRiderStatusOption.label
          }`}
          onSelect={(option) => {
            setRiderStatusFilter(option.value as RiderStatusFilter)
          }}
          selectedOption={initialRiderStatusOption}
          variant="medium"
          buttonVariant="dashboard-filter"
        />
      </div>
      <UnassignVehicleTable vehicles={vehicles} />
    </>
  )
}

export default UnassignVehicleView
