import { useCallback, useMemo, useState } from 'react'
import { uuidv7 } from '@kripod/uuidv7'
import {
  ApolloClient,
  ApolloError,
  useApolloClient,
  ApolloQueryResult,
} from '@apollo/client'

import { ImportVehicle, ImportVehicles } from '~/components/Import/types'
import { VehicleRow } from '~/components/Import/VehicleUpload'
import {
  CreatedVehicleFragment,
  VehicleOptionsQuery,
  LocksByReferenceDocument,
  AxaLock,
} from '~/graphql/generated/types'
import { formatHubs } from '~/utils/hubs'
import { GraphileError } from '~/utils/errors'
import { BluetoothInputType, ConflictingVehicle } from '~/pages/vehicles/types'
import { isBluetoothBike, Supplier } from '~/pages/vehicles/helpers'
import { validateVehicle } from '~/components/Import/helpers/validation'
import {
  getConflictQueryDoc,
  getMutationDoc,
} from '~/components/Import/helpers/graphql'

type MutationData = { [create_key: string]: CreatedVehicleFragment }

type ImportActionData = {
  error?: ApolloError
  data?: MutationData
}

const combineVehicles = (
  vehicles: ImportVehicles,
  actionData?: ImportActionData
): ImportVehicles => {
  if (!actionData || !vehicles) {
    return vehicles
  }
  const { data, error } = actionData
  let importVehicles = Object.assign({}, vehicles)

  if (error && error.graphQLErrors) {
    const vehicleErrors = (error.graphQLErrors as GraphileError[])
      .filter((e) => e.path && typeof e.path[0] === 'string')
      .map((e) => [
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore check if this is true in prod mode
        e.path[0].slice(1).replace(/_/g, '-'),
        [`VehicleImport.errors.${e.errcode}`, e.detail || e.message],
      ])

    importVehicles = vehicleErrors.reduce(
      (acc, [id, error]) =>
        acc[id]
          ? {
              ...acc,
              [id]: { ...acc[id], errors: [...acc[id].errors, error] },
            }
          : acc,
      vehicles
    )
  }

  if (data) {
    const createdVehicleData = Object.values(data).flatMap(({ vehicle }) =>
      vehicle ? [[vehicle.id, vehicle]] : []
    )

    importVehicles = createdVehicleData.reduce(
      (acc, [id, vehicle]) =>
        acc[id]
          ? {
              ...acc,
              [id]: {
                ...acc[id],
                vehicle: { ...acc[id].vehicle, ...vehicle },
              },
            }
          : acc,
      importVehicles
    )
  }

  return importVehicles
}

const getLocksByReference = async (
  apolloClient: ApolloClient<object>,
  references: string[]
) => {
  if (references.length > 0) {
    try {
      const locksReponse = await apolloClient.query({
        query: LocksByReferenceDocument,
        variables: { references: references },
        fetchPolicy: 'no-cache',
      })
      return locksReponse.data.locksByReference
    } catch (error) {
      return []
    }
  }
  return []
}

const getImportVehicles = async (
  rows: VehicleRow[],
  suppliers: Supplier[],
  apolloClient: ApolloClient<object>
) => {
  const bluetoothLockReferences: string[] = []

  const vehicles: ImportVehicle[] = rows.map((row) => {
    const supplier = suppliers.find(
      (s) => s.name.trim().toLowerCase() === row.supplier?.toLowerCase()
    )
    const models = supplier?.vehicleModels?.nodes || []
    const model = models.find(
      (m) =>
        m.name.toLowerCase().replace(/ /g, '') ===
        row.model?.toLowerCase().replace(/ /g, '')
    )

    const bluetoothData: BluetoothInputType = {
      bluetooth: null,
      provider: null,
      moduleId: null,
    }

    if (row.licensePlate !== undefined && supplier?.id && model?.id) {
      const [bluetooth, provider] = isBluetoothBike(
        supplier?.id,
        model?.id,
        suppliers
      )
      bluetoothData.bluetooth = bluetooth
      bluetoothData.provider = provider
      if (bluetooth) {
        bluetoothLockReferences.push(row.licensePlate)
      }
    }

    Object.entries(bluetoothData).forEach(([key, val]) => {
      if (!val) delete bluetoothData[key as keyof BluetoothInputType]
    })

    const vehicle: ImportVehicle = {
      id: uuidv7(),
      licensePlate: row.licensePlate,
      model: model?.name || row.model,
      modelId: model?.id,
      hubSlug: row.hubSlug?.toUpperCase(),
      supplier: supplier?.name || row.supplier,
      supplierId: supplier?.id,
      kind: model?.kind,
      ...bluetoothData,
    }
    return vehicle
  })

  const locks: AxaLock[] = await getLocksByReference(
    apolloClient,
    bluetoothLockReferences
  )
  locks.forEach((lock: AxaLock) => {
    const vehicle = vehicles.find(
      (vehicle: ImportVehicle) => vehicle.licensePlate === lock.reference
    )
    if (vehicle) {
      vehicle.moduleId = lock.id ?? null
    }
  })

  return vehicles
}

const splitValidVehicles = (vehicles: ImportVehicle[], hubSlugs: string[]) => {
  const validatedVehicles = vehicles
    .map((vehicle) => {
      return {
        vehicle,
        errors: validateVehicle(vehicle, hubSlugs),
      }
    })
    .map((v) => [v.vehicle.id, v])

  const allVehicles = Object.fromEntries(validatedVehicles) as ImportVehicles
  const validVehicles = Object.values(allVehicles || {})
    .filter((v) => v.errors.length === 0)
    .map((v) => v.vehicle)

  return { allVehicles, validVehicles }
}

const splitConflictingVehicles = async (
  apolloClient: ApolloClient<object>,
  importVehicles: ImportVehicle[]
) => {
  let result: ApolloQueryResult<object> | undefined
  try {
    result = await apolloClient.query({
      query: getConflictQueryDoc(importVehicles),
      fetchPolicy: 'no-cache',
    })
  } catch (error) {
    return [[], [], []]
  }
  const existingVehicles = Object.values(result.data)
    .filter(
      (vehicleData: { nodes: ConflictingVehicle[] }) => vehicleData.nodes.length
    )
    .flatMap(
      (vehicleData: { nodes: ConflictingVehicle[] }) => vehicleData.nodes
    )

  const createVehicles: ImportVehicle[] = []
  const updateVehicles: ImportVehicle[] = []
  const conflictingVehicles: ImportVehicle[] = []

  importVehicles.forEach((importVehicle: ImportVehicle) => {
    const existingVehicle = existingVehicles.find(
      (vehicle: ConflictingVehicle) =>
        vehicle.licensePlate.toLowerCase() ==
          importVehicle.licensePlate?.toLowerCase() &&
        vehicle.supplierId == importVehicle.supplierId
    )
    const mutationId = importVehicle.id
    if (existingVehicle) {
      updateVehicles.push({
        ...importVehicle,
        id: existingVehicle.id,
        mutationId: mutationId,
      })
      conflictingVehicles.push(existingVehicle)
    } else {
      createVehicles.push(importVehicle)
    }
  })

  return [conflictingVehicles, updateVehicles, createVehicles]
}

type ReturnTypes = {
  vehicles: ImportVehicles
  importing: boolean
  onImport: (rows: VehicleRow[]) => Promise<void>
  pending: boolean
  cancelImport: () => void
  continuePendingImport: () => void
  conflictingVehicles: ImportVehicle[]
}

const useVehicleImport = (options?: VehicleOptionsQuery): ReturnTypes => {
  const apolloClient = useApolloClient()

  const { hubs, suppliers } = useMemo(
    () => ({
      hubs: options?.allHubs || [],
      suppliers: options?.suppliers?.nodes || [],
    }),
    [options]
  )

  const [importVehicles, setVehicles] = useState<ImportVehicles>(null)
  const [validImportVehicles, setValidImportVehicles] = useState<
    ImportVehicle[]
  >([])
  const [validUpdateVehicles, setValidUpdateVehicles] = useState<
    ImportVehicle[]
  >([])
  const [conflictingVehicles, setConflictingVehicles] = useState<
    ImportVehicle[]
  >([])
  const [actionData, setActionData] = useState<ImportActionData>({})
  const [importing, setImporting] = useState<boolean>(false)
  const [pending, setPending] = useState<boolean>(false)

  const continuePendingImport = async () => {
    await executeImport(validImportVehicles, validUpdateVehicles)
    setPending(false)
    setImporting(false)
  }

  const cancelImport = () => {
    setPending(false)
    setVehicles(null)
    setImporting(false)
  }

  const vehicles = useMemo(
    () => combineVehicles(importVehicles, actionData),
    [importVehicles, actionData]
  )

  const executeImport = useCallback(
    async (
      importVehicles: ImportVehicle[],
      updateVehicles: ImportVehicle[]
    ) => {
      try {
        const mutationDoc = getMutationDoc(importVehicles, updateVehicles)

        const result = await apolloClient.mutate({
          mutation: mutationDoc,
        })
        const data: MutationData = result.data
        setActionData({ data })
      } catch (error) {
        setActionData({ error: error as ApolloError })
      }
    },
    [apolloClient]
  )

  const onImport = useCallback(
    async (rows: VehicleRow[]): Promise<void> => {
      setImporting(true)
      setConflictingVehicles([])
      setValidImportVehicles([])
      setValidUpdateVehicles([])

      // parse vehicles, match supplier & model ids, get bluetooth keys
      const importVehicles: ImportVehicle[] = await getImportVehicles(
        rows,
        suppliers,
        apolloClient
      )

      // validate vehicles
      const { allVehicles, validVehicles } = splitValidVehicles(
        importVehicles,
        formatHubs(hubs)
      )

      setVehicles(allVehicles)

      if (validVehicles.length === 0) {
        return setImporting(false)
      }

      // check for vehicle conflicts
      const [conflictVehicles, allValidUpdateVehicles, allValidImportVehicles] =
        await splitConflictingVehicles(apolloClient, validVehicles)

      setConflictingVehicles(conflictVehicles)
      setValidUpdateVehicles(allValidUpdateVehicles)
      setValidImportVehicles(allValidImportVehicles)

      // pause infleeting waiting for user action on conflicts
      if (conflictVehicles.length) {
        return setPending(true)
      }

      await executeImport(allValidImportVehicles, [])
      setImporting(false)
    },
    [hubs, suppliers, apolloClient, executeImport]
  )

  return {
    vehicles,
    importing,
    onImport,
    pending,
    cancelImport,
    continuePendingImport,
    conflictingVehicles,
  }
}

export default useVehicleImport
