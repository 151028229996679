/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {
  GraphQLResolveInfo,
  GraphQLScalarType,
  GraphQLScalarTypeConfig,
} from 'graphql'
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type RequireFields<T, K extends keyof T> = Omit<T, K> & {
  [P in K]-?: NonNullable<T[P]>
}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: any
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: any
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: any
}

export type AxaLock = {
  __typename?: 'AXALock'
  id?: Maybe<Scalars['String']>
  reference: Scalars['String']
}

/** All input for the `archiveVehicleById` mutation. */
export type ArchiveVehicleByIdInput = {
  archiveId: Scalars['UUID']
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
}

/** The output of our `archiveVehicleById` mutation. */
export type ArchiveVehicleByIdPayload = {
  __typename?: 'ArchiveVehicleByIdPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Reads a single `VehicleModel` that is related to this `Vehicle`. */
  model?: Maybe<VehicleModel>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
  /** Reads a single `Supplier` that is related to this `Vehicle`. */
  supplier?: Maybe<Supplier>
  vehicle?: Maybe<Vehicle>
  /** An edge for our `Vehicle`. May be used by Relay 1. */
  vehicleEdge?: Maybe<VehiclesEdge>
}

/** The output of our `archiveVehicleById` mutation. */
export type ArchiveVehicleByIdPayloadVehicleEdgeArgs = {
  orderBy?: InputMaybe<Array<VehiclesOrderBy>>
}

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Boolean']>
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Boolean']>>
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Boolean']>
}

/** All input for the create `Damage` mutation. */
export type CreateDamageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The `Damage` to be created by this mutation. */
  damage: DamageInput
}

/** The output of our create `Damage` mutation. */
export type CreateDamagePayload = {
  __typename?: 'CreateDamagePayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The `Damage` that was created by this mutation. */
  damage?: Maybe<Damage>
  /** An edge for our `Damage`. May be used by Relay 1. */
  damageEdge?: Maybe<DamagesEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
  /** Reads a single `Vehicle` that is related to this `Damage`. */
  vehicle?: Maybe<Vehicle>
}

/** The output of our create `Damage` mutation. */
export type CreateDamagePayloadDamageEdgeArgs = {
  orderBy?: InputMaybe<Array<DamagesOrderBy>>
}

/** All input for the create `ModelEquipment` mutation. */
export type CreateModelEquipmentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The `ModelEquipment` to be created by this mutation. */
  modelEquipment: ModelEquipmentInput
}

/** The output of our create `ModelEquipment` mutation. */
export type CreateModelEquipmentPayload = {
  __typename?: 'CreateModelEquipmentPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The `ModelEquipment` that was created by this mutation. */
  modelEquipment?: Maybe<ModelEquipment>
  /** An edge for our `ModelEquipment`. May be used by Relay 1. */
  modelEquipmentEdge?: Maybe<ModelEquipmentEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
  /** Reads a single `VehicleModel` that is related to this `ModelEquipment`. */
  vehicleModel?: Maybe<VehicleModel>
}

/** The output of our create `ModelEquipment` mutation. */
export type CreateModelEquipmentPayloadModelEquipmentEdgeArgs = {
  orderBy?: InputMaybe<Array<ModelEquipmentOrderBy>>
}

/** All input for the create `Supplier` mutation. */
export type CreateSupplierInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The `Supplier` to be created by this mutation. */
  supplier: SupplierInput
}

/** The output of our create `Supplier` mutation. */
export type CreateSupplierPayload = {
  __typename?: 'CreateSupplierPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
  /** The `Supplier` that was created by this mutation. */
  supplier?: Maybe<Supplier>
  /** An edge for our `Supplier`. May be used by Relay 1. */
  supplierEdge?: Maybe<SuppliersEdge>
}

/** The output of our create `Supplier` mutation. */
export type CreateSupplierPayloadSupplierEdgeArgs = {
  orderBy?: InputMaybe<Array<SuppliersOrderBy>>
}

/** All input for the create `VehicleAssignment` mutation. */
export type CreateVehicleAssignmentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The `VehicleAssignment` to be created by this mutation. */
  vehicleAssignment: VehicleAssignmentInput
}

/** The output of our create `VehicleAssignment` mutation. */
export type CreateVehicleAssignmentPayload = {
  __typename?: 'CreateVehicleAssignmentPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
  /** Reads a single `Vehicle` that is related to this `VehicleAssignment`. */
  vehicle?: Maybe<Vehicle>
  /** The `VehicleAssignment` that was created by this mutation. */
  vehicleAssignment?: Maybe<VehicleAssignment>
  /** An edge for our `VehicleAssignment`. May be used by Relay 1. */
  vehicleAssignmentEdge?: Maybe<VehicleAssignmentsEdge>
}

/** The output of our create `VehicleAssignment` mutation. */
export type CreateVehicleAssignmentPayloadVehicleAssignmentEdgeArgs = {
  orderBy?: InputMaybe<Array<VehicleAssignmentsOrderBy>>
}

/** All input for the create `VehicleEquipment` mutation. */
export type CreateVehicleEquipmentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The `VehicleEquipment` to be created by this mutation. */
  vehicleEquipment: VehicleEquipmentInput
}

/** The output of our create `VehicleEquipment` mutation. */
export type CreateVehicleEquipmentPayload = {
  __typename?: 'CreateVehicleEquipmentPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
  /** Reads a single `Vehicle` that is related to this `VehicleEquipment`. */
  vehicle?: Maybe<Vehicle>
  /** The `VehicleEquipment` that was created by this mutation. */
  vehicleEquipment?: Maybe<VehicleEquipment>
  /** An edge for our `VehicleEquipment`. May be used by Relay 1. */
  vehicleEquipmentEdge?: Maybe<VehicleEquipmentEdge>
}

/** The output of our create `VehicleEquipment` mutation. */
export type CreateVehicleEquipmentPayloadVehicleEquipmentEdgeArgs = {
  orderBy?: InputMaybe<Array<VehicleEquipmentOrderBy>>
}

/** All input for the create `Vehicle` mutation. */
export type CreateVehicleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The `Vehicle` to be created by this mutation. */
  vehicle: VehicleInput
}

/** All input for the create `VehicleModel` mutation. */
export type CreateVehicleModelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The `VehicleModel` to be created by this mutation. */
  vehicleModel: VehicleModelInput
}

/** The output of our create `VehicleModel` mutation. */
export type CreateVehicleModelPayload = {
  __typename?: 'CreateVehicleModelPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
  /** Reads a single `Supplier` that is related to this `VehicleModel`. */
  supplier?: Maybe<Supplier>
  /** The `VehicleModel` that was created by this mutation. */
  vehicleModel?: Maybe<VehicleModel>
  /** An edge for our `VehicleModel`. May be used by Relay 1. */
  vehicleModelEdge?: Maybe<VehicleModelsEdge>
}

/** The output of our create `VehicleModel` mutation. */
export type CreateVehicleModelPayloadVehicleModelEdgeArgs = {
  orderBy?: InputMaybe<Array<VehicleModelsOrderBy>>
}

/** The output of our create `Vehicle` mutation. */
export type CreateVehiclePayload = {
  __typename?: 'CreateVehiclePayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Reads a single `VehicleModel` that is related to this `Vehicle`. */
  model?: Maybe<VehicleModel>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
  /** Reads a single `Supplier` that is related to this `Vehicle`. */
  supplier?: Maybe<Supplier>
  /** The `Vehicle` that was created by this mutation. */
  vehicle?: Maybe<Vehicle>
  /** An edge for our `Vehicle`. May be used by Relay 1. */
  vehicleEdge?: Maybe<VehiclesEdge>
}

/** The output of our create `Vehicle` mutation. */
export type CreateVehiclePayloadVehicleEdgeArgs = {
  orderBy?: InputMaybe<Array<VehiclesOrderBy>>
}

export type Damage = Node & {
  __typename?: 'Damage'
  archivedBy?: Maybe<Scalars['String']>
  code: DamageCode
  createdAt: Scalars['Datetime']
  description: Scalars['String']
  fixApprovedBy?: Maybe<Scalars['String']>
  fixRejectedBy?: Maybe<Scalars['String']>
  id: Scalars['UUID']
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  reportedBy?: Maybe<Scalars['String']>
  status: DamageStatus
  title: Scalars['String']
  updatedAt: Scalars['Datetime']
  /** Reads a single `Vehicle` that is related to this `Damage`. */
  vehicle?: Maybe<Vehicle>
  vehicleId: Scalars['UUID']
}

export enum DamageCode {
  Brake = 'BRAKE',
  Engine = 'ENGINE',
  Fender = 'FENDER',
  Gears = 'GEARS',
  Key = 'KEY',
  Light = 'LIGHT',
  Other = 'OTHER',
  Puncture = 'PUNCTURE',
  Saddle = 'SADDLE',
  Wheel = 'WHEEL',
}

/** A filter to be used against DamageCode fields. All fields are combined with a logical ‘and.’ */
export type DamageCodeFilter = {
  /** Equal to the specified value. */
  equalTo?: InputMaybe<DamageCode>
  /** Included in the specified list. */
  in?: InputMaybe<Array<DamageCode>>
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>
  /** Less than the specified value. */
  lessThan?: InputMaybe<DamageCode>
}

/** A condition to be used against `Damage` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type DamageCondition = {
  /** Checks for equality with the object’s `archivedBy` field. */
  archivedBy?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `code` field. */
  code?: InputMaybe<DamageCode>
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `fixApprovedBy` field. */
  fixApprovedBy?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `fixRejectedBy` field. */
  fixRejectedBy?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>
  /** Checks for equality with the object’s `reportedBy` field. */
  reportedBy?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<DamageStatus>
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>
  /** Checks for equality with the object’s `vehicleId` field. */
  vehicleId?: InputMaybe<Scalars['UUID']>
}

/** A filter to be used against `Damage` object types. All fields are combined with a logical ‘and.’ */
export type DamageFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DamageFilter>>
  /** Filter by the object’s `archivedBy` field. */
  archivedBy?: InputMaybe<StringFilter>
  /** Filter by the object’s `code` field. */
  code?: InputMaybe<DamageCodeFilter>
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>
  /** Filter by the object’s `fixApprovedBy` field. */
  fixApprovedBy?: InputMaybe<StringFilter>
  /** Filter by the object’s `fixRejectedBy` field. */
  fixRejectedBy?: InputMaybe<StringFilter>
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>
  /** Negates the expression. */
  not?: InputMaybe<DamageFilter>
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DamageFilter>>
  /** Filter by the object’s `reportedBy` field. */
  reportedBy?: InputMaybe<StringFilter>
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<DamageStatusFilter>
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>
  /** Filter by the object’s `vehicle` relation. */
  vehicle?: InputMaybe<VehicleFilter>
  /** Filter by the object’s `vehicleId` field. */
  vehicleId?: InputMaybe<UuidFilter>
}

/** An input for mutations affecting `Damage` */
export type DamageInput = {
  archivedBy?: InputMaybe<Scalars['String']>
  code: DamageCode
  createdAt?: InputMaybe<Scalars['Datetime']>
  description: Scalars['String']
  fixApprovedBy?: InputMaybe<Scalars['String']>
  fixRejectedBy?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['UUID']>
  reportedBy?: InputMaybe<Scalars['String']>
  status?: InputMaybe<DamageStatus>
  title: Scalars['String']
  updatedAt?: InputMaybe<Scalars['Datetime']>
  vehicleId: Scalars['UUID']
}

/** Represents an update to a `Damage`. Fields that are set will be updated. */
export type DamagePatch = {
  archivedBy?: InputMaybe<Scalars['String']>
  code?: InputMaybe<DamageCode>
  createdAt?: InputMaybe<Scalars['Datetime']>
  description?: InputMaybe<Scalars['String']>
  fixApprovedBy?: InputMaybe<Scalars['String']>
  fixRejectedBy?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['UUID']>
  reportedBy?: InputMaybe<Scalars['String']>
  status?: InputMaybe<DamageStatus>
  title?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['Datetime']>
  vehicleId?: InputMaybe<Scalars['UUID']>
}

export enum DamageStatus {
  Archived = 'ARCHIVED',
  Fixed = 'FIXED',
  FixedPendingApproval = 'FIXED_PENDING_APPROVAL',
  Reported = 'REPORTED',
}

/** A filter to be used against DamageStatus fields. All fields are combined with a logical ‘and.’ */
export type DamageStatusFilter = {
  /** Equal to the specified value. */
  equalTo?: InputMaybe<DamageStatus>
  /** Included in the specified list. */
  in?: InputMaybe<Array<DamageStatus>>
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>
  /** Less than the specified value. */
  lessThan?: InputMaybe<DamageStatus>
}

/** A connection to a list of `Damage` values. */
export type DamagesConnection = {
  __typename?: 'DamagesConnection'
  /** A list of edges which contains the `Damage` and cursor to aid in pagination. */
  edges: Array<DamagesEdge>
  /** A list of `Damage` objects. */
  nodes: Array<Damage>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Damage` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Damage` edge in the connection. */
export type DamagesEdge = {
  __typename?: 'DamagesEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `Damage` at the end of the edge. */
  node: Damage
}

/** Methods to use when ordering `Damage`. */
export enum DamagesOrderBy {
  ArchivedByAsc = 'ARCHIVED_BY_ASC',
  ArchivedByDesc = 'ARCHIVED_BY_DESC',
  CodeAsc = 'CODE_ASC',
  CodeDesc = 'CODE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  FixApprovedByAsc = 'FIX_APPROVED_BY_ASC',
  FixApprovedByDesc = 'FIX_APPROVED_BY_DESC',
  FixRejectedByAsc = 'FIX_REJECTED_BY_ASC',
  FixRejectedByDesc = 'FIX_REJECTED_BY_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReportedByAsc = 'REPORTED_BY_ASC',
  ReportedByDesc = 'REPORTED_BY_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  VehicleIdAsc = 'VEHICLE_ID_ASC',
  VehicleIdDesc = 'VEHICLE_ID_DESC',
}

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Datetime']>
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Datetime']>>
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Datetime']>
}

export type Hub = {
  __typename?: 'Hub'
  slug?: Maybe<Scalars['String']>
}

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Int']>
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Int']>>
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Int']>
}

/** A filter to be used against JSON fields. All fields are combined with a logical ‘and.’ */
export type JsonFilter = {
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['JSON']>
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['JSON']>>
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['JSON']>
}

export type LockKey = {
  __typename?: 'LockKey'
  apiId?: Maybe<Scalars['String']>
  eKey?: Maybe<Scalars['String']>
  otp?: Maybe<Scalars['String']>
  securityKey?: Maybe<Scalars['String']>
}

export enum LockType {
  Bluetooth = 'BLUETOOTH',
  Hybrid = 'HYBRID',
}

export type ModelEquipment = {
  __typename?: 'ModelEquipment'
  capacity: Scalars['String']
  count: Scalars['Int']
  equipment: VehicleEquipmentType
  /** Reads a single `VehicleModel` that is related to this `ModelEquipment`. */
  vehicleModel?: Maybe<VehicleModel>
  vehicleModelId?: Maybe<Scalars['UUID']>
}

/**
 * A condition to be used against `ModelEquipment` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ModelEquipmentCondition = {
  /** Checks for equality with the object’s `capacity` field. */
  capacity?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `count` field. */
  count?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `equipment` field. */
  equipment?: InputMaybe<VehicleEquipmentType>
  /** Checks for equality with the object’s `vehicleModelId` field. */
  vehicleModelId?: InputMaybe<Scalars['UUID']>
}

/** A connection to a list of `ModelEquipment` values. */
export type ModelEquipmentConnection = {
  __typename?: 'ModelEquipmentConnection'
  /** A list of edges which contains the `ModelEquipment` and cursor to aid in pagination. */
  edges: Array<ModelEquipmentEdge>
  /** A list of `ModelEquipment` objects. */
  nodes: Array<ModelEquipment>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `ModelEquipment` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `ModelEquipment` edge in the connection. */
export type ModelEquipmentEdge = {
  __typename?: 'ModelEquipmentEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `ModelEquipment` at the end of the edge. */
  node: ModelEquipment
}

/** A filter to be used against `ModelEquipment` object types. All fields are combined with a logical ‘and.’ */
export type ModelEquipmentFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ModelEquipmentFilter>>
  /** Filter by the object’s `capacity` field. */
  capacity?: InputMaybe<StringFilter>
  /** Filter by the object’s `count` field. */
  count?: InputMaybe<IntFilter>
  /** Filter by the object’s `equipment` field. */
  equipment?: InputMaybe<VehicleEquipmentTypeFilter>
  /** Negates the expression. */
  not?: InputMaybe<ModelEquipmentFilter>
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ModelEquipmentFilter>>
  /** Filter by the object’s `vehicleModel` relation. */
  vehicleModel?: InputMaybe<VehicleModelFilter>
  /** A related `vehicleModel` exists. */
  vehicleModelExists?: InputMaybe<Scalars['Boolean']>
  /** Filter by the object’s `vehicleModelId` field. */
  vehicleModelId?: InputMaybe<UuidFilter>
}

/** An input for mutations affecting `ModelEquipment` */
export type ModelEquipmentInput = {
  capacity?: InputMaybe<Scalars['String']>
  count: Scalars['Int']
  equipment: VehicleEquipmentType
  vehicleModelId?: InputMaybe<Scalars['UUID']>
}

/** Methods to use when ordering `ModelEquipment`. */
export enum ModelEquipmentOrderBy {
  CapacityAsc = 'CAPACITY_ASC',
  CapacityDesc = 'CAPACITY_DESC',
  CountAsc = 'COUNT_ASC',
  CountDesc = 'COUNT_DESC',
  EquipmentAsc = 'EQUIPMENT_ASC',
  EquipmentDesc = 'EQUIPMENT_DESC',
  Natural = 'NATURAL',
  VehicleModelIdAsc = 'VEHICLE_MODEL_ID_ASC',
  VehicleModelIdDesc = 'VEHICLE_MODEL_ID_DESC',
}

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation'
  archiveVehicleById?: Maybe<ArchiveVehicleByIdPayload>
  /** Creates a single `Damage`. */
  createDamage?: Maybe<CreateDamagePayload>
  /** Creates a single `ModelEquipment`. */
  createModelEquipment?: Maybe<CreateModelEquipmentPayload>
  /** Creates a single `Supplier`. */
  createSupplier?: Maybe<CreateSupplierPayload>
  /** Creates a single `Vehicle`. */
  createVehicle?: Maybe<CreateVehiclePayload>
  /** Creates a single `VehicleAssignment`. */
  createVehicleAssignment?: Maybe<CreateVehicleAssignmentPayload>
  /** Creates a single `VehicleEquipment`. */
  createVehicleEquipment?: Maybe<CreateVehicleEquipmentPayload>
  /** Creates a single `VehicleModel`. */
  createVehicleModel?: Maybe<CreateVehicleModelPayload>
  reassignVehicle?: Maybe<ReassignVehiclePayload>
  unassignVehicle?: Maybe<UnassignVehiclePayload>
  unassignVehicleV2?: Maybe<UnassignVehicleV2Payload>
  /** Updates a single `Damage` using a unique key and a patch. */
  updateDamage?: Maybe<UpdateDamagePayload>
  /** Updates a single `Damage` using its globally unique id and a patch. */
  updateDamageByNodeId?: Maybe<UpdateDamagePayload>
  /** Updates a single `Supplier` using a unique key and a patch. */
  updateSupplier?: Maybe<UpdateSupplierPayload>
  /** Updates a single `Supplier` using a unique key and a patch. */
  updateSupplierByName?: Maybe<UpdateSupplierPayload>
  /** Updates a single `Supplier` using its globally unique id and a patch. */
  updateSupplierByNodeId?: Maybe<UpdateSupplierPayload>
  /** Updates a single `Supplier` using a unique key and a patch. */
  updateSupplierByPrefix?: Maybe<UpdateSupplierPayload>
  /** Updates a single `Vehicle` using a unique key and a patch. */
  updateVehicle?: Maybe<UpdateVehiclePayload>
  /** Updates a single `VehicleAssignment` using a unique key and a patch. */
  updateVehicleAssignment?: Maybe<UpdateVehicleAssignmentPayload>
  /** Updates a single `VehicleAssignment` using its globally unique id and a patch. */
  updateVehicleAssignmentByNodeId?: Maybe<UpdateVehicleAssignmentPayload>
  /** Updates a single `Vehicle` using its globally unique id and a patch. */
  updateVehicleByNodeId?: Maybe<UpdateVehiclePayload>
  /** Updates a single `VehicleEquipment` using a unique key and a patch. */
  updateVehicleEquipmentByVehicleIdAndEquipment?: Maybe<UpdateVehicleEquipmentPayload>
  updateVehicleEquipments?: Maybe<UpdateVehicleEquipmentsPayload>
  /** Updates a single `VehicleModel` using a unique key and a patch. */
  updateVehicleModel?: Maybe<UpdateVehicleModelPayload>
  /** Updates a single `VehicleModel` using its globally unique id and a patch. */
  updateVehicleModelByNodeId?: Maybe<UpdateVehicleModelPayload>
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationArchiveVehicleByIdArgs = {
  input: ArchiveVehicleByIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDamageArgs = {
  input: CreateDamageInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateModelEquipmentArgs = {
  input: CreateModelEquipmentInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSupplierArgs = {
  input: CreateSupplierInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateVehicleArgs = {
  input: CreateVehicleInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateVehicleAssignmentArgs = {
  input: CreateVehicleAssignmentInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateVehicleEquipmentArgs = {
  input: CreateVehicleEquipmentInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateVehicleModelArgs = {
  input: CreateVehicleModelInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationReassignVehicleArgs = {
  input: ReassignVehicleInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUnassignVehicleArgs = {
  input: UnassignVehicleInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUnassignVehicleV2Args = {
  input: UnassignVehicleV2Input
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDamageArgs = {
  input: UpdateDamageInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDamageByNodeIdArgs = {
  input: UpdateDamageByNodeIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSupplierArgs = {
  input: UpdateSupplierInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSupplierByNameArgs = {
  input: UpdateSupplierByNameInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSupplierByNodeIdArgs = {
  input: UpdateSupplierByNodeIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSupplierByPrefixArgs = {
  input: UpdateSupplierByPrefixInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVehicleArgs = {
  input: UpdateVehicleInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVehicleAssignmentArgs = {
  input: UpdateVehicleAssignmentInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVehicleAssignmentByNodeIdArgs = {
  input: UpdateVehicleAssignmentByNodeIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVehicleByNodeIdArgs = {
  input: UpdateVehicleByNodeIdInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVehicleEquipmentByVehicleIdAndEquipmentArgs = {
  input: UpdateVehicleEquipmentByVehicleIdAndEquipmentInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVehicleEquipmentsArgs = {
  input: UpdateVehicleEquipmentsInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVehicleModelArgs = {
  input: UpdateVehicleModelInput
}

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVehicleModelByNodeIdArgs = {
  input: UpdateVehicleModelByNodeIdInput
}

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']>
}

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query'
  allHubs: Array<Hub>
  damage?: Maybe<Damage>
  /** Reads a single `Damage` using its globally unique `ID`. */
  damageByNodeId?: Maybe<Damage>
  /** Reads and enables pagination through a set of `Damage`. */
  damages?: Maybe<DamagesConnection>
  locksByReference: Array<AxaLock>
  /** Reads and enables pagination through a set of `ModelEquipment`. */
  modelEquipments?: Maybe<ModelEquipmentConnection>
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID']
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query
  supplier?: Maybe<Supplier>
  supplierByName?: Maybe<Supplier>
  /** Reads a single `Supplier` using its globally unique `ID`. */
  supplierByNodeId?: Maybe<Supplier>
  supplierByPrefix?: Maybe<Supplier>
  /** Reads and enables pagination through a set of `Supplier`. */
  suppliers?: Maybe<SuppliersConnection>
  vehicle?: Maybe<Vehicle>
  vehicleAssignment?: Maybe<VehicleAssignment>
  /** Reads a single `VehicleAssignment` using its globally unique `ID`. */
  vehicleAssignmentByNodeId?: Maybe<VehicleAssignment>
  /** Reads and enables pagination through a set of `VehicleAssignment`. */
  vehicleAssignments?: Maybe<VehicleAssignmentsConnection>
  /** Reads a single `Vehicle` using its globally unique `ID`. */
  vehicleByNodeId?: Maybe<Vehicle>
  vehicleEquipmentByVehicleIdAndEquipment?: Maybe<VehicleEquipment>
  /** Reads and enables pagination through a set of `VehicleEquipment`. */
  vehicleEquipments?: Maybe<VehicleEquipmentConnection>
  vehicleModel?: Maybe<VehicleModel>
  /** Reads a single `VehicleModel` using its globally unique `ID`. */
  vehicleModelByNodeId?: Maybe<VehicleModel>
  /** Reads and enables pagination through a set of `VehicleModel`. */
  vehicleModels?: Maybe<VehicleModelsConnection>
  /** Reads and enables pagination through a set of `Vehicle`. */
  vehicles?: Maybe<VehiclesConnection>
}

/** The root query type which gives access points into the data universe. */
export type QueryDamageArgs = {
  id: Scalars['UUID']
}

/** The root query type which gives access points into the data universe. */
export type QueryDamageByNodeIdArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryDamagesArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<DamageCondition>
  filter?: InputMaybe<DamageFilter>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<DamagesOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryLocksByReferenceArgs = {
  references: Array<Scalars['String']>
}

/** The root query type which gives access points into the data universe. */
export type QueryModelEquipmentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<ModelEquipmentCondition>
  filter?: InputMaybe<ModelEquipmentFilter>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ModelEquipmentOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QuerySupplierArgs = {
  id: Scalars['UUID']
}

/** The root query type which gives access points into the data universe. */
export type QuerySupplierByNameArgs = {
  name: Scalars['String']
}

/** The root query type which gives access points into the data universe. */
export type QuerySupplierByNodeIdArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QuerySupplierByPrefixArgs = {
  prefix: Scalars['String']
}

/** The root query type which gives access points into the data universe. */
export type QuerySuppliersArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<SupplierCondition>
  filter?: InputMaybe<SupplierFilter>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<SuppliersOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryVehicleArgs = {
  id: Scalars['UUID']
}

/** The root query type which gives access points into the data universe. */
export type QueryVehicleAssignmentArgs = {
  id: Scalars['UUID']
}

/** The root query type which gives access points into the data universe. */
export type QueryVehicleAssignmentByNodeIdArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryVehicleAssignmentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<VehicleAssignmentCondition>
  filter?: InputMaybe<VehicleAssignmentFilter>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VehicleAssignmentsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryVehicleByNodeIdArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryVehicleEquipmentByVehicleIdAndEquipmentArgs = {
  equipment: VehicleEquipmentType
  vehicleId: Scalars['UUID']
}

/** The root query type which gives access points into the data universe. */
export type QueryVehicleEquipmentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<VehicleEquipmentCondition>
  filter?: InputMaybe<VehicleEquipmentFilter>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VehicleEquipmentOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryVehicleModelArgs = {
  id: Scalars['UUID']
}

/** The root query type which gives access points into the data universe. */
export type QueryVehicleModelByNodeIdArgs = {
  nodeId: Scalars['ID']
}

/** The root query type which gives access points into the data universe. */
export type QueryVehicleModelsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<VehicleModelCondition>
  filter?: InputMaybe<VehicleModelFilter>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VehicleModelsOrderBy>>
}

/** The root query type which gives access points into the data universe. */
export type QueryVehiclesArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<VehicleCondition>
  filter?: InputMaybe<VehicleFilter>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VehiclesOrderBy>>
}

/** All input for the `reassignVehicle` mutation. */
export type ReassignVehicleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  riderId: Scalars['String']
  vehicleId: Scalars['UUID']
}

/** The output of our `reassignVehicle` mutation. */
export type ReassignVehiclePayload = {
  __typename?: 'ReassignVehiclePayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
  /** Reads a single `Vehicle` that is related to this `VehicleAssignment`. */
  vehicle?: Maybe<Vehicle>
  vehicleAssignment?: Maybe<VehicleAssignment>
  /** An edge for our `VehicleAssignment`. May be used by Relay 1. */
  vehicleAssignmentEdge?: Maybe<VehicleAssignmentsEdge>
}

/** The output of our `reassignVehicle` mutation. */
export type ReassignVehiclePayloadVehicleAssignmentEdgeArgs = {
  orderBy?: InputMaybe<Array<VehicleAssignmentsOrderBy>>
}

export type Rider = {
  __typename?: 'Rider'
  assignedAt: Scalars['Datetime']
  employeeNumber?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  riderId: Scalars['String']
  status?: Maybe<RiderStatus>
  unassignedAt?: Maybe<Scalars['Datetime']>
}

export enum RiderStatus {
  Busy = 'BUSY',
  Idle = 'IDLE',
  Offline = 'OFFLINE',
  Online = 'ONLINE',
  OnBreak = 'ON_BREAK',
  Returning = 'RETURNING',
  Starting = 'STARTING',
  TempOffline = 'TEMP_OFFLINE',
}

export enum RoutingProfileType {
  Bicycle = 'BICYCLE',
  Drive = 'DRIVE',
  TwoWheeler = 'TWO_WHEELER',
}

/** A filter to be used against RoutingProfileType fields. All fields are combined with a logical ‘and.’ */
export type RoutingProfileTypeFilter = {
  /** Equal to the specified value. */
  equalTo?: InputMaybe<RoutingProfileType>
  /** Included in the specified list. */
  in?: InputMaybe<Array<RoutingProfileType>>
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>
  /** Less than the specified value. */
  lessThan?: InputMaybe<RoutingProfileType>
}

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['String']>
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: InputMaybe<Scalars['String']>
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['String']>>
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars['String']>
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['String']>
}

export type Supplier = Node & {
  __typename?: 'Supplier'
  createdAt: Scalars['Datetime']
  id: Scalars['UUID']
  name: Scalars['String']
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  prefix: Scalars['String']
  updatedAt: Scalars['Datetime']
  /** Reads and enables pagination through a set of `VehicleModel`. */
  vehicleModels: VehicleModelsConnection
  /** Reads and enables pagination through a set of `Vehicle`. */
  vehicles: VehiclesConnection
}

export type SupplierVehicleModelsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<VehicleModelCondition>
  filter?: InputMaybe<VehicleModelFilter>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VehicleModelsOrderBy>>
}

export type SupplierVehiclesArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<VehicleCondition>
  filter?: InputMaybe<VehicleFilter>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VehiclesOrderBy>>
}

/**
 * A condition to be used against `Supplier` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type SupplierCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `prefix` field. */
  prefix?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>
}

/** A filter to be used against `Supplier` object types. All fields are combined with a logical ‘and.’ */
export type SupplierFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SupplierFilter>>
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>
  /** Negates the expression. */
  not?: InputMaybe<SupplierFilter>
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SupplierFilter>>
  /** Filter by the object’s `prefix` field. */
  prefix?: InputMaybe<StringFilter>
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>
  /** Filter by the object’s `vehicleModels` relation. */
  vehicleModels?: InputMaybe<SupplierToManyVehicleModelFilter>
  /** Some related `vehicleModels` exist. */
  vehicleModelsExist?: InputMaybe<Scalars['Boolean']>
  /** Filter by the object’s `vehicles` relation. */
  vehicles?: InputMaybe<SupplierToManyVehicleFilter>
  /** Some related `vehicles` exist. */
  vehiclesExist?: InputMaybe<Scalars['Boolean']>
}

/** An input for mutations affecting `Supplier` */
export type SupplierInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>
  id?: InputMaybe<Scalars['UUID']>
  name: Scalars['String']
  prefix: Scalars['String']
  updatedAt?: InputMaybe<Scalars['Datetime']>
}

/** Represents an update to a `Supplier`. Fields that are set will be updated. */
export type SupplierPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>
  id?: InputMaybe<Scalars['UUID']>
  name?: InputMaybe<Scalars['String']>
  prefix?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['Datetime']>
}

/** A filter to be used against many `Vehicle` object types. All fields are combined with a logical ‘and.’ */
export type SupplierToManyVehicleFilter = {
  /** Every related `Vehicle` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<VehicleFilter>
  /** No related `Vehicle` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<VehicleFilter>
  /** Some related `Vehicle` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<VehicleFilter>
}

/** A filter to be used against many `VehicleModel` object types. All fields are combined with a logical ‘and.’ */
export type SupplierToManyVehicleModelFilter = {
  /** Every related `VehicleModel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<VehicleModelFilter>
  /** No related `VehicleModel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<VehicleModelFilter>
  /** Some related `VehicleModel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<VehicleModelFilter>
}

/** A connection to a list of `Supplier` values. */
export type SuppliersConnection = {
  __typename?: 'SuppliersConnection'
  /** A list of edges which contains the `Supplier` and cursor to aid in pagination. */
  edges: Array<SuppliersEdge>
  /** A list of `Supplier` objects. */
  nodes: Array<Supplier>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Supplier` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Supplier` edge in the connection. */
export type SuppliersEdge = {
  __typename?: 'SuppliersEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `Supplier` at the end of the edge. */
  node: Supplier
}

/** Methods to use when ordering `Supplier`. */
export enum SuppliersOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrefixAsc = 'PREFIX_ASC',
  PrefixDesc = 'PREFIX_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['UUID']>
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['UUID']>>
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['UUID']>
}

/** All input for the `unassignVehicle` mutation. */
export type UnassignVehicleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  vehicleId: Scalars['UUID']
}

/** The output of our `unassignVehicle` mutation. */
export type UnassignVehiclePayload = {
  __typename?: 'UnassignVehiclePayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
  /** Reads a single `Vehicle` that is related to this `VehicleAssignment`. */
  vehicle?: Maybe<Vehicle>
  vehicleAssignment?: Maybe<VehicleAssignment>
  /** An edge for our `VehicleAssignment`. May be used by Relay 1. */
  vehicleAssignmentEdge?: Maybe<VehicleAssignmentsEdge>
}

/** The output of our `unassignVehicle` mutation. */
export type UnassignVehiclePayloadVehicleAssignmentEdgeArgs = {
  orderBy?: InputMaybe<Array<VehicleAssignmentsOrderBy>>
}

/** All input for the `unassignVehicleV2` mutation. */
export type UnassignVehicleV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  riderId: Scalars['String']
  vehicleId: Scalars['UUID']
}

/** The output of our `unassignVehicleV2` mutation. */
export type UnassignVehicleV2Payload = {
  __typename?: 'UnassignVehicleV2Payload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
  /** Reads a single `Vehicle` that is related to this `VehicleAssignment`. */
  vehicle?: Maybe<Vehicle>
  vehicleAssignment?: Maybe<VehicleAssignment>
  /** An edge for our `VehicleAssignment`. May be used by Relay 1. */
  vehicleAssignmentEdge?: Maybe<VehicleAssignmentsEdge>
}

/** The output of our `unassignVehicleV2` mutation. */
export type UnassignVehicleV2PayloadVehicleAssignmentEdgeArgs = {
  orderBy?: InputMaybe<Array<VehicleAssignmentsOrderBy>>
}

/** All input for the `updateDamageByNodeId` mutation. */
export type UpdateDamageByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `Damage` to be updated. */
  nodeId: Scalars['ID']
  /** An object where the defined keys will be set on the `Damage` being updated. */
  patch: DamagePatch
}

/** All input for the `updateDamage` mutation. */
export type UpdateDamageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['UUID']
  /** An object where the defined keys will be set on the `Damage` being updated. */
  patch: DamagePatch
}

/** The output of our update `Damage` mutation. */
export type UpdateDamagePayload = {
  __typename?: 'UpdateDamagePayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** The `Damage` that was updated by this mutation. */
  damage?: Maybe<Damage>
  /** An edge for our `Damage`. May be used by Relay 1. */
  damageEdge?: Maybe<DamagesEdge>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
  /** Reads a single `Vehicle` that is related to this `Damage`. */
  vehicle?: Maybe<Vehicle>
}

/** The output of our update `Damage` mutation. */
export type UpdateDamagePayloadDamageEdgeArgs = {
  orderBy?: InputMaybe<Array<DamagesOrderBy>>
}

/** All input for the `updateSupplierByName` mutation. */
export type UpdateSupplierByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  /** An object where the defined keys will be set on the `Supplier` being updated. */
  patch: SupplierPatch
}

/** All input for the `updateSupplierByNodeId` mutation. */
export type UpdateSupplierByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `Supplier` to be updated. */
  nodeId: Scalars['ID']
  /** An object where the defined keys will be set on the `Supplier` being updated. */
  patch: SupplierPatch
}

/** All input for the `updateSupplierByPrefix` mutation. */
export type UpdateSupplierByPrefixInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** An object where the defined keys will be set on the `Supplier` being updated. */
  patch: SupplierPatch
  prefix: Scalars['String']
}

/** All input for the `updateSupplier` mutation. */
export type UpdateSupplierInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['UUID']
  /** An object where the defined keys will be set on the `Supplier` being updated. */
  patch: SupplierPatch
}

/** The output of our update `Supplier` mutation. */
export type UpdateSupplierPayload = {
  __typename?: 'UpdateSupplierPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
  /** The `Supplier` that was updated by this mutation. */
  supplier?: Maybe<Supplier>
  /** An edge for our `Supplier`. May be used by Relay 1. */
  supplierEdge?: Maybe<SuppliersEdge>
}

/** The output of our update `Supplier` mutation. */
export type UpdateSupplierPayloadSupplierEdgeArgs = {
  orderBy?: InputMaybe<Array<SuppliersOrderBy>>
}

/** All input for the `updateVehicleAssignmentByNodeId` mutation. */
export type UpdateVehicleAssignmentByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `VehicleAssignment` to be updated. */
  nodeId: Scalars['ID']
  /** An object where the defined keys will be set on the `VehicleAssignment` being updated. */
  patch: VehicleAssignmentPatch
}

/** All input for the `updateVehicleAssignment` mutation. */
export type UpdateVehicleAssignmentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['UUID']
  /** An object where the defined keys will be set on the `VehicleAssignment` being updated. */
  patch: VehicleAssignmentPatch
}

/** The output of our update `VehicleAssignment` mutation. */
export type UpdateVehicleAssignmentPayload = {
  __typename?: 'UpdateVehicleAssignmentPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
  /** Reads a single `Vehicle` that is related to this `VehicleAssignment`. */
  vehicle?: Maybe<Vehicle>
  /** The `VehicleAssignment` that was updated by this mutation. */
  vehicleAssignment?: Maybe<VehicleAssignment>
  /** An edge for our `VehicleAssignment`. May be used by Relay 1. */
  vehicleAssignmentEdge?: Maybe<VehicleAssignmentsEdge>
}

/** The output of our update `VehicleAssignment` mutation. */
export type UpdateVehicleAssignmentPayloadVehicleAssignmentEdgeArgs = {
  orderBy?: InputMaybe<Array<VehicleAssignmentsOrderBy>>
}

/** All input for the `updateVehicleByNodeId` mutation. */
export type UpdateVehicleByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `Vehicle` to be updated. */
  nodeId: Scalars['ID']
  /** An object where the defined keys will be set on the `Vehicle` being updated. */
  patch: VehiclePatch
}

/** All input for the `updateVehicleEquipmentByVehicleIdAndEquipment` mutation. */
export type UpdateVehicleEquipmentByVehicleIdAndEquipmentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  equipment: VehicleEquipmentType
  /** An object where the defined keys will be set on the `VehicleEquipment` being updated. */
  patch: VehicleEquipmentPatch
  vehicleId: Scalars['UUID']
}

/** The output of our update `VehicleEquipment` mutation. */
export type UpdateVehicleEquipmentPayload = {
  __typename?: 'UpdateVehicleEquipmentPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
  /** Reads a single `Vehicle` that is related to this `VehicleEquipment`. */
  vehicle?: Maybe<Vehicle>
  /** The `VehicleEquipment` that was updated by this mutation. */
  vehicleEquipment?: Maybe<VehicleEquipment>
  /** An edge for our `VehicleEquipment`. May be used by Relay 1. */
  vehicleEquipmentEdge?: Maybe<VehicleEquipmentEdge>
}

/** The output of our update `VehicleEquipment` mutation. */
export type UpdateVehicleEquipmentPayloadVehicleEquipmentEdgeArgs = {
  orderBy?: InputMaybe<Array<VehicleEquipmentOrderBy>>
}

/** All input for the `updateVehicleEquipments` mutation. */
export type UpdateVehicleEquipmentsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  vehicleEquipments?: InputMaybe<Array<InputMaybe<VehicleEquipmentInput>>>
}

/** The output of our `updateVehicleEquipments` mutation. */
export type UpdateVehicleEquipmentsPayload = {
  __typename?: 'UpdateVehicleEquipmentsPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
}

/** All input for the `updateVehicle` mutation. */
export type UpdateVehicleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['UUID']
  /** An object where the defined keys will be set on the `Vehicle` being updated. */
  patch: VehiclePatch
}

/** All input for the `updateVehicleModelByNodeId` mutation. */
export type UpdateVehicleModelByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The globally unique `ID` which will identify a single `VehicleModel` to be updated. */
  nodeId: Scalars['ID']
  /** An object where the defined keys will be set on the `VehicleModel` being updated. */
  patch: VehicleModelPatch
}

/** All input for the `updateVehicleModel` mutation. */
export type UpdateVehicleModelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['UUID']
  /** An object where the defined keys will be set on the `VehicleModel` being updated. */
  patch: VehicleModelPatch
}

/** The output of our update `VehicleModel` mutation. */
export type UpdateVehicleModelPayload = {
  __typename?: 'UpdateVehicleModelPayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
  /** Reads a single `Supplier` that is related to this `VehicleModel`. */
  supplier?: Maybe<Supplier>
  /** The `VehicleModel` that was updated by this mutation. */
  vehicleModel?: Maybe<VehicleModel>
  /** An edge for our `VehicleModel`. May be used by Relay 1. */
  vehicleModelEdge?: Maybe<VehicleModelsEdge>
}

/** The output of our update `VehicleModel` mutation. */
export type UpdateVehicleModelPayloadVehicleModelEdgeArgs = {
  orderBy?: InputMaybe<Array<VehicleModelsOrderBy>>
}

/** The output of our update `Vehicle` mutation. */
export type UpdateVehiclePayload = {
  __typename?: 'UpdateVehiclePayload'
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>
  /** Reads a single `VehicleModel` that is related to this `Vehicle`. */
  model?: Maybe<VehicleModel>
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>
  /** Reads a single `Supplier` that is related to this `Vehicle`. */
  supplier?: Maybe<Supplier>
  /** The `Vehicle` that was updated by this mutation. */
  vehicle?: Maybe<Vehicle>
  /** An edge for our `Vehicle`. May be used by Relay 1. */
  vehicleEdge?: Maybe<VehiclesEdge>
}

/** The output of our update `Vehicle` mutation. */
export type UpdateVehiclePayloadVehicleEdgeArgs = {
  orderBy?: InputMaybe<Array<VehiclesOrderBy>>
}

export type Vehicle = Node & {
  __typename?: 'Vehicle'
  archivedAt?: Maybe<Scalars['Datetime']>
  assigned?: Maybe<Scalars['Boolean']>
  availabilityStatus?: Maybe<VehicleAvailabilityStatus>
  bluetooth: Scalars['Boolean']
  createdAt: Scalars['Datetime']
  /** Reads and enables pagination through a set of `Damage`. */
  damages: DamagesConnection
  hubSlug: Scalars['String']
  id: Scalars['UUID']
  imageUrl?: Maybe<Scalars['String']>
  kind: VehicleKind
  licensePlate: Scalars['String']
  location?: Maybe<Scalars['JSON']>
  lock?: Maybe<VehicleLock>
  mileageRemaining?: Maybe<Scalars['Int']>
  mileageTotal?: Maybe<Scalars['Int']>
  /** Reads a single `VehicleModel` that is related to this `Vehicle`. */
  model?: Maybe<VehicleModel>
  modelId?: Maybe<Scalars['UUID']>
  modelName?: Maybe<Scalars['String']>
  moduleId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  operationalStatus: VehicleOperationalStatus
  organizationId?: Maybe<Scalars['UUID']>
  pricingDurationConfigId?: Maybe<Scalars['UUID']>
  properties?: Maybe<Scalars['JSON']>
  provider?: Maybe<VehicleTelematicsProvider>
  rider?: Maybe<Rider>
  sku?: Maybe<Scalars['String']>
  source?: Maybe<VehicleSource>
  speed?: Maybe<Scalars['Int']>
  statusUpdatedAt?: Maybe<Scalars['Datetime']>
  /** Reads a single `Supplier` that is related to this `Vehicle`. */
  supplier?: Maybe<Supplier>
  supplierId: Scalars['UUID']
  supplierName?: Maybe<Scalars['String']>
  supportedCommands: Array<Maybe<VehicleSupportedCommands>>
  updatedAt: Scalars['Datetime']
  /** Reads and enables pagination through a set of `VehicleAssignment`. */
  vehicleAssignmentsByVehicleId: VehicleAssignmentsConnection
  /** Reads and enables pagination through a set of `VehicleEquipment`. */
  vehicleEquipments: VehicleEquipmentConnection
  vehicleModel?: Maybe<VehicleModel>
  vehicleSupplier?: Maybe<Supplier>
}

export type VehicleDamagesArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<DamageCondition>
  filter?: InputMaybe<DamageFilter>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<DamagesOrderBy>>
}

export type VehicleVehicleAssignmentsByVehicleIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<VehicleAssignmentCondition>
  filter?: InputMaybe<VehicleAssignmentFilter>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VehicleAssignmentsOrderBy>>
}

export type VehicleVehicleEquipmentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<VehicleEquipmentCondition>
  filter?: InputMaybe<VehicleEquipmentFilter>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VehicleEquipmentOrderBy>>
}

export type VehicleAssignment = Node & {
  __typename?: 'VehicleAssignment'
  assignedAt: Scalars['Datetime']
  id: Scalars['UUID']
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  rider?: Maybe<Rider>
  riderId: Scalars['String']
  unassignedAt?: Maybe<Scalars['Datetime']>
  /** Reads a single `Vehicle` that is related to this `VehicleAssignment`. */
  vehicle?: Maybe<Vehicle>
  vehicleId: Scalars['UUID']
}

/**
 * A condition to be used against `VehicleAssignment` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type VehicleAssignmentCondition = {
  /** Checks for equality with the object’s `assignedAt` field. */
  assignedAt?: InputMaybe<Scalars['Datetime']>
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>
  /** Checks for equality with the object’s `riderId` field. */
  riderId?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `unassignedAt` field. */
  unassignedAt?: InputMaybe<Scalars['Datetime']>
  /** Checks for equality with the object’s `vehicleId` field. */
  vehicleId?: InputMaybe<Scalars['UUID']>
}

/** A filter to be used against `VehicleAssignment` object types. All fields are combined with a logical ‘and.’ */
export type VehicleAssignmentFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<VehicleAssignmentFilter>>
  /** Filter by the object’s `assignedAt` field. */
  assignedAt?: InputMaybe<DatetimeFilter>
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>
  /** Negates the expression. */
  not?: InputMaybe<VehicleAssignmentFilter>
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<VehicleAssignmentFilter>>
  /** Filter by the object’s `riderId` field. */
  riderId?: InputMaybe<StringFilter>
  /** Filter by the object’s `unassignedAt` field. */
  unassignedAt?: InputMaybe<DatetimeFilter>
  /** Filter by the object’s `vehicle` relation. */
  vehicle?: InputMaybe<VehicleFilter>
  /** Filter by the object’s `vehicleId` field. */
  vehicleId?: InputMaybe<UuidFilter>
}

/** An input for mutations affecting `VehicleAssignment` */
export type VehicleAssignmentInput = {
  assignedAt?: InputMaybe<Scalars['Datetime']>
  id?: InputMaybe<Scalars['UUID']>
  riderId: Scalars['String']
  unassignedAt?: InputMaybe<Scalars['Datetime']>
  vehicleId: Scalars['UUID']
}

/** Represents an update to a `VehicleAssignment`. Fields that are set will be updated. */
export type VehicleAssignmentPatch = {
  assignedAt?: InputMaybe<Scalars['Datetime']>
  id?: InputMaybe<Scalars['UUID']>
  riderId?: InputMaybe<Scalars['String']>
  unassignedAt?: InputMaybe<Scalars['Datetime']>
  vehicleId?: InputMaybe<Scalars['UUID']>
}

/** A connection to a list of `VehicleAssignment` values. */
export type VehicleAssignmentsConnection = {
  __typename?: 'VehicleAssignmentsConnection'
  /** A list of edges which contains the `VehicleAssignment` and cursor to aid in pagination. */
  edges: Array<VehicleAssignmentsEdge>
  /** A list of `VehicleAssignment` objects. */
  nodes: Array<VehicleAssignment>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `VehicleAssignment` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `VehicleAssignment` edge in the connection. */
export type VehicleAssignmentsEdge = {
  __typename?: 'VehicleAssignmentsEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `VehicleAssignment` at the end of the edge. */
  node: VehicleAssignment
}

/** Methods to use when ordering `VehicleAssignment`. */
export enum VehicleAssignmentsOrderBy {
  AssignedAtAsc = 'ASSIGNED_AT_ASC',
  AssignedAtDesc = 'ASSIGNED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RiderIdAsc = 'RIDER_ID_ASC',
  RiderIdDesc = 'RIDER_ID_DESC',
  UnassignedAtAsc = 'UNASSIGNED_AT_ASC',
  UnassignedAtDesc = 'UNASSIGNED_AT_DESC',
  VehicleIdAsc = 'VEHICLE_ID_ASC',
  VehicleIdDesc = 'VEHICLE_ID_DESC',
}

export enum VehicleAvailabilityStatus {
  Assigned = 'ASSIGNED',
  Available = 'AVAILABLE',
  Unavailable = 'UNAVAILABLE',
}

/** A filter to be used against VehicleAvailabilityStatus fields. All fields are combined with a logical ‘and.’ */
export type VehicleAvailabilityStatusFilter = {
  /** Equal to the specified value. */
  equalTo?: InputMaybe<VehicleAvailabilityStatus>
  /** Included in the specified list. */
  in?: InputMaybe<Array<VehicleAvailabilityStatus>>
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>
  /** Less than the specified value. */
  lessThan?: InputMaybe<VehicleAvailabilityStatus>
}

/** A condition to be used against `Vehicle` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type VehicleCondition = {
  /** Checks for equality with the object’s `archivedAt` field. */
  archivedAt?: InputMaybe<Scalars['Datetime']>
  /** Checks for equality with the object’s `bluetooth` field. */
  bluetooth?: InputMaybe<Scalars['Boolean']>
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>
  /** Checks for equality with the object’s `hubSlug` field. */
  hubSlug?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>
  /** Checks for equality with the object’s `imageUrl` field. */
  imageUrl?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `kind` field. */
  kind?: InputMaybe<VehicleKind>
  /** Checks for equality with the object’s `licensePlate` field. */
  licensePlate?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `location` field. */
  location?: InputMaybe<Scalars['JSON']>
  /** Checks for equality with the object’s `mileageRemaining` field. */
  mileageRemaining?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `mileageTotal` field. */
  mileageTotal?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `modelId` field. */
  modelId?: InputMaybe<Scalars['UUID']>
  /** Checks for equality with the object’s `modelName` field. */
  modelName?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `moduleId` field. */
  moduleId?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `operationalStatus` field. */
  operationalStatus?: InputMaybe<VehicleOperationalStatus>
  /** Checks for equality with the object’s `organizationId` field. */
  organizationId?: InputMaybe<Scalars['UUID']>
  /** Checks for equality with the object’s `pricingDurationConfigId` field. */
  pricingDurationConfigId?: InputMaybe<Scalars['UUID']>
  /** Checks for equality with the object’s `properties` field. */
  properties?: InputMaybe<Scalars['JSON']>
  /** Checks for equality with the object’s `provider` field. */
  provider?: InputMaybe<VehicleTelematicsProvider>
  /** Checks for equality with the object’s `speed` field. */
  speed?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `statusUpdatedAt` field. */
  statusUpdatedAt?: InputMaybe<Scalars['Datetime']>
  /** Checks for equality with the object’s `supplierId` field. */
  supplierId?: InputMaybe<Scalars['UUID']>
  /** Checks for equality with the object’s `supplierName` field. */
  supplierName?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `supportedCommands` field. */
  supportedCommands?: InputMaybe<Array<InputMaybe<VehicleSupportedCommands>>>
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>
}

export type VehicleEquipment = {
  __typename?: 'VehicleEquipment'
  count: Scalars['Int']
  equipment: VehicleEquipmentType
  updatedAt: Scalars['Datetime']
  updatedBy: Scalars['String']
  /** Reads a single `Vehicle` that is related to this `VehicleEquipment`. */
  vehicle?: Maybe<Vehicle>
  vehicleId?: Maybe<Scalars['UUID']>
}

/**
 * A condition to be used against `VehicleEquipment` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type VehicleEquipmentCondition = {
  /** Checks for equality with the object’s `count` field. */
  count?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `equipment` field. */
  equipment?: InputMaybe<VehicleEquipmentType>
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `vehicleId` field. */
  vehicleId?: InputMaybe<Scalars['UUID']>
}

/** A connection to a list of `VehicleEquipment` values. */
export type VehicleEquipmentConnection = {
  __typename?: 'VehicleEquipmentConnection'
  /** A list of edges which contains the `VehicleEquipment` and cursor to aid in pagination. */
  edges: Array<VehicleEquipmentEdge>
  /** A list of `VehicleEquipment` objects. */
  nodes: Array<VehicleEquipment>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `VehicleEquipment` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `VehicleEquipment` edge in the connection. */
export type VehicleEquipmentEdge = {
  __typename?: 'VehicleEquipmentEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `VehicleEquipment` at the end of the edge. */
  node: VehicleEquipment
}

/** A filter to be used against `VehicleEquipment` object types. All fields are combined with a logical ‘and.’ */
export type VehicleEquipmentFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<VehicleEquipmentFilter>>
  /** Filter by the object’s `count` field. */
  count?: InputMaybe<IntFilter>
  /** Filter by the object’s `equipment` field. */
  equipment?: InputMaybe<VehicleEquipmentTypeFilter>
  /** Negates the expression. */
  not?: InputMaybe<VehicleEquipmentFilter>
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<VehicleEquipmentFilter>>
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<StringFilter>
  /** Filter by the object’s `vehicle` relation. */
  vehicle?: InputMaybe<VehicleFilter>
  /** A related `vehicle` exists. */
  vehicleExists?: InputMaybe<Scalars['Boolean']>
  /** Filter by the object’s `vehicleId` field. */
  vehicleId?: InputMaybe<UuidFilter>
}

/** An input for mutations affecting `VehicleEquipment` */
export type VehicleEquipmentInput = {
  count: Scalars['Int']
  equipment: VehicleEquipmentType
  updatedAt?: InputMaybe<Scalars['Datetime']>
  updatedBy: Scalars['String']
  vehicleId?: InputMaybe<Scalars['UUID']>
}

/** Methods to use when ordering `VehicleEquipment`. */
export enum VehicleEquipmentOrderBy {
  CountAsc = 'COUNT_ASC',
  CountDesc = 'COUNT_DESC',
  EquipmentAsc = 'EQUIPMENT_ASC',
  EquipmentDesc = 'EQUIPMENT_DESC',
  Natural = 'NATURAL',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  VehicleIdAsc = 'VEHICLE_ID_ASC',
  VehicleIdDesc = 'VEHICLE_ID_DESC',
}

/** Represents an update to a `VehicleEquipment`. Fields that are set will be updated. */
export type VehicleEquipmentPatch = {
  count?: InputMaybe<Scalars['Int']>
  equipment?: InputMaybe<VehicleEquipmentType>
  updatedAt?: InputMaybe<Scalars['Datetime']>
  updatedBy?: InputMaybe<Scalars['String']>
  vehicleId?: InputMaybe<Scalars['UUID']>
}

export enum VehicleEquipmentType {
  Box = 'BOX',
  SaddleBag = 'SADDLE_BAG',
}

/** A filter to be used against VehicleEquipmentType fields. All fields are combined with a logical ‘and.’ */
export type VehicleEquipmentTypeFilter = {
  /** Equal to the specified value. */
  equalTo?: InputMaybe<VehicleEquipmentType>
  /** Included in the specified list. */
  in?: InputMaybe<Array<VehicleEquipmentType>>
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>
  /** Less than the specified value. */
  lessThan?: InputMaybe<VehicleEquipmentType>
}

/** A filter to be used against `Vehicle` object types. All fields are combined with a logical ‘and.’ */
export type VehicleFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<VehicleFilter>>
  /** Filter by the object’s `archivedAt` field. */
  archivedAt?: InputMaybe<DatetimeFilter>
  /** Filter by the object’s `assigned` field. */
  assigned?: InputMaybe<BooleanFilter>
  /** Filter by the object’s `availabilityStatus` field. */
  availabilityStatus?: InputMaybe<VehicleAvailabilityStatusFilter>
  /** Filter by the object’s `bluetooth` field. */
  bluetooth?: InputMaybe<BooleanFilter>
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>
  /** Filter by the object’s `damages` relation. */
  damages?: InputMaybe<VehicleToManyDamageFilter>
  /** Some related `damages` exist. */
  damagesExist?: InputMaybe<Scalars['Boolean']>
  /** Filter by the object’s `hubSlug` field. */
  hubSlug?: InputMaybe<StringFilter>
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>
  /** Filter by the object’s `imageUrl` field. */
  imageUrl?: InputMaybe<StringFilter>
  /** Filter by the object’s `kind` field. */
  kind?: InputMaybe<VehicleKindFilter>
  /** Filter by the object’s `licensePlate` field. */
  licensePlate?: InputMaybe<StringFilter>
  /** Filter by the object’s `location` field. */
  location?: InputMaybe<JsonFilter>
  /** Filter by the object’s `mileageRemaining` field. */
  mileageRemaining?: InputMaybe<IntFilter>
  /** Filter by the object’s `mileageTotal` field. */
  mileageTotal?: InputMaybe<IntFilter>
  /** Filter by the object’s `model` relation. */
  model?: InputMaybe<VehicleModelFilter>
  /** A related `model` exists. */
  modelExists?: InputMaybe<Scalars['Boolean']>
  /** Filter by the object’s `modelId` field. */
  modelId?: InputMaybe<UuidFilter>
  /** Filter by the object’s `modelName` field. */
  modelName?: InputMaybe<StringFilter>
  /** Filter by the object’s `moduleId` field. */
  moduleId?: InputMaybe<StringFilter>
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>
  /** Negates the expression. */
  not?: InputMaybe<VehicleFilter>
  /** Filter by the object’s `operationalStatus` field. */
  operationalStatus?: InputMaybe<VehicleOperationalStatusFilter>
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<VehicleFilter>>
  /** Filter by the object’s `organizationId` field. */
  organizationId?: InputMaybe<UuidFilter>
  /** Filter by the object’s `pricingDurationConfigId` field. */
  pricingDurationConfigId?: InputMaybe<UuidFilter>
  /** Filter by the object’s `provider` field. */
  provider?: InputMaybe<VehicleTelematicsProviderFilter>
  /** Filter by the object’s `sku` field. */
  sku?: InputMaybe<StringFilter>
  /** Filter by the object’s `source` field. */
  source?: InputMaybe<VehicleSourceFilter>
  /** Filter by the object’s `speed` field. */
  speed?: InputMaybe<IntFilter>
  /** Filter by the object’s `statusUpdatedAt` field. */
  statusUpdatedAt?: InputMaybe<DatetimeFilter>
  /** Filter by the object’s `supplier` relation. */
  supplier?: InputMaybe<SupplierFilter>
  /** Filter by the object’s `supplierId` field. */
  supplierId?: InputMaybe<UuidFilter>
  /** Filter by the object’s `supplierName` field. */
  supplierName?: InputMaybe<StringFilter>
  /** Filter by the object’s `supportedCommands` field. */
  supportedCommands?: InputMaybe<VehicleSupportedCommandsListFilter>
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>
  /** Filter by the object’s `vehicleAssignmentsByVehicleId` relation. */
  vehicleAssignmentsByVehicleId?: InputMaybe<VehicleToManyVehicleAssignmentFilter>
  /** Some related `vehicleAssignmentsByVehicleId` exist. */
  vehicleAssignmentsByVehicleIdExist?: InputMaybe<Scalars['Boolean']>
  /** Filter by the object’s `vehicleEquipments` relation. */
  vehicleEquipments?: InputMaybe<VehicleToManyVehicleEquipmentFilter>
  /** Some related `vehicleEquipments` exist. */
  vehicleEquipmentsExist?: InputMaybe<Scalars['Boolean']>
}

/** An input for mutations affecting `Vehicle` */
export type VehicleInput = {
  archivedAt?: InputMaybe<Scalars['Datetime']>
  bluetooth?: InputMaybe<Scalars['Boolean']>
  createdAt?: InputMaybe<Scalars['Datetime']>
  hubSlug: Scalars['String']
  id?: InputMaybe<Scalars['UUID']>
  imageUrl?: InputMaybe<Scalars['String']>
  kind: VehicleKind
  licensePlate: Scalars['String']
  location?: InputMaybe<Scalars['JSON']>
  mileageRemaining?: InputMaybe<Scalars['Int']>
  mileageTotal?: InputMaybe<Scalars['Int']>
  modelId?: InputMaybe<Scalars['UUID']>
  moduleId?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  operationalStatus?: InputMaybe<VehicleOperationalStatus>
  organizationId?: InputMaybe<Scalars['UUID']>
  pricingDurationConfigId?: InputMaybe<Scalars['UUID']>
  properties?: InputMaybe<Scalars['JSON']>
  provider?: InputMaybe<VehicleTelematicsProvider>
  speed?: InputMaybe<Scalars['Int']>
  statusUpdatedAt?: InputMaybe<Scalars['Datetime']>
  supplierId: Scalars['UUID']
  supportedCommands?: InputMaybe<Array<InputMaybe<VehicleSupportedCommands>>>
  updatedAt?: InputMaybe<Scalars['Datetime']>
}

export enum VehicleKind {
  Bicycle = 'BICYCLE',
  Car = 'CAR',
  Cargo = 'CARGO',
  KickScooter = 'KICK_SCOOTER',
  Scooter = 'SCOOTER',
  Truck = 'TRUCK',
}

/** A filter to be used against VehicleKind fields. All fields are combined with a logical ‘and.’ */
export type VehicleKindFilter = {
  /** Equal to the specified value. */
  equalTo?: InputMaybe<VehicleKind>
  /** Included in the specified list. */
  in?: InputMaybe<Array<VehicleKind>>
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>
  /** Less than the specified value. */
  lessThan?: InputMaybe<VehicleKind>
}

export type VehicleLock = {
  __typename?: 'VehicleLock'
  key?: Maybe<LockKey>
  type: LockType
  uid?: Maybe<Scalars['String']>
}

export type VehicleModel = Node & {
  __typename?: 'VehicleModel'
  axa?: Maybe<Scalars['Boolean']>
  createdAt: Scalars['Datetime']
  id: Scalars['UUID']
  kind: VehicleKind
  maxWeightGrams: Scalars['Int']
  /** Reads and enables pagination through a set of `ModelEquipment`. */
  modelEquipments: ModelEquipmentConnection
  name: Scalars['String']
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']
  routingProfile: RoutingProfileType
  /** Reads a single `Supplier` that is related to this `VehicleModel`. */
  supplier?: Maybe<Supplier>
  supplierId: Scalars['UUID']
  updatedAt: Scalars['Datetime']
  /** Reads and enables pagination through a set of `Vehicle`. */
  vehiclesByModelId: VehiclesConnection
}

export type VehicleModelModelEquipmentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<ModelEquipmentCondition>
  filter?: InputMaybe<ModelEquipmentFilter>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ModelEquipmentOrderBy>>
}

export type VehicleModelVehiclesByModelIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  condition?: InputMaybe<VehicleCondition>
  filter?: InputMaybe<VehicleFilter>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VehiclesOrderBy>>
}

/**
 * A condition to be used against `VehicleModel` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type VehicleModelCondition = {
  /** Checks for equality with the object’s `axa` field. */
  axa?: InputMaybe<Scalars['Boolean']>
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>
  /** Checks for equality with the object’s `kind` field. */
  kind?: InputMaybe<VehicleKind>
  /** Checks for equality with the object’s `maxWeightGrams` field. */
  maxWeightGrams?: InputMaybe<Scalars['Int']>
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>
  /** Checks for equality with the object’s `routingProfile` field. */
  routingProfile?: InputMaybe<RoutingProfileType>
  /** Checks for equality with the object’s `supplierId` field. */
  supplierId?: InputMaybe<Scalars['UUID']>
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>
}

/** A filter to be used against `VehicleModel` object types. All fields are combined with a logical ‘and.’ */
export type VehicleModelFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<VehicleModelFilter>>
  /** Filter by the object’s `axa` field. */
  axa?: InputMaybe<BooleanFilter>
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>
  /** Filter by the object’s `kind` field. */
  kind?: InputMaybe<VehicleKindFilter>
  /** Filter by the object’s `maxWeightGrams` field. */
  maxWeightGrams?: InputMaybe<IntFilter>
  /** Filter by the object’s `modelEquipments` relation. */
  modelEquipments?: InputMaybe<VehicleModelToManyModelEquipmentFilter>
  /** Some related `modelEquipments` exist. */
  modelEquipmentsExist?: InputMaybe<Scalars['Boolean']>
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>
  /** Negates the expression. */
  not?: InputMaybe<VehicleModelFilter>
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<VehicleModelFilter>>
  /** Filter by the object’s `routingProfile` field. */
  routingProfile?: InputMaybe<RoutingProfileTypeFilter>
  /** Filter by the object’s `supplier` relation. */
  supplier?: InputMaybe<SupplierFilter>
  /** Filter by the object’s `supplierId` field. */
  supplierId?: InputMaybe<UuidFilter>
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>
  /** Filter by the object’s `vehiclesByModelId` relation. */
  vehiclesByModelId?: InputMaybe<VehicleModelToManyVehicleFilter>
  /** Some related `vehiclesByModelId` exist. */
  vehiclesByModelIdExist?: InputMaybe<Scalars['Boolean']>
}

/** An input for mutations affecting `VehicleModel` */
export type VehicleModelInput = {
  axa?: InputMaybe<Scalars['Boolean']>
  createdAt?: InputMaybe<Scalars['Datetime']>
  id?: InputMaybe<Scalars['UUID']>
  kind?: InputMaybe<VehicleKind>
  maxWeightGrams?: InputMaybe<Scalars['Int']>
  name: Scalars['String']
  routingProfile?: InputMaybe<RoutingProfileType>
  supplierId: Scalars['UUID']
  updatedAt?: InputMaybe<Scalars['Datetime']>
}

/** Represents an update to a `VehicleModel`. Fields that are set will be updated. */
export type VehicleModelPatch = {
  axa?: InputMaybe<Scalars['Boolean']>
  createdAt?: InputMaybe<Scalars['Datetime']>
  id?: InputMaybe<Scalars['UUID']>
  kind?: InputMaybe<VehicleKind>
  maxWeightGrams?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  routingProfile?: InputMaybe<RoutingProfileType>
  supplierId?: InputMaybe<Scalars['UUID']>
  updatedAt?: InputMaybe<Scalars['Datetime']>
}

/** A filter to be used against many `ModelEquipment` object types. All fields are combined with a logical ‘and.’ */
export type VehicleModelToManyModelEquipmentFilter = {
  /** Every related `ModelEquipment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ModelEquipmentFilter>
  /** No related `ModelEquipment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ModelEquipmentFilter>
  /** Some related `ModelEquipment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ModelEquipmentFilter>
}

/** A filter to be used against many `Vehicle` object types. All fields are combined with a logical ‘and.’ */
export type VehicleModelToManyVehicleFilter = {
  /** Every related `Vehicle` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<VehicleFilter>
  /** No related `Vehicle` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<VehicleFilter>
  /** Some related `Vehicle` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<VehicleFilter>
}

/** A connection to a list of `VehicleModel` values. */
export type VehicleModelsConnection = {
  __typename?: 'VehicleModelsConnection'
  /** A list of edges which contains the `VehicleModel` and cursor to aid in pagination. */
  edges: Array<VehicleModelsEdge>
  /** A list of `VehicleModel` objects. */
  nodes: Array<VehicleModel>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `VehicleModel` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `VehicleModel` edge in the connection. */
export type VehicleModelsEdge = {
  __typename?: 'VehicleModelsEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `VehicleModel` at the end of the edge. */
  node: VehicleModel
}

/** Methods to use when ordering `VehicleModel`. */
export enum VehicleModelsOrderBy {
  AxaAsc = 'AXA_ASC',
  AxaDesc = 'AXA_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  KindAsc = 'KIND_ASC',
  KindDesc = 'KIND_DESC',
  MaxWeightGramsAsc = 'MAX_WEIGHT_GRAMS_ASC',
  MaxWeightGramsDesc = 'MAX_WEIGHT_GRAMS_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RoutingProfileAsc = 'ROUTING_PROFILE_ASC',
  RoutingProfileDesc = 'ROUTING_PROFILE_DESC',
  SupplierIdAsc = 'SUPPLIER_ID_ASC',
  SupplierIdDesc = 'SUPPLIER_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

export enum VehicleOperationalStatus {
  AtSupplier = 'AT_SUPPLIER',
  InStorage = 'IN_STORAGE',
  InTransfer = 'IN_TRANSFER',
  MaintenanceRequired = 'MAINTENANCE_REQUIRED',
  Operational = 'OPERATIONAL',
  PartlyOperational = 'PARTLY_OPERATIONAL',
  Stolen = 'STOLEN',
}

/** A filter to be used against VehicleOperationalStatus fields. All fields are combined with a logical ‘and.’ */
export type VehicleOperationalStatusFilter = {
  /** Equal to the specified value. */
  equalTo?: InputMaybe<VehicleOperationalStatus>
  /** Included in the specified list. */
  in?: InputMaybe<Array<VehicleOperationalStatus>>
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>
  /** Less than the specified value. */
  lessThan?: InputMaybe<VehicleOperationalStatus>
}

/** Represents an update to a `Vehicle`. Fields that are set will be updated. */
export type VehiclePatch = {
  archivedAt?: InputMaybe<Scalars['Datetime']>
  bluetooth?: InputMaybe<Scalars['Boolean']>
  createdAt?: InputMaybe<Scalars['Datetime']>
  hubSlug?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['UUID']>
  imageUrl?: InputMaybe<Scalars['String']>
  kind?: InputMaybe<VehicleKind>
  licensePlate?: InputMaybe<Scalars['String']>
  location?: InputMaybe<Scalars['JSON']>
  mileageRemaining?: InputMaybe<Scalars['Int']>
  mileageTotal?: InputMaybe<Scalars['Int']>
  modelId?: InputMaybe<Scalars['UUID']>
  moduleId?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  operationalStatus?: InputMaybe<VehicleOperationalStatus>
  organizationId?: InputMaybe<Scalars['UUID']>
  pricingDurationConfigId?: InputMaybe<Scalars['UUID']>
  properties?: InputMaybe<Scalars['JSON']>
  provider?: InputMaybe<VehicleTelematicsProvider>
  speed?: InputMaybe<Scalars['Int']>
  statusUpdatedAt?: InputMaybe<Scalars['Datetime']>
  supplierId?: InputMaybe<Scalars['UUID']>
  supportedCommands?: InputMaybe<Array<InputMaybe<VehicleSupportedCommands>>>
  updatedAt?: InputMaybe<Scalars['Datetime']>
}

export enum VehicleSource {
  Fleet = 'FLEET',
}

/** A filter to be used against VehicleSource fields. All fields are combined with a logical ‘and.’ */
export type VehicleSourceFilter = {
  /** Equal to the specified value. */
  equalTo?: InputMaybe<VehicleSource>
  /** Included in the specified list. */
  in?: InputMaybe<Array<VehicleSource>>
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>
  /** Less than the specified value. */
  lessThan?: InputMaybe<VehicleSource>
}

export enum VehicleSupportedCommands {
  DoorLock = 'DOOR_LOCK',
  EngineLock = 'ENGINE_LOCK',
  MaintenanceCompartmentLock = 'MAINTENANCE_COMPARTMENT_LOCK',
}

/** A filter to be used against VehicleSupportedCommands List fields. All fields are combined with a logical ‘and.’ */
export type VehicleSupportedCommandsListFilter = {
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Array<InputMaybe<VehicleSupportedCommands>>>
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>
  /** Less than the specified value. */
  lessThan?: InputMaybe<Array<InputMaybe<VehicleSupportedCommands>>>
}

export enum VehicleTelematicsProvider {
  Axa = 'AXA',
  Fake = 'FAKE',
  Invers = 'INVERS',
  Maptex = 'MAPTEX',
  Movelo = 'MOVELO',
  Niu = 'NIU',
}

/** A filter to be used against VehicleTelematicsProvider fields. All fields are combined with a logical ‘and.’ */
export type VehicleTelematicsProviderFilter = {
  /** Equal to the specified value. */
  equalTo?: InputMaybe<VehicleTelematicsProvider>
  /** Included in the specified list. */
  in?: InputMaybe<Array<VehicleTelematicsProvider>>
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>
  /** Less than the specified value. */
  lessThan?: InputMaybe<VehicleTelematicsProvider>
}

/** A filter to be used against many `Damage` object types. All fields are combined with a logical ‘and.’ */
export type VehicleToManyDamageFilter = {
  /** Every related `Damage` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DamageFilter>
  /** No related `Damage` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DamageFilter>
  /** Some related `Damage` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DamageFilter>
}

/** A filter to be used against many `VehicleAssignment` object types. All fields are combined with a logical ‘and.’ */
export type VehicleToManyVehicleAssignmentFilter = {
  /** Every related `VehicleAssignment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<VehicleAssignmentFilter>
  /** No related `VehicleAssignment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<VehicleAssignmentFilter>
  /** Some related `VehicleAssignment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<VehicleAssignmentFilter>
}

/** A filter to be used against many `VehicleEquipment` object types. All fields are combined with a logical ‘and.’ */
export type VehicleToManyVehicleEquipmentFilter = {
  /** Every related `VehicleEquipment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<VehicleEquipmentFilter>
  /** No related `VehicleEquipment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<VehicleEquipmentFilter>
  /** Some related `VehicleEquipment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<VehicleEquipmentFilter>
}

/** A connection to a list of `Vehicle` values. */
export type VehiclesConnection = {
  __typename?: 'VehiclesConnection'
  /** A list of edges which contains the `Vehicle` and cursor to aid in pagination. */
  edges: Array<VehiclesEdge>
  /** A list of `Vehicle` objects. */
  nodes: Array<Vehicle>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** The count of *all* `Vehicle` you could get from the connection. */
  totalCount: Scalars['Int']
}

/** A `Vehicle` edge in the connection. */
export type VehiclesEdge = {
  __typename?: 'VehiclesEdge'
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>
  /** The `Vehicle` at the end of the edge. */
  node: Vehicle
}

/** Methods to use when ordering `Vehicle`. */
export enum VehiclesOrderBy {
  ArchivedAtAsc = 'ARCHIVED_AT_ASC',
  ArchivedAtDesc = 'ARCHIVED_AT_DESC',
  BluetoothAsc = 'BLUETOOTH_ASC',
  BluetoothDesc = 'BLUETOOTH_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  HubSlugAsc = 'HUB_SLUG_ASC',
  HubSlugDesc = 'HUB_SLUG_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ImageUrlAsc = 'IMAGE_URL_ASC',
  ImageUrlDesc = 'IMAGE_URL_DESC',
  KindAsc = 'KIND_ASC',
  KindDesc = 'KIND_DESC',
  LicensePlateAsc = 'LICENSE_PLATE_ASC',
  LicensePlateDesc = 'LICENSE_PLATE_DESC',
  LocationAsc = 'LOCATION_ASC',
  LocationDesc = 'LOCATION_DESC',
  MileageRemainingAsc = 'MILEAGE_REMAINING_ASC',
  MileageRemainingDesc = 'MILEAGE_REMAINING_DESC',
  MileageTotalAsc = 'MILEAGE_TOTAL_ASC',
  MileageTotalDesc = 'MILEAGE_TOTAL_DESC',
  ModelIdAsc = 'MODEL_ID_ASC',
  ModelIdDesc = 'MODEL_ID_DESC',
  ModelNameAsc = 'MODEL_NAME_ASC',
  ModelNameDesc = 'MODEL_NAME_DESC',
  ModuleIdAsc = 'MODULE_ID_ASC',
  ModuleIdDesc = 'MODULE_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  OperationalStatusAsc = 'OPERATIONAL_STATUS_ASC',
  OperationalStatusDesc = 'OPERATIONAL_STATUS_DESC',
  OrganizationIdAsc = 'ORGANIZATION_ID_ASC',
  OrganizationIdDesc = 'ORGANIZATION_ID_DESC',
  PricingDurationConfigIdAsc = 'PRICING_DURATION_CONFIG_ID_ASC',
  PricingDurationConfigIdDesc = 'PRICING_DURATION_CONFIG_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PropertiesAsc = 'PROPERTIES_ASC',
  PropertiesDesc = 'PROPERTIES_DESC',
  ProviderAsc = 'PROVIDER_ASC',
  ProviderDesc = 'PROVIDER_DESC',
  SpeedAsc = 'SPEED_ASC',
  SpeedDesc = 'SPEED_DESC',
  StatusUpdatedAtAsc = 'STATUS_UPDATED_AT_ASC',
  StatusUpdatedAtDesc = 'STATUS_UPDATED_AT_DESC',
  SupplierIdAsc = 'SUPPLIER_ID_ASC',
  SupplierIdDesc = 'SUPPLIER_ID_DESC',
  SupplierNameAsc = 'SUPPLIER_NAME_ASC',
  SupplierNameDesc = 'SUPPLIER_NAME_DESC',
  SupportedCommandsAsc = 'SUPPORTED_COMMANDS_ASC',
  SupportedCommandsDesc = 'SUPPORTED_COMMANDS_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

export type VehiclesQueryVariables = Exact<{
  limit: Scalars['Int']
  offset: Scalars['Int']
  operationalStatus?: InputMaybe<
    Array<VehicleOperationalStatus> | VehicleOperationalStatus
  >
  unArchived?: InputMaybe<Scalars['Boolean']>
  search?: InputMaybe<Scalars['String']>
  supplierId?: InputMaybe<Array<Scalars['UUID']> | Scalars['UUID']>
  bluetooth?: InputMaybe<Scalars['Boolean']>
  hub?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
}>

export type VehiclesQuery = {
  __typename?: 'Query'
  vehicles?: {
    __typename?: 'VehiclesConnection'
    totalCount: number
    nodes: Array<{
      __typename?: 'Vehicle'
      id: any
      sku?: string | null
      operationalStatus: VehicleOperationalStatus
      bluetooth: boolean
      archivedAt?: any | null
      hubSlug: string
      vehicleSupplier?: { __typename?: 'Supplier'; name: string } | null
      vehicleModel?: { __typename?: 'VehicleModel'; name: string } | null
      damages: { __typename?: 'DamagesConnection'; totalCount: number }
    }>
    pageInfo: {
      __typename?: 'PageInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
    }
  } | null
}

export type VehicleByIdQueryVariables = Exact<{
  id: Scalars['UUID']
  includeDamages: Scalars['Boolean']
}>

export type VehicleByIdQuery = {
  __typename?: 'Query'
  vehicle?: {
    __typename?: 'Vehicle'
    id: any
    sku?: string | null
    licensePlate: string
    availabilityStatus?: VehicleAvailabilityStatus | null
    operationalStatus: VehicleOperationalStatus
    supplierId: any
    modelId?: any | null
    provider?: VehicleTelematicsProvider | null
    bluetooth: boolean
    imageUrl?: string | null
    archivedAt?: any | null
    hubSlug: string
    moduleId?: string | null
    vehicleSupplier?: { __typename?: 'Supplier'; name: string } | null
    vehicleModel?: {
      __typename?: 'VehicleModel'
      name: string
      kind: VehicleKind
    } | null
    damages?: {
      __typename?: 'DamagesConnection'
      nodes: Array<{
        __typename?: 'Damage'
        id: any
        status: DamageStatus
        title: string
        code: DamageCode
        description: string
        createdAt: any
      }>
    }
  } | null
}

export type VehicleDamagesQueryVariables = Exact<{
  id: Scalars['UUID']
}>

export type VehicleDamagesQuery = {
  __typename?: 'Query'
  vehicle?: {
    __typename?: 'Vehicle'
    id: any
    damages: {
      __typename?: 'DamagesConnection'
      nodes: Array<{
        __typename?: 'Damage'
        id: any
        status: DamageStatus
        title: string
        code: DamageCode
        description: string
        createdAt: any
      }>
    }
  } | null
}

export type UpdateOperationalStatusMutationVariables = Exact<{
  id: Scalars['UUID']
  operationalStatus?: InputMaybe<VehicleOperationalStatus>
}>

export type UpdateOperationalStatusMutation = {
  __typename?: 'Mutation'
  updateVehicle?: {
    __typename?: 'UpdateVehiclePayload'
    vehicle?: {
      __typename?: 'Vehicle'
      id: any
      operationalStatus: VehicleOperationalStatus
    } | null
  } | null
}

export type CreatedVehicleFragment = {
  __typename?: 'CreateVehiclePayload'
  vehicle?: {
    __typename?: 'Vehicle'
    id: any
    sku?: string | null
    createdAt: any
  } | null
}

export type CreateVehicleMutationVariables = Exact<{
  input: CreateVehicleInput
}>

export type CreateVehicleMutation = {
  __typename?: 'Mutation'
  createVehicle?: {
    __typename?: 'CreateVehiclePayload'
    vehicle?: {
      __typename?: 'Vehicle'
      id: any
      sku?: string | null
      createdAt: any
    } | null
  } | null
}

export type UpdateVehicleMutationVariables = Exact<{
  id: Scalars['UUID']
  patch: VehiclePatch
}>

export type UpdateVehicleMutation = {
  __typename?: 'Mutation'
  updateVehicle?: {
    __typename?: 'UpdateVehiclePayload'
    vehicle?: {
      __typename?: 'Vehicle'
      id: any
      sku?: string | null
      kind: VehicleKind
      hubSlug: string
      imageUrl?: string | null
      moduleId?: string | null
      provider?: VehicleTelematicsProvider | null
      operationalStatus: VehicleOperationalStatus
      supplier?: string | null
    } | null
  } | null
}

export type ArchiveVehicleMutationVariables = Exact<{
  id: Scalars['UUID']
}>

export type ArchiveVehicleMutation = {
  __typename?: 'Mutation'
  archiveVehicleById?: {
    __typename?: 'ArchiveVehicleByIdPayload'
    vehicle?: {
      __typename?: 'Vehicle'
      id: any
      archivedAt?: any | null
    } | null
  } | null
}

export type CreateDamageMutationVariables = Exact<{
  vehicleId: Scalars['UUID']
  title: Scalars['String']
  description: Scalars['String']
  code: DamageCode
}>

export type CreateDamageMutation = {
  __typename?: 'Mutation'
  createDamage?: {
    __typename?: 'CreateDamagePayload'
    vehicle?: { __typename?: 'Vehicle'; id: any } | null
    damage?: {
      __typename?: 'Damage'
      id: any
      status: DamageStatus
      title: string
      code: DamageCode
      description: string
    } | null
  } | null
}

export type UpdateDamageMutationVariables = Exact<{
  id: Scalars['UUID']
  title?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  code?: InputMaybe<DamageCode>
  status?: InputMaybe<DamageStatus>
}>

export type UpdateDamageMutation = {
  __typename?: 'Mutation'
  updateDamage?: {
    __typename?: 'UpdateDamagePayload'
    vehicle?: {
      __typename?: 'Vehicle'
      id: any
      sku?: string | null
      operationalStatus: VehicleOperationalStatus
    } | null
    damage?: {
      __typename?: 'Damage'
      id: any
      status: DamageStatus
      title: string
      code: DamageCode
      description: string
    } | null
  } | null
}

export type VehicleOptionsQueryVariables = Exact<{ [key: string]: never }>

export type VehicleOptionsQuery = {
  __typename?: 'Query'
  allHubs: Array<{ __typename?: 'Hub'; slug?: string | null }>
  suppliers?: {
    __typename?: 'SuppliersConnection'
    nodes: Array<{
      __typename?: 'Supplier'
      id: any
      name: string
      vehicleModels: {
        __typename?: 'VehicleModelsConnection'
        nodes: Array<{
          __typename?: 'VehicleModel'
          id: any
          name: string
          axa?: boolean | null
          kind: VehicleKind
        }>
      }
    }>
  } | null
}

export type AssignedVehiclesQueryVariables = Exact<{
  hub?: InputMaybe<Scalars['String']>
}>

export type AssignedVehiclesQuery = {
  __typename?: 'Query'
  vehicles?: {
    __typename?: 'VehiclesConnection'
    nodes: Array<{
      __typename?: 'Vehicle'
      hubSlug: string
      id: any
      sku?: string | null
      vehicleSupplier?: { __typename?: 'Supplier'; name: string } | null
      rider?: {
        __typename?: 'Rider'
        firstName?: string | null
        lastName?: string | null
        status?: RiderStatus | null
      } | null
    }>
  } | null
}

export type LocksByReferenceQueryVariables = Exact<{
  references: Array<Scalars['String']> | Scalars['String']
}>

export type LocksByReferenceQuery = {
  __typename?: 'Query'
  locksByReference: Array<{
    __typename?: 'AXALock'
    id?: string | null
    reference: string
  }>
}

export type ConflictingVehiclesQueryVariables = Exact<{
  licensePlate: Scalars['String']
  supplierId: Scalars['UUID']
}>

export type ConflictingVehiclesQuery = {
  __typename?: 'Query'
  vehicles?: {
    __typename?: 'VehiclesConnection'
    nodes: Array<{
      __typename?: 'Vehicle'
      id: any
      licensePlate: string
      hubSlug: string
    }>
  } | null
}

export type DamagesQueryVariables = Exact<{
  limit: Scalars['Int']
  offset: Scalars['Int']
  supplierId?: InputMaybe<Array<Scalars['UUID']> | Scalars['UUID']>
  operationalStatus?: InputMaybe<
    Array<VehicleOperationalStatus> | VehicleOperationalStatus
  >
  hub?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  status?: InputMaybe<Array<DamageStatus> | DamageStatus>
}>

export type DamagesQuery = {
  __typename?: 'Query'
  damages?: {
    __typename?: 'DamagesConnection'
    totalCount: number
    nodes: Array<{
      __typename?: 'Damage'
      id: any
      status: DamageStatus
      title: string
      code: DamageCode
      createdAt: any
      updatedAt: any
      archivedBy?: string | null
      vehicle?: {
        __typename?: 'Vehicle'
        id: any
        name?: string | null
        sku?: string | null
        hubSlug: string
        operationalStatus: VehicleOperationalStatus
        vehicleSupplier?: {
          __typename?: 'Supplier'
          id: any
          name: string
        } | null
      } | null
    }>
    pageInfo: {
      __typename?: 'PageInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
    }
  } | null
}

export type VehiclesByIdsAndDamageStatusQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['UUID']> | Scalars['UUID']>
  status?: InputMaybe<Array<DamageStatus> | DamageStatus>
}>

export type VehiclesByIdsAndDamageStatusQuery = {
  __typename?: 'Query'
  vehicles?: {
    __typename?: 'VehiclesConnection'
    nodes: Array<{
      __typename?: 'Vehicle'
      id: any
      sku?: string | null
      damages: { __typename?: 'DamagesConnection'; totalCount: number }
    }>
  } | null
}

export type VehicleAssignmentsQueryVariables = Exact<{
  id: Scalars['UUID']
  limit: Scalars['Int']
  offset: Scalars['Int']
}>

export type VehicleAssignmentsQuery = {
  __typename?: 'Query'
  vehicleAssignments?: {
    __typename?: 'VehicleAssignmentsConnection'
    totalCount: number
    nodes: Array<{
      __typename?: 'VehicleAssignment'
      id: any
      assignedAt: any
      riderId: string
      vehicleId: any
      nodeId: string
      unassignedAt?: any | null
      rider?: {
        __typename?: 'Rider'
        firstName?: string | null
        lastName?: string | null
        employeeNumber?: string | null
      } | null
    }>
    pageInfo: {
      __typename?: 'PageInfo'
      hasPreviousPage: boolean
      hasNextPage: boolean
    }
  } | null
}

export type ResolverTypeWrapper<T> = Promise<T> | T

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>
}
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> {
  subscribe: SubscriptionSubscribeFn<
    { [key in TKey]: TResult },
    TParent,
    TContext,
    TArgs
  >
  resolve?: SubscriptionResolveFn<
    TResult,
    { [key in TKey]: TResult },
    TContext,
    TArgs
  >
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>
}

export type SubscriptionObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {}
> =
  | ((
      ...args: any[]
    ) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo
) => boolean | Promise<boolean>

export type NextResolverFn<T> = () => Promise<T>

export type DirectiveResolverFn<
  TResult = {},
  TParent = {},
  TContext = {},
  TArgs = {}
> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AXALock: ResolverTypeWrapper<AxaLock>
  ArchiveVehicleByIdInput: ArchiveVehicleByIdInput
  ArchiveVehicleByIdPayload: ResolverTypeWrapper<ArchiveVehicleByIdPayload>
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>
  BooleanFilter: BooleanFilter
  CreateDamageInput: CreateDamageInput
  CreateDamagePayload: ResolverTypeWrapper<CreateDamagePayload>
  CreateModelEquipmentInput: CreateModelEquipmentInput
  CreateModelEquipmentPayload: ResolverTypeWrapper<CreateModelEquipmentPayload>
  CreateSupplierInput: CreateSupplierInput
  CreateSupplierPayload: ResolverTypeWrapper<CreateSupplierPayload>
  CreateVehicleAssignmentInput: CreateVehicleAssignmentInput
  CreateVehicleAssignmentPayload: ResolverTypeWrapper<CreateVehicleAssignmentPayload>
  CreateVehicleEquipmentInput: CreateVehicleEquipmentInput
  CreateVehicleEquipmentPayload: ResolverTypeWrapper<CreateVehicleEquipmentPayload>
  CreateVehicleInput: CreateVehicleInput
  CreateVehicleModelInput: CreateVehicleModelInput
  CreateVehicleModelPayload: ResolverTypeWrapper<CreateVehicleModelPayload>
  CreateVehiclePayload: ResolverTypeWrapper<CreateVehiclePayload>
  Cursor: ResolverTypeWrapper<Scalars['Cursor']>
  Damage: ResolverTypeWrapper<Damage>
  DamageCode: DamageCode
  DamageCodeFilter: DamageCodeFilter
  DamageCondition: DamageCondition
  DamageFilter: DamageFilter
  DamageInput: DamageInput
  DamagePatch: DamagePatch
  DamageStatus: DamageStatus
  DamageStatusFilter: DamageStatusFilter
  DamagesConnection: ResolverTypeWrapper<DamagesConnection>
  DamagesEdge: ResolverTypeWrapper<DamagesEdge>
  DamagesOrderBy: DamagesOrderBy
  Datetime: ResolverTypeWrapper<Scalars['Datetime']>
  DatetimeFilter: DatetimeFilter
  Hub: ResolverTypeWrapper<Hub>
  ID: ResolverTypeWrapper<Scalars['ID']>
  Int: ResolverTypeWrapper<Scalars['Int']>
  IntFilter: IntFilter
  JSON: ResolverTypeWrapper<Scalars['JSON']>
  JSONFilter: JsonFilter
  LockKey: ResolverTypeWrapper<LockKey>
  LockType: LockType
  ModelEquipment: ResolverTypeWrapper<ModelEquipment>
  ModelEquipmentCondition: ModelEquipmentCondition
  ModelEquipmentConnection: ResolverTypeWrapper<ModelEquipmentConnection>
  ModelEquipmentEdge: ResolverTypeWrapper<ModelEquipmentEdge>
  ModelEquipmentFilter: ModelEquipmentFilter
  ModelEquipmentInput: ModelEquipmentInput
  ModelEquipmentOrderBy: ModelEquipmentOrderBy
  Mutation: ResolverTypeWrapper<{}>
  Node:
    | ResolversTypes['Damage']
    | ResolversTypes['Query']
    | ResolversTypes['Supplier']
    | ResolversTypes['Vehicle']
    | ResolversTypes['VehicleAssignment']
    | ResolversTypes['VehicleModel']
  PageInfo: ResolverTypeWrapper<PageInfo>
  Query: ResolverTypeWrapper<{}>
  ReassignVehicleInput: ReassignVehicleInput
  ReassignVehiclePayload: ResolverTypeWrapper<ReassignVehiclePayload>
  Rider: ResolverTypeWrapper<Rider>
  RiderStatus: RiderStatus
  RoutingProfileType: RoutingProfileType
  RoutingProfileTypeFilter: RoutingProfileTypeFilter
  String: ResolverTypeWrapper<Scalars['String']>
  StringFilter: StringFilter
  Supplier: ResolverTypeWrapper<Supplier>
  SupplierCondition: SupplierCondition
  SupplierFilter: SupplierFilter
  SupplierInput: SupplierInput
  SupplierPatch: SupplierPatch
  SupplierToManyVehicleFilter: SupplierToManyVehicleFilter
  SupplierToManyVehicleModelFilter: SupplierToManyVehicleModelFilter
  SuppliersConnection: ResolverTypeWrapper<SuppliersConnection>
  SuppliersEdge: ResolverTypeWrapper<SuppliersEdge>
  SuppliersOrderBy: SuppliersOrderBy
  UUID: ResolverTypeWrapper<Scalars['UUID']>
  UUIDFilter: UuidFilter
  UnassignVehicleInput: UnassignVehicleInput
  UnassignVehiclePayload: ResolverTypeWrapper<UnassignVehiclePayload>
  UnassignVehicleV2Input: UnassignVehicleV2Input
  UnassignVehicleV2Payload: ResolverTypeWrapper<UnassignVehicleV2Payload>
  UpdateDamageByNodeIdInput: UpdateDamageByNodeIdInput
  UpdateDamageInput: UpdateDamageInput
  UpdateDamagePayload: ResolverTypeWrapper<UpdateDamagePayload>
  UpdateSupplierByNameInput: UpdateSupplierByNameInput
  UpdateSupplierByNodeIdInput: UpdateSupplierByNodeIdInput
  UpdateSupplierByPrefixInput: UpdateSupplierByPrefixInput
  UpdateSupplierInput: UpdateSupplierInput
  UpdateSupplierPayload: ResolverTypeWrapper<UpdateSupplierPayload>
  UpdateVehicleAssignmentByNodeIdInput: UpdateVehicleAssignmentByNodeIdInput
  UpdateVehicleAssignmentInput: UpdateVehicleAssignmentInput
  UpdateVehicleAssignmentPayload: ResolverTypeWrapper<UpdateVehicleAssignmentPayload>
  UpdateVehicleByNodeIdInput: UpdateVehicleByNodeIdInput
  UpdateVehicleEquipmentByVehicleIdAndEquipmentInput: UpdateVehicleEquipmentByVehicleIdAndEquipmentInput
  UpdateVehicleEquipmentPayload: ResolverTypeWrapper<UpdateVehicleEquipmentPayload>
  UpdateVehicleEquipmentsInput: UpdateVehicleEquipmentsInput
  UpdateVehicleEquipmentsPayload: ResolverTypeWrapper<UpdateVehicleEquipmentsPayload>
  UpdateVehicleInput: UpdateVehicleInput
  UpdateVehicleModelByNodeIdInput: UpdateVehicleModelByNodeIdInput
  UpdateVehicleModelInput: UpdateVehicleModelInput
  UpdateVehicleModelPayload: ResolverTypeWrapper<UpdateVehicleModelPayload>
  UpdateVehiclePayload: ResolverTypeWrapper<UpdateVehiclePayload>
  Vehicle: ResolverTypeWrapper<Vehicle>
  VehicleAssignment: ResolverTypeWrapper<VehicleAssignment>
  VehicleAssignmentCondition: VehicleAssignmentCondition
  VehicleAssignmentFilter: VehicleAssignmentFilter
  VehicleAssignmentInput: VehicleAssignmentInput
  VehicleAssignmentPatch: VehicleAssignmentPatch
  VehicleAssignmentsConnection: ResolverTypeWrapper<VehicleAssignmentsConnection>
  VehicleAssignmentsEdge: ResolverTypeWrapper<VehicleAssignmentsEdge>
  VehicleAssignmentsOrderBy: VehicleAssignmentsOrderBy
  VehicleAvailabilityStatus: VehicleAvailabilityStatus
  VehicleAvailabilityStatusFilter: VehicleAvailabilityStatusFilter
  VehicleCondition: VehicleCondition
  VehicleEquipment: ResolverTypeWrapper<VehicleEquipment>
  VehicleEquipmentCondition: VehicleEquipmentCondition
  VehicleEquipmentConnection: ResolverTypeWrapper<VehicleEquipmentConnection>
  VehicleEquipmentEdge: ResolverTypeWrapper<VehicleEquipmentEdge>
  VehicleEquipmentFilter: VehicleEquipmentFilter
  VehicleEquipmentInput: VehicleEquipmentInput
  VehicleEquipmentOrderBy: VehicleEquipmentOrderBy
  VehicleEquipmentPatch: VehicleEquipmentPatch
  VehicleEquipmentType: VehicleEquipmentType
  VehicleEquipmentTypeFilter: VehicleEquipmentTypeFilter
  VehicleFilter: VehicleFilter
  VehicleInput: VehicleInput
  VehicleKind: VehicleKind
  VehicleKindFilter: VehicleKindFilter
  VehicleLock: ResolverTypeWrapper<VehicleLock>
  VehicleModel: ResolverTypeWrapper<VehicleModel>
  VehicleModelCondition: VehicleModelCondition
  VehicleModelFilter: VehicleModelFilter
  VehicleModelInput: VehicleModelInput
  VehicleModelPatch: VehicleModelPatch
  VehicleModelToManyModelEquipmentFilter: VehicleModelToManyModelEquipmentFilter
  VehicleModelToManyVehicleFilter: VehicleModelToManyVehicleFilter
  VehicleModelsConnection: ResolverTypeWrapper<VehicleModelsConnection>
  VehicleModelsEdge: ResolverTypeWrapper<VehicleModelsEdge>
  VehicleModelsOrderBy: VehicleModelsOrderBy
  VehicleOperationalStatus: VehicleOperationalStatus
  VehicleOperationalStatusFilter: VehicleOperationalStatusFilter
  VehiclePatch: VehiclePatch
  VehicleSource: VehicleSource
  VehicleSourceFilter: VehicleSourceFilter
  VehicleSupportedCommands: VehicleSupportedCommands
  VehicleSupportedCommandsListFilter: VehicleSupportedCommandsListFilter
  VehicleTelematicsProvider: VehicleTelematicsProvider
  VehicleTelematicsProviderFilter: VehicleTelematicsProviderFilter
  VehicleToManyDamageFilter: VehicleToManyDamageFilter
  VehicleToManyVehicleAssignmentFilter: VehicleToManyVehicleAssignmentFilter
  VehicleToManyVehicleEquipmentFilter: VehicleToManyVehicleEquipmentFilter
  VehiclesConnection: ResolverTypeWrapper<VehiclesConnection>
  VehiclesEdge: ResolverTypeWrapper<VehiclesEdge>
  VehiclesOrderBy: VehiclesOrderBy
}

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AXALock: AxaLock
  ArchiveVehicleByIdInput: ArchiveVehicleByIdInput
  ArchiveVehicleByIdPayload: ArchiveVehicleByIdPayload
  Boolean: Scalars['Boolean']
  BooleanFilter: BooleanFilter
  CreateDamageInput: CreateDamageInput
  CreateDamagePayload: CreateDamagePayload
  CreateModelEquipmentInput: CreateModelEquipmentInput
  CreateModelEquipmentPayload: CreateModelEquipmentPayload
  CreateSupplierInput: CreateSupplierInput
  CreateSupplierPayload: CreateSupplierPayload
  CreateVehicleAssignmentInput: CreateVehicleAssignmentInput
  CreateVehicleAssignmentPayload: CreateVehicleAssignmentPayload
  CreateVehicleEquipmentInput: CreateVehicleEquipmentInput
  CreateVehicleEquipmentPayload: CreateVehicleEquipmentPayload
  CreateVehicleInput: CreateVehicleInput
  CreateVehicleModelInput: CreateVehicleModelInput
  CreateVehicleModelPayload: CreateVehicleModelPayload
  CreateVehiclePayload: CreateVehiclePayload
  Cursor: Scalars['Cursor']
  Damage: Damage
  DamageCodeFilter: DamageCodeFilter
  DamageCondition: DamageCondition
  DamageFilter: DamageFilter
  DamageInput: DamageInput
  DamagePatch: DamagePatch
  DamageStatusFilter: DamageStatusFilter
  DamagesConnection: DamagesConnection
  DamagesEdge: DamagesEdge
  Datetime: Scalars['Datetime']
  DatetimeFilter: DatetimeFilter
  Hub: Hub
  ID: Scalars['ID']
  Int: Scalars['Int']
  IntFilter: IntFilter
  JSON: Scalars['JSON']
  JSONFilter: JsonFilter
  LockKey: LockKey
  ModelEquipment: ModelEquipment
  ModelEquipmentCondition: ModelEquipmentCondition
  ModelEquipmentConnection: ModelEquipmentConnection
  ModelEquipmentEdge: ModelEquipmentEdge
  ModelEquipmentFilter: ModelEquipmentFilter
  ModelEquipmentInput: ModelEquipmentInput
  Mutation: {}
  Node:
    | ResolversParentTypes['Damage']
    | ResolversParentTypes['Query']
    | ResolversParentTypes['Supplier']
    | ResolversParentTypes['Vehicle']
    | ResolversParentTypes['VehicleAssignment']
    | ResolversParentTypes['VehicleModel']
  PageInfo: PageInfo
  Query: {}
  ReassignVehicleInput: ReassignVehicleInput
  ReassignVehiclePayload: ReassignVehiclePayload
  Rider: Rider
  RoutingProfileTypeFilter: RoutingProfileTypeFilter
  String: Scalars['String']
  StringFilter: StringFilter
  Supplier: Supplier
  SupplierCondition: SupplierCondition
  SupplierFilter: SupplierFilter
  SupplierInput: SupplierInput
  SupplierPatch: SupplierPatch
  SupplierToManyVehicleFilter: SupplierToManyVehicleFilter
  SupplierToManyVehicleModelFilter: SupplierToManyVehicleModelFilter
  SuppliersConnection: SuppliersConnection
  SuppliersEdge: SuppliersEdge
  UUID: Scalars['UUID']
  UUIDFilter: UuidFilter
  UnassignVehicleInput: UnassignVehicleInput
  UnassignVehiclePayload: UnassignVehiclePayload
  UnassignVehicleV2Input: UnassignVehicleV2Input
  UnassignVehicleV2Payload: UnassignVehicleV2Payload
  UpdateDamageByNodeIdInput: UpdateDamageByNodeIdInput
  UpdateDamageInput: UpdateDamageInput
  UpdateDamagePayload: UpdateDamagePayload
  UpdateSupplierByNameInput: UpdateSupplierByNameInput
  UpdateSupplierByNodeIdInput: UpdateSupplierByNodeIdInput
  UpdateSupplierByPrefixInput: UpdateSupplierByPrefixInput
  UpdateSupplierInput: UpdateSupplierInput
  UpdateSupplierPayload: UpdateSupplierPayload
  UpdateVehicleAssignmentByNodeIdInput: UpdateVehicleAssignmentByNodeIdInput
  UpdateVehicleAssignmentInput: UpdateVehicleAssignmentInput
  UpdateVehicleAssignmentPayload: UpdateVehicleAssignmentPayload
  UpdateVehicleByNodeIdInput: UpdateVehicleByNodeIdInput
  UpdateVehicleEquipmentByVehicleIdAndEquipmentInput: UpdateVehicleEquipmentByVehicleIdAndEquipmentInput
  UpdateVehicleEquipmentPayload: UpdateVehicleEquipmentPayload
  UpdateVehicleEquipmentsInput: UpdateVehicleEquipmentsInput
  UpdateVehicleEquipmentsPayload: UpdateVehicleEquipmentsPayload
  UpdateVehicleInput: UpdateVehicleInput
  UpdateVehicleModelByNodeIdInput: UpdateVehicleModelByNodeIdInput
  UpdateVehicleModelInput: UpdateVehicleModelInput
  UpdateVehicleModelPayload: UpdateVehicleModelPayload
  UpdateVehiclePayload: UpdateVehiclePayload
  Vehicle: Vehicle
  VehicleAssignment: VehicleAssignment
  VehicleAssignmentCondition: VehicleAssignmentCondition
  VehicleAssignmentFilter: VehicleAssignmentFilter
  VehicleAssignmentInput: VehicleAssignmentInput
  VehicleAssignmentPatch: VehicleAssignmentPatch
  VehicleAssignmentsConnection: VehicleAssignmentsConnection
  VehicleAssignmentsEdge: VehicleAssignmentsEdge
  VehicleAvailabilityStatusFilter: VehicleAvailabilityStatusFilter
  VehicleCondition: VehicleCondition
  VehicleEquipment: VehicleEquipment
  VehicleEquipmentCondition: VehicleEquipmentCondition
  VehicleEquipmentConnection: VehicleEquipmentConnection
  VehicleEquipmentEdge: VehicleEquipmentEdge
  VehicleEquipmentFilter: VehicleEquipmentFilter
  VehicleEquipmentInput: VehicleEquipmentInput
  VehicleEquipmentPatch: VehicleEquipmentPatch
  VehicleEquipmentTypeFilter: VehicleEquipmentTypeFilter
  VehicleFilter: VehicleFilter
  VehicleInput: VehicleInput
  VehicleKindFilter: VehicleKindFilter
  VehicleLock: VehicleLock
  VehicleModel: VehicleModel
  VehicleModelCondition: VehicleModelCondition
  VehicleModelFilter: VehicleModelFilter
  VehicleModelInput: VehicleModelInput
  VehicleModelPatch: VehicleModelPatch
  VehicleModelToManyModelEquipmentFilter: VehicleModelToManyModelEquipmentFilter
  VehicleModelToManyVehicleFilter: VehicleModelToManyVehicleFilter
  VehicleModelsConnection: VehicleModelsConnection
  VehicleModelsEdge: VehicleModelsEdge
  VehicleOperationalStatusFilter: VehicleOperationalStatusFilter
  VehiclePatch: VehiclePatch
  VehicleSourceFilter: VehicleSourceFilter
  VehicleSupportedCommandsListFilter: VehicleSupportedCommandsListFilter
  VehicleTelematicsProviderFilter: VehicleTelematicsProviderFilter
  VehicleToManyDamageFilter: VehicleToManyDamageFilter
  VehicleToManyVehicleAssignmentFilter: VehicleToManyVehicleAssignmentFilter
  VehicleToManyVehicleEquipmentFilter: VehicleToManyVehicleEquipmentFilter
  VehiclesConnection: VehiclesConnection
  VehiclesEdge: VehiclesEdge
}

export type AxaLockResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AXALock'] = ResolversParentTypes['AXALock']
> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ArchiveVehicleByIdPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ArchiveVehicleByIdPayload'] = ResolversParentTypes['ArchiveVehicleByIdPayload']
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  model?: Resolver<
    Maybe<ResolversTypes['VehicleModel']>,
    ParentType,
    ContextType
  >
  query?: Resolver<Maybe<ResolversTypes['Query']>, ParentType, ContextType>
  supplier?: Resolver<
    Maybe<ResolversTypes['Supplier']>,
    ParentType,
    ContextType
  >
  vehicle?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType>
  vehicleEdge?: Resolver<
    Maybe<ResolversTypes['VehiclesEdge']>,
    ParentType,
    ContextType,
    RequireFields<ArchiveVehicleByIdPayloadVehicleEdgeArgs, 'orderBy'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateDamagePayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateDamagePayload'] = ResolversParentTypes['CreateDamagePayload']
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  damage?: Resolver<Maybe<ResolversTypes['Damage']>, ParentType, ContextType>
  damageEdge?: Resolver<
    Maybe<ResolversTypes['DamagesEdge']>,
    ParentType,
    ContextType,
    RequireFields<CreateDamagePayloadDamageEdgeArgs, 'orderBy'>
  >
  query?: Resolver<Maybe<ResolversTypes['Query']>, ParentType, ContextType>
  vehicle?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateModelEquipmentPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateModelEquipmentPayload'] = ResolversParentTypes['CreateModelEquipmentPayload']
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  modelEquipment?: Resolver<
    Maybe<ResolversTypes['ModelEquipment']>,
    ParentType,
    ContextType
  >
  modelEquipmentEdge?: Resolver<
    Maybe<ResolversTypes['ModelEquipmentEdge']>,
    ParentType,
    ContextType,
    RequireFields<CreateModelEquipmentPayloadModelEquipmentEdgeArgs, 'orderBy'>
  >
  query?: Resolver<Maybe<ResolversTypes['Query']>, ParentType, ContextType>
  vehicleModel?: Resolver<
    Maybe<ResolversTypes['VehicleModel']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateSupplierPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateSupplierPayload'] = ResolversParentTypes['CreateSupplierPayload']
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  query?: Resolver<Maybe<ResolversTypes['Query']>, ParentType, ContextType>
  supplier?: Resolver<
    Maybe<ResolversTypes['Supplier']>,
    ParentType,
    ContextType
  >
  supplierEdge?: Resolver<
    Maybe<ResolversTypes['SuppliersEdge']>,
    ParentType,
    ContextType,
    RequireFields<CreateSupplierPayloadSupplierEdgeArgs, 'orderBy'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateVehicleAssignmentPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateVehicleAssignmentPayload'] = ResolversParentTypes['CreateVehicleAssignmentPayload']
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  query?: Resolver<Maybe<ResolversTypes['Query']>, ParentType, ContextType>
  vehicle?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType>
  vehicleAssignment?: Resolver<
    Maybe<ResolversTypes['VehicleAssignment']>,
    ParentType,
    ContextType
  >
  vehicleAssignmentEdge?: Resolver<
    Maybe<ResolversTypes['VehicleAssignmentsEdge']>,
    ParentType,
    ContextType,
    RequireFields<
      CreateVehicleAssignmentPayloadVehicleAssignmentEdgeArgs,
      'orderBy'
    >
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateVehicleEquipmentPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateVehicleEquipmentPayload'] = ResolversParentTypes['CreateVehicleEquipmentPayload']
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  query?: Resolver<Maybe<ResolversTypes['Query']>, ParentType, ContextType>
  vehicle?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType>
  vehicleEquipment?: Resolver<
    Maybe<ResolversTypes['VehicleEquipment']>,
    ParentType,
    ContextType
  >
  vehicleEquipmentEdge?: Resolver<
    Maybe<ResolversTypes['VehicleEquipmentEdge']>,
    ParentType,
    ContextType,
    RequireFields<
      CreateVehicleEquipmentPayloadVehicleEquipmentEdgeArgs,
      'orderBy'
    >
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateVehicleModelPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateVehicleModelPayload'] = ResolversParentTypes['CreateVehicleModelPayload']
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  query?: Resolver<Maybe<ResolversTypes['Query']>, ParentType, ContextType>
  supplier?: Resolver<
    Maybe<ResolversTypes['Supplier']>,
    ParentType,
    ContextType
  >
  vehicleModel?: Resolver<
    Maybe<ResolversTypes['VehicleModel']>,
    ParentType,
    ContextType
  >
  vehicleModelEdge?: Resolver<
    Maybe<ResolversTypes['VehicleModelsEdge']>,
    ParentType,
    ContextType,
    RequireFields<CreateVehicleModelPayloadVehicleModelEdgeArgs, 'orderBy'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateVehiclePayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateVehiclePayload'] = ResolversParentTypes['CreateVehiclePayload']
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  model?: Resolver<
    Maybe<ResolversTypes['VehicleModel']>,
    ParentType,
    ContextType
  >
  query?: Resolver<Maybe<ResolversTypes['Query']>, ParentType, ContextType>
  supplier?: Resolver<
    Maybe<ResolversTypes['Supplier']>,
    ParentType,
    ContextType
  >
  vehicle?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType>
  vehicleEdge?: Resolver<
    Maybe<ResolversTypes['VehiclesEdge']>,
    ParentType,
    ContextType,
    RequireFields<CreateVehiclePayloadVehicleEdgeArgs, 'orderBy'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface CursorScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Cursor'], any> {
  name: 'Cursor'
}

export type DamageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Damage'] = ResolversParentTypes['Damage']
> = {
  archivedBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  code?: Resolver<ResolversTypes['DamageCode'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Datetime'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  fixApprovedBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  fixRejectedBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>
  nodeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  reportedBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  status?: Resolver<ResolversTypes['DamageStatus'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Datetime'], ParentType, ContextType>
  vehicle?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType>
  vehicleId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DamagesConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DamagesConnection'] = ResolversParentTypes['DamagesConnection']
> = {
  edges?: Resolver<
    Array<ResolversTypes['DamagesEdge']>,
    ParentType,
    ContextType
  >
  nodes?: Resolver<Array<ResolversTypes['Damage']>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DamagesEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DamagesEdge'] = ResolversParentTypes['DamagesEdge']
> = {
  cursor?: Resolver<Maybe<ResolversTypes['Cursor']>, ParentType, ContextType>
  node?: Resolver<ResolversTypes['Damage'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface DatetimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Datetime'], any> {
  name: 'Datetime'
}

export type HubResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Hub'] = ResolversParentTypes['Hub']
> = {
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface JsonScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON'
}

export type LockKeyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LockKey'] = ResolversParentTypes['LockKey']
> = {
  apiId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  eKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  otp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  securityKey?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ModelEquipmentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ModelEquipment'] = ResolversParentTypes['ModelEquipment']
> = {
  capacity?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  equipment?: Resolver<
    ResolversTypes['VehicleEquipmentType'],
    ParentType,
    ContextType
  >
  vehicleModel?: Resolver<
    Maybe<ResolversTypes['VehicleModel']>,
    ParentType,
    ContextType
  >
  vehicleModelId?: Resolver<
    Maybe<ResolversTypes['UUID']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ModelEquipmentConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ModelEquipmentConnection'] = ResolversParentTypes['ModelEquipmentConnection']
> = {
  edges?: Resolver<
    Array<ResolversTypes['ModelEquipmentEdge']>,
    ParentType,
    ContextType
  >
  nodes?: Resolver<
    Array<ResolversTypes['ModelEquipment']>,
    ParentType,
    ContextType
  >
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ModelEquipmentEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ModelEquipmentEdge'] = ResolversParentTypes['ModelEquipmentEdge']
> = {
  cursor?: Resolver<Maybe<ResolversTypes['Cursor']>, ParentType, ContextType>
  node?: Resolver<ResolversTypes['ModelEquipment'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']
> = {
  archiveVehicleById?: Resolver<
    Maybe<ResolversTypes['ArchiveVehicleByIdPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationArchiveVehicleByIdArgs, 'input'>
  >
  createDamage?: Resolver<
    Maybe<ResolversTypes['CreateDamagePayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateDamageArgs, 'input'>
  >
  createModelEquipment?: Resolver<
    Maybe<ResolversTypes['CreateModelEquipmentPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateModelEquipmentArgs, 'input'>
  >
  createSupplier?: Resolver<
    Maybe<ResolversTypes['CreateSupplierPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateSupplierArgs, 'input'>
  >
  createVehicle?: Resolver<
    Maybe<ResolversTypes['CreateVehiclePayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateVehicleArgs, 'input'>
  >
  createVehicleAssignment?: Resolver<
    Maybe<ResolversTypes['CreateVehicleAssignmentPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateVehicleAssignmentArgs, 'input'>
  >
  createVehicleEquipment?: Resolver<
    Maybe<ResolversTypes['CreateVehicleEquipmentPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateVehicleEquipmentArgs, 'input'>
  >
  createVehicleModel?: Resolver<
    Maybe<ResolversTypes['CreateVehicleModelPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateVehicleModelArgs, 'input'>
  >
  reassignVehicle?: Resolver<
    Maybe<ResolversTypes['ReassignVehiclePayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationReassignVehicleArgs, 'input'>
  >
  unassignVehicle?: Resolver<
    Maybe<ResolversTypes['UnassignVehiclePayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnassignVehicleArgs, 'input'>
  >
  unassignVehicleV2?: Resolver<
    Maybe<ResolversTypes['UnassignVehicleV2Payload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnassignVehicleV2Args, 'input'>
  >
  updateDamage?: Resolver<
    Maybe<ResolversTypes['UpdateDamagePayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateDamageArgs, 'input'>
  >
  updateDamageByNodeId?: Resolver<
    Maybe<ResolversTypes['UpdateDamagePayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateDamageByNodeIdArgs, 'input'>
  >
  updateSupplier?: Resolver<
    Maybe<ResolversTypes['UpdateSupplierPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateSupplierArgs, 'input'>
  >
  updateSupplierByName?: Resolver<
    Maybe<ResolversTypes['UpdateSupplierPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateSupplierByNameArgs, 'input'>
  >
  updateSupplierByNodeId?: Resolver<
    Maybe<ResolversTypes['UpdateSupplierPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateSupplierByNodeIdArgs, 'input'>
  >
  updateSupplierByPrefix?: Resolver<
    Maybe<ResolversTypes['UpdateSupplierPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateSupplierByPrefixArgs, 'input'>
  >
  updateVehicle?: Resolver<
    Maybe<ResolversTypes['UpdateVehiclePayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateVehicleArgs, 'input'>
  >
  updateVehicleAssignment?: Resolver<
    Maybe<ResolversTypes['UpdateVehicleAssignmentPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateVehicleAssignmentArgs, 'input'>
  >
  updateVehicleAssignmentByNodeId?: Resolver<
    Maybe<ResolversTypes['UpdateVehicleAssignmentPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateVehicleAssignmentByNodeIdArgs, 'input'>
  >
  updateVehicleByNodeId?: Resolver<
    Maybe<ResolversTypes['UpdateVehiclePayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateVehicleByNodeIdArgs, 'input'>
  >
  updateVehicleEquipmentByVehicleIdAndEquipment?: Resolver<
    Maybe<ResolversTypes['UpdateVehicleEquipmentPayload']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateVehicleEquipmentByVehicleIdAndEquipmentArgs,
      'input'
    >
  >
  updateVehicleEquipments?: Resolver<
    Maybe<ResolversTypes['UpdateVehicleEquipmentsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateVehicleEquipmentsArgs, 'input'>
  >
  updateVehicleModel?: Resolver<
    Maybe<ResolversTypes['UpdateVehicleModelPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateVehicleModelArgs, 'input'>
  >
  updateVehicleModelByNodeId?: Resolver<
    Maybe<ResolversTypes['UpdateVehicleModelPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateVehicleModelByNodeIdArgs, 'input'>
  >
}

export type NodeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Node'] = ResolversParentTypes['Node']
> = {
  __resolveType: TypeResolveFn<
    | 'Damage'
    | 'Query'
    | 'Supplier'
    | 'Vehicle'
    | 'VehicleAssignment'
    | 'VehicleModel',
    ParentType,
    ContextType
  >
  nodeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
}

export type PageInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo']
> = {
  endCursor?: Resolver<Maybe<ResolversTypes['Cursor']>, ParentType, ContextType>
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  startCursor?: Resolver<
    Maybe<ResolversTypes['Cursor']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']
> = {
  allHubs?: Resolver<Array<ResolversTypes['Hub']>, ParentType, ContextType>
  damage?: Resolver<
    Maybe<ResolversTypes['Damage']>,
    ParentType,
    ContextType,
    RequireFields<QueryDamageArgs, 'id'>
  >
  damageByNodeId?: Resolver<
    Maybe<ResolversTypes['Damage']>,
    ParentType,
    ContextType,
    RequireFields<QueryDamageByNodeIdArgs, 'nodeId'>
  >
  damages?: Resolver<
    Maybe<ResolversTypes['DamagesConnection']>,
    ParentType,
    ContextType,
    RequireFields<QueryDamagesArgs, 'orderBy'>
  >
  locksByReference?: Resolver<
    Array<ResolversTypes['AXALock']>,
    ParentType,
    ContextType,
    RequireFields<QueryLocksByReferenceArgs, 'references'>
  >
  modelEquipments?: Resolver<
    Maybe<ResolversTypes['ModelEquipmentConnection']>,
    ParentType,
    ContextType,
    RequireFields<QueryModelEquipmentsArgs, 'orderBy'>
  >
  node?: Resolver<
    Maybe<ResolversTypes['Node']>,
    ParentType,
    ContextType,
    RequireFields<QueryNodeArgs, 'nodeId'>
  >
  nodeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  query?: Resolver<ResolversTypes['Query'], ParentType, ContextType>
  supplier?: Resolver<
    Maybe<ResolversTypes['Supplier']>,
    ParentType,
    ContextType,
    RequireFields<QuerySupplierArgs, 'id'>
  >
  supplierByName?: Resolver<
    Maybe<ResolversTypes['Supplier']>,
    ParentType,
    ContextType,
    RequireFields<QuerySupplierByNameArgs, 'name'>
  >
  supplierByNodeId?: Resolver<
    Maybe<ResolversTypes['Supplier']>,
    ParentType,
    ContextType,
    RequireFields<QuerySupplierByNodeIdArgs, 'nodeId'>
  >
  supplierByPrefix?: Resolver<
    Maybe<ResolversTypes['Supplier']>,
    ParentType,
    ContextType,
    RequireFields<QuerySupplierByPrefixArgs, 'prefix'>
  >
  suppliers?: Resolver<
    Maybe<ResolversTypes['SuppliersConnection']>,
    ParentType,
    ContextType,
    RequireFields<QuerySuppliersArgs, 'orderBy'>
  >
  vehicle?: Resolver<
    Maybe<ResolversTypes['Vehicle']>,
    ParentType,
    ContextType,
    RequireFields<QueryVehicleArgs, 'id'>
  >
  vehicleAssignment?: Resolver<
    Maybe<ResolversTypes['VehicleAssignment']>,
    ParentType,
    ContextType,
    RequireFields<QueryVehicleAssignmentArgs, 'id'>
  >
  vehicleAssignmentByNodeId?: Resolver<
    Maybe<ResolversTypes['VehicleAssignment']>,
    ParentType,
    ContextType,
    RequireFields<QueryVehicleAssignmentByNodeIdArgs, 'nodeId'>
  >
  vehicleAssignments?: Resolver<
    Maybe<ResolversTypes['VehicleAssignmentsConnection']>,
    ParentType,
    ContextType,
    RequireFields<QueryVehicleAssignmentsArgs, 'orderBy'>
  >
  vehicleByNodeId?: Resolver<
    Maybe<ResolversTypes['Vehicle']>,
    ParentType,
    ContextType,
    RequireFields<QueryVehicleByNodeIdArgs, 'nodeId'>
  >
  vehicleEquipmentByVehicleIdAndEquipment?: Resolver<
    Maybe<ResolversTypes['VehicleEquipment']>,
    ParentType,
    ContextType,
    RequireFields<
      QueryVehicleEquipmentByVehicleIdAndEquipmentArgs,
      'equipment' | 'vehicleId'
    >
  >
  vehicleEquipments?: Resolver<
    Maybe<ResolversTypes['VehicleEquipmentConnection']>,
    ParentType,
    ContextType,
    RequireFields<QueryVehicleEquipmentsArgs, 'orderBy'>
  >
  vehicleModel?: Resolver<
    Maybe<ResolversTypes['VehicleModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryVehicleModelArgs, 'id'>
  >
  vehicleModelByNodeId?: Resolver<
    Maybe<ResolversTypes['VehicleModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryVehicleModelByNodeIdArgs, 'nodeId'>
  >
  vehicleModels?: Resolver<
    Maybe<ResolversTypes['VehicleModelsConnection']>,
    ParentType,
    ContextType,
    RequireFields<QueryVehicleModelsArgs, 'orderBy'>
  >
  vehicles?: Resolver<
    Maybe<ResolversTypes['VehiclesConnection']>,
    ParentType,
    ContextType,
    RequireFields<QueryVehiclesArgs, 'orderBy'>
  >
}

export type ReassignVehiclePayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReassignVehiclePayload'] = ResolversParentTypes['ReassignVehiclePayload']
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  query?: Resolver<Maybe<ResolversTypes['Query']>, ParentType, ContextType>
  vehicle?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType>
  vehicleAssignment?: Resolver<
    Maybe<ResolversTypes['VehicleAssignment']>,
    ParentType,
    ContextType
  >
  vehicleAssignmentEdge?: Resolver<
    Maybe<ResolversTypes['VehicleAssignmentsEdge']>,
    ParentType,
    ContextType,
    RequireFields<ReassignVehiclePayloadVehicleAssignmentEdgeArgs, 'orderBy'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RiderResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Rider'] = ResolversParentTypes['Rider']
> = {
  assignedAt?: Resolver<ResolversTypes['Datetime'], ParentType, ContextType>
  employeeNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  riderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<
    Maybe<ResolversTypes['RiderStatus']>,
    ParentType,
    ContextType
  >
  unassignedAt?: Resolver<
    Maybe<ResolversTypes['Datetime']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SupplierResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Supplier'] = ResolversParentTypes['Supplier']
> = {
  createdAt?: Resolver<ResolversTypes['Datetime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  nodeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  prefix?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Datetime'], ParentType, ContextType>
  vehicleModels?: Resolver<
    ResolversTypes['VehicleModelsConnection'],
    ParentType,
    ContextType,
    RequireFields<SupplierVehicleModelsArgs, 'orderBy'>
  >
  vehicles?: Resolver<
    ResolversTypes['VehiclesConnection'],
    ParentType,
    ContextType,
    RequireFields<SupplierVehiclesArgs, 'orderBy'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SuppliersConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SuppliersConnection'] = ResolversParentTypes['SuppliersConnection']
> = {
  edges?: Resolver<
    Array<ResolversTypes['SuppliersEdge']>,
    ParentType,
    ContextType
  >
  nodes?: Resolver<Array<ResolversTypes['Supplier']>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SuppliersEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SuppliersEdge'] = ResolversParentTypes['SuppliersEdge']
> = {
  cursor?: Resolver<Maybe<ResolversTypes['Cursor']>, ParentType, ContextType>
  node?: Resolver<ResolversTypes['Supplier'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface UuidScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['UUID'], any> {
  name: 'UUID'
}

export type UnassignVehiclePayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnassignVehiclePayload'] = ResolversParentTypes['UnassignVehiclePayload']
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  query?: Resolver<Maybe<ResolversTypes['Query']>, ParentType, ContextType>
  vehicle?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType>
  vehicleAssignment?: Resolver<
    Maybe<ResolversTypes['VehicleAssignment']>,
    ParentType,
    ContextType
  >
  vehicleAssignmentEdge?: Resolver<
    Maybe<ResolversTypes['VehicleAssignmentsEdge']>,
    ParentType,
    ContextType,
    RequireFields<UnassignVehiclePayloadVehicleAssignmentEdgeArgs, 'orderBy'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UnassignVehicleV2PayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnassignVehicleV2Payload'] = ResolversParentTypes['UnassignVehicleV2Payload']
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  query?: Resolver<Maybe<ResolversTypes['Query']>, ParentType, ContextType>
  vehicle?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType>
  vehicleAssignment?: Resolver<
    Maybe<ResolversTypes['VehicleAssignment']>,
    ParentType,
    ContextType
  >
  vehicleAssignmentEdge?: Resolver<
    Maybe<ResolversTypes['VehicleAssignmentsEdge']>,
    ParentType,
    ContextType,
    RequireFields<UnassignVehicleV2PayloadVehicleAssignmentEdgeArgs, 'orderBy'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateDamagePayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateDamagePayload'] = ResolversParentTypes['UpdateDamagePayload']
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  damage?: Resolver<Maybe<ResolversTypes['Damage']>, ParentType, ContextType>
  damageEdge?: Resolver<
    Maybe<ResolversTypes['DamagesEdge']>,
    ParentType,
    ContextType,
    RequireFields<UpdateDamagePayloadDamageEdgeArgs, 'orderBy'>
  >
  query?: Resolver<Maybe<ResolversTypes['Query']>, ParentType, ContextType>
  vehicle?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateSupplierPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSupplierPayload'] = ResolversParentTypes['UpdateSupplierPayload']
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  query?: Resolver<Maybe<ResolversTypes['Query']>, ParentType, ContextType>
  supplier?: Resolver<
    Maybe<ResolversTypes['Supplier']>,
    ParentType,
    ContextType
  >
  supplierEdge?: Resolver<
    Maybe<ResolversTypes['SuppliersEdge']>,
    ParentType,
    ContextType,
    RequireFields<UpdateSupplierPayloadSupplierEdgeArgs, 'orderBy'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateVehicleAssignmentPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateVehicleAssignmentPayload'] = ResolversParentTypes['UpdateVehicleAssignmentPayload']
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  query?: Resolver<Maybe<ResolversTypes['Query']>, ParentType, ContextType>
  vehicle?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType>
  vehicleAssignment?: Resolver<
    Maybe<ResolversTypes['VehicleAssignment']>,
    ParentType,
    ContextType
  >
  vehicleAssignmentEdge?: Resolver<
    Maybe<ResolversTypes['VehicleAssignmentsEdge']>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateVehicleAssignmentPayloadVehicleAssignmentEdgeArgs,
      'orderBy'
    >
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateVehicleEquipmentPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateVehicleEquipmentPayload'] = ResolversParentTypes['UpdateVehicleEquipmentPayload']
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  query?: Resolver<Maybe<ResolversTypes['Query']>, ParentType, ContextType>
  vehicle?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType>
  vehicleEquipment?: Resolver<
    Maybe<ResolversTypes['VehicleEquipment']>,
    ParentType,
    ContextType
  >
  vehicleEquipmentEdge?: Resolver<
    Maybe<ResolversTypes['VehicleEquipmentEdge']>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateVehicleEquipmentPayloadVehicleEquipmentEdgeArgs,
      'orderBy'
    >
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateVehicleEquipmentsPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateVehicleEquipmentsPayload'] = ResolversParentTypes['UpdateVehicleEquipmentsPayload']
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  query?: Resolver<Maybe<ResolversTypes['Query']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateVehicleModelPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateVehicleModelPayload'] = ResolversParentTypes['UpdateVehicleModelPayload']
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  query?: Resolver<Maybe<ResolversTypes['Query']>, ParentType, ContextType>
  supplier?: Resolver<
    Maybe<ResolversTypes['Supplier']>,
    ParentType,
    ContextType
  >
  vehicleModel?: Resolver<
    Maybe<ResolversTypes['VehicleModel']>,
    ParentType,
    ContextType
  >
  vehicleModelEdge?: Resolver<
    Maybe<ResolversTypes['VehicleModelsEdge']>,
    ParentType,
    ContextType,
    RequireFields<UpdateVehicleModelPayloadVehicleModelEdgeArgs, 'orderBy'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateVehiclePayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateVehiclePayload'] = ResolversParentTypes['UpdateVehiclePayload']
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  model?: Resolver<
    Maybe<ResolversTypes['VehicleModel']>,
    ParentType,
    ContextType
  >
  query?: Resolver<Maybe<ResolversTypes['Query']>, ParentType, ContextType>
  supplier?: Resolver<
    Maybe<ResolversTypes['Supplier']>,
    ParentType,
    ContextType
  >
  vehicle?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType>
  vehicleEdge?: Resolver<
    Maybe<ResolversTypes['VehiclesEdge']>,
    ParentType,
    ContextType,
    RequireFields<UpdateVehiclePayloadVehicleEdgeArgs, 'orderBy'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VehicleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Vehicle'] = ResolversParentTypes['Vehicle']
> = {
  archivedAt?: Resolver<
    Maybe<ResolversTypes['Datetime']>,
    ParentType,
    ContextType
  >
  assigned?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  availabilityStatus?: Resolver<
    Maybe<ResolversTypes['VehicleAvailabilityStatus']>,
    ParentType,
    ContextType
  >
  bluetooth?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Datetime'], ParentType, ContextType>
  damages?: Resolver<
    ResolversTypes['DamagesConnection'],
    ParentType,
    ContextType,
    RequireFields<VehicleDamagesArgs, 'orderBy'>
  >
  hubSlug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  kind?: Resolver<ResolversTypes['VehicleKind'], ParentType, ContextType>
  licensePlate?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  location?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  lock?: Resolver<Maybe<ResolversTypes['VehicleLock']>, ParentType, ContextType>
  mileageRemaining?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  mileageTotal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  model?: Resolver<
    Maybe<ResolversTypes['VehicleModel']>,
    ParentType,
    ContextType
  >
  modelId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>
  modelName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  moduleId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  nodeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  operationalStatus?: Resolver<
    ResolversTypes['VehicleOperationalStatus'],
    ParentType,
    ContextType
  >
  organizationId?: Resolver<
    Maybe<ResolversTypes['UUID']>,
    ParentType,
    ContextType
  >
  pricingDurationConfigId?: Resolver<
    Maybe<ResolversTypes['UUID']>,
    ParentType,
    ContextType
  >
  properties?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  provider?: Resolver<
    Maybe<ResolversTypes['VehicleTelematicsProvider']>,
    ParentType,
    ContextType
  >
  rider?: Resolver<Maybe<ResolversTypes['Rider']>, ParentType, ContextType>
  sku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  source?: Resolver<
    Maybe<ResolversTypes['VehicleSource']>,
    ParentType,
    ContextType
  >
  speed?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  statusUpdatedAt?: Resolver<
    Maybe<ResolversTypes['Datetime']>,
    ParentType,
    ContextType
  >
  supplier?: Resolver<
    Maybe<ResolversTypes['Supplier']>,
    ParentType,
    ContextType
  >
  supplierId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>
  supplierName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  supportedCommands?: Resolver<
    Array<Maybe<ResolversTypes['VehicleSupportedCommands']>>,
    ParentType,
    ContextType
  >
  updatedAt?: Resolver<ResolversTypes['Datetime'], ParentType, ContextType>
  vehicleAssignmentsByVehicleId?: Resolver<
    ResolversTypes['VehicleAssignmentsConnection'],
    ParentType,
    ContextType,
    RequireFields<VehicleVehicleAssignmentsByVehicleIdArgs, 'orderBy'>
  >
  vehicleEquipments?: Resolver<
    ResolversTypes['VehicleEquipmentConnection'],
    ParentType,
    ContextType,
    RequireFields<VehicleVehicleEquipmentsArgs, 'orderBy'>
  >
  vehicleModel?: Resolver<
    Maybe<ResolversTypes['VehicleModel']>,
    ParentType,
    ContextType
  >
  vehicleSupplier?: Resolver<
    Maybe<ResolversTypes['Supplier']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VehicleAssignmentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VehicleAssignment'] = ResolversParentTypes['VehicleAssignment']
> = {
  assignedAt?: Resolver<ResolversTypes['Datetime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>
  nodeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  rider?: Resolver<Maybe<ResolversTypes['Rider']>, ParentType, ContextType>
  riderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  unassignedAt?: Resolver<
    Maybe<ResolversTypes['Datetime']>,
    ParentType,
    ContextType
  >
  vehicle?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType>
  vehicleId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VehicleAssignmentsConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VehicleAssignmentsConnection'] = ResolversParentTypes['VehicleAssignmentsConnection']
> = {
  edges?: Resolver<
    Array<ResolversTypes['VehicleAssignmentsEdge']>,
    ParentType,
    ContextType
  >
  nodes?: Resolver<
    Array<ResolversTypes['VehicleAssignment']>,
    ParentType,
    ContextType
  >
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VehicleAssignmentsEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VehicleAssignmentsEdge'] = ResolversParentTypes['VehicleAssignmentsEdge']
> = {
  cursor?: Resolver<Maybe<ResolversTypes['Cursor']>, ParentType, ContextType>
  node?: Resolver<ResolversTypes['VehicleAssignment'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VehicleEquipmentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VehicleEquipment'] = ResolversParentTypes['VehicleEquipment']
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  equipment?: Resolver<
    ResolversTypes['VehicleEquipmentType'],
    ParentType,
    ContextType
  >
  updatedAt?: Resolver<ResolversTypes['Datetime'], ParentType, ContextType>
  updatedBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  vehicle?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType>
  vehicleId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VehicleEquipmentConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VehicleEquipmentConnection'] = ResolversParentTypes['VehicleEquipmentConnection']
> = {
  edges?: Resolver<
    Array<ResolversTypes['VehicleEquipmentEdge']>,
    ParentType,
    ContextType
  >
  nodes?: Resolver<
    Array<ResolversTypes['VehicleEquipment']>,
    ParentType,
    ContextType
  >
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VehicleEquipmentEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VehicleEquipmentEdge'] = ResolversParentTypes['VehicleEquipmentEdge']
> = {
  cursor?: Resolver<Maybe<ResolversTypes['Cursor']>, ParentType, ContextType>
  node?: Resolver<ResolversTypes['VehicleEquipment'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VehicleLockResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VehicleLock'] = ResolversParentTypes['VehicleLock']
> = {
  key?: Resolver<Maybe<ResolversTypes['LockKey']>, ParentType, ContextType>
  type?: Resolver<ResolversTypes['LockType'], ParentType, ContextType>
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VehicleModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VehicleModel'] = ResolversParentTypes['VehicleModel']
> = {
  axa?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Datetime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>
  kind?: Resolver<ResolversTypes['VehicleKind'], ParentType, ContextType>
  maxWeightGrams?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  modelEquipments?: Resolver<
    ResolversTypes['ModelEquipmentConnection'],
    ParentType,
    ContextType,
    RequireFields<VehicleModelModelEquipmentsArgs, 'orderBy'>
  >
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  nodeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  routingProfile?: Resolver<
    ResolversTypes['RoutingProfileType'],
    ParentType,
    ContextType
  >
  supplier?: Resolver<
    Maybe<ResolversTypes['Supplier']>,
    ParentType,
    ContextType
  >
  supplierId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Datetime'], ParentType, ContextType>
  vehiclesByModelId?: Resolver<
    ResolversTypes['VehiclesConnection'],
    ParentType,
    ContextType,
    RequireFields<VehicleModelVehiclesByModelIdArgs, 'orderBy'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VehicleModelsConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VehicleModelsConnection'] = ResolversParentTypes['VehicleModelsConnection']
> = {
  edges?: Resolver<
    Array<ResolversTypes['VehicleModelsEdge']>,
    ParentType,
    ContextType
  >
  nodes?: Resolver<
    Array<ResolversTypes['VehicleModel']>,
    ParentType,
    ContextType
  >
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VehicleModelsEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VehicleModelsEdge'] = ResolversParentTypes['VehicleModelsEdge']
> = {
  cursor?: Resolver<Maybe<ResolversTypes['Cursor']>, ParentType, ContextType>
  node?: Resolver<ResolversTypes['VehicleModel'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VehiclesConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VehiclesConnection'] = ResolversParentTypes['VehiclesConnection']
> = {
  edges?: Resolver<
    Array<ResolversTypes['VehiclesEdge']>,
    ParentType,
    ContextType
  >
  nodes?: Resolver<Array<ResolversTypes['Vehicle']>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VehiclesEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VehiclesEdge'] = ResolversParentTypes['VehiclesEdge']
> = {
  cursor?: Resolver<Maybe<ResolversTypes['Cursor']>, ParentType, ContextType>
  node?: Resolver<ResolversTypes['Vehicle'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Resolvers<ContextType = any> = {
  AXALock?: AxaLockResolvers<ContextType>
  ArchiveVehicleByIdPayload?: ArchiveVehicleByIdPayloadResolvers<ContextType>
  CreateDamagePayload?: CreateDamagePayloadResolvers<ContextType>
  CreateModelEquipmentPayload?: CreateModelEquipmentPayloadResolvers<ContextType>
  CreateSupplierPayload?: CreateSupplierPayloadResolvers<ContextType>
  CreateVehicleAssignmentPayload?: CreateVehicleAssignmentPayloadResolvers<ContextType>
  CreateVehicleEquipmentPayload?: CreateVehicleEquipmentPayloadResolvers<ContextType>
  CreateVehicleModelPayload?: CreateVehicleModelPayloadResolvers<ContextType>
  CreateVehiclePayload?: CreateVehiclePayloadResolvers<ContextType>
  Cursor?: GraphQLScalarType
  Damage?: DamageResolvers<ContextType>
  DamagesConnection?: DamagesConnectionResolvers<ContextType>
  DamagesEdge?: DamagesEdgeResolvers<ContextType>
  Datetime?: GraphQLScalarType
  Hub?: HubResolvers<ContextType>
  JSON?: GraphQLScalarType
  LockKey?: LockKeyResolvers<ContextType>
  ModelEquipment?: ModelEquipmentResolvers<ContextType>
  ModelEquipmentConnection?: ModelEquipmentConnectionResolvers<ContextType>
  ModelEquipmentEdge?: ModelEquipmentEdgeResolvers<ContextType>
  Mutation?: MutationResolvers<ContextType>
  Node?: NodeResolvers<ContextType>
  PageInfo?: PageInfoResolvers<ContextType>
  Query?: QueryResolvers<ContextType>
  ReassignVehiclePayload?: ReassignVehiclePayloadResolvers<ContextType>
  Rider?: RiderResolvers<ContextType>
  Supplier?: SupplierResolvers<ContextType>
  SuppliersConnection?: SuppliersConnectionResolvers<ContextType>
  SuppliersEdge?: SuppliersEdgeResolvers<ContextType>
  UUID?: GraphQLScalarType
  UnassignVehiclePayload?: UnassignVehiclePayloadResolvers<ContextType>
  UnassignVehicleV2Payload?: UnassignVehicleV2PayloadResolvers<ContextType>
  UpdateDamagePayload?: UpdateDamagePayloadResolvers<ContextType>
  UpdateSupplierPayload?: UpdateSupplierPayloadResolvers<ContextType>
  UpdateVehicleAssignmentPayload?: UpdateVehicleAssignmentPayloadResolvers<ContextType>
  UpdateVehicleEquipmentPayload?: UpdateVehicleEquipmentPayloadResolvers<ContextType>
  UpdateVehicleEquipmentsPayload?: UpdateVehicleEquipmentsPayloadResolvers<ContextType>
  UpdateVehicleModelPayload?: UpdateVehicleModelPayloadResolvers<ContextType>
  UpdateVehiclePayload?: UpdateVehiclePayloadResolvers<ContextType>
  Vehicle?: VehicleResolvers<ContextType>
  VehicleAssignment?: VehicleAssignmentResolvers<ContextType>
  VehicleAssignmentsConnection?: VehicleAssignmentsConnectionResolvers<ContextType>
  VehicleAssignmentsEdge?: VehicleAssignmentsEdgeResolvers<ContextType>
  VehicleEquipment?: VehicleEquipmentResolvers<ContextType>
  VehicleEquipmentConnection?: VehicleEquipmentConnectionResolvers<ContextType>
  VehicleEquipmentEdge?: VehicleEquipmentEdgeResolvers<ContextType>
  VehicleLock?: VehicleLockResolvers<ContextType>
  VehicleModel?: VehicleModelResolvers<ContextType>
  VehicleModelsConnection?: VehicleModelsConnectionResolvers<ContextType>
  VehicleModelsEdge?: VehicleModelsEdgeResolvers<ContextType>
  VehiclesConnection?: VehiclesConnectionResolvers<ContextType>
  VehiclesEdge?: VehiclesEdgeResolvers<ContextType>
}

export const CreatedVehicleFragmentDoc = gql`
  fragment CreatedVehicle on CreateVehiclePayload {
    vehicle {
      id
      sku
      createdAt
    }
  }
`
export const VehiclesDocument = gql`
  query Vehicles(
    $limit: Int!
    $offset: Int!
    $operationalStatus: [VehicleOperationalStatus!]
    $unArchived: Boolean
    $search: String
    $supplierId: [UUID!]
    $bluetooth: Boolean
    $hub: [String!]
  ) {
    vehicles(
      first: $limit
      offset: $offset
      filter: {
        and: [
          { operationalStatus: { in: $operationalStatus } }
          { archivedAt: { isNull: $unArchived } }
          { sku: { includesInsensitive: $search } }
          { supplierId: { in: $supplierId } }
          { bluetooth: { equalTo: $bluetooth } }
          { hubSlug: { in: $hub } }
        ]
      }
    ) {
      nodes {
        id
        sku
        operationalStatus
        vehicleSupplier {
          name
        }
        vehicleModel {
          name
        }
        bluetooth
        archivedAt
        hubSlug
        damages(filter: { status: { in: [REPORTED] } }) {
          totalCount
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

/**
 * __useVehiclesQuery__
 *
 * To run a query within a React component, call `useVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehiclesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      operationalStatus: // value for 'operationalStatus'
 *      unArchived: // value for 'unArchived'
 *      search: // value for 'search'
 *      supplierId: // value for 'supplierId'
 *      bluetooth: // value for 'bluetooth'
 *      hub: // value for 'hub'
 *   },
 * });
 */
export function useVehiclesQuery(
  baseOptions: Apollo.QueryHookOptions<VehiclesQuery, VehiclesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<VehiclesQuery, VehiclesQueryVariables>(
    VehiclesDocument,
    options
  )
}
export function useVehiclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehiclesQuery,
    VehiclesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<VehiclesQuery, VehiclesQueryVariables>(
    VehiclesDocument,
    options
  )
}
export type VehiclesQueryHookResult = ReturnType<typeof useVehiclesQuery>
export type VehiclesLazyQueryHookResult = ReturnType<
  typeof useVehiclesLazyQuery
>
export type VehiclesQueryResult = Apollo.QueryResult<
  VehiclesQuery,
  VehiclesQueryVariables
>
export const VehicleByIdDocument = gql`
  query VehicleById($id: UUID!, $includeDamages: Boolean!) {
    vehicle(id: $id) {
      id
      sku
      licensePlate
      availabilityStatus
      operationalStatus
      supplierId
      vehicleSupplier {
        name
      }
      modelId
      vehicleModel {
        name
        kind
      }
      provider
      bluetooth
      imageUrl
      archivedAt
      hubSlug
      moduleId
      damages @include(if: $includeDamages) {
        nodes {
          id
          status
          title
          code
          description
          createdAt
        }
      }
    }
  }
`

/**
 * __useVehicleByIdQuery__
 *
 * To run a query within a React component, call `useVehicleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      includeDamages: // value for 'includeDamages'
 *   },
 * });
 */
export function useVehicleByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    VehicleByIdQuery,
    VehicleByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<VehicleByIdQuery, VehicleByIdQueryVariables>(
    VehicleByIdDocument,
    options
  )
}
export function useVehicleByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehicleByIdQuery,
    VehicleByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<VehicleByIdQuery, VehicleByIdQueryVariables>(
    VehicleByIdDocument,
    options
  )
}
export type VehicleByIdQueryHookResult = ReturnType<typeof useVehicleByIdQuery>
export type VehicleByIdLazyQueryHookResult = ReturnType<
  typeof useVehicleByIdLazyQuery
>
export type VehicleByIdQueryResult = Apollo.QueryResult<
  VehicleByIdQuery,
  VehicleByIdQueryVariables
>
export const VehicleDamagesDocument = gql`
  query VehicleDamages($id: UUID!) {
    vehicle(id: $id) {
      id
      damages {
        nodes {
          id
          status
          title
          code
          description
          createdAt
        }
      }
    }
  }
`

/**
 * __useVehicleDamagesQuery__
 *
 * To run a query within a React component, call `useVehicleDamagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleDamagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleDamagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVehicleDamagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    VehicleDamagesQuery,
    VehicleDamagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<VehicleDamagesQuery, VehicleDamagesQueryVariables>(
    VehicleDamagesDocument,
    options
  )
}
export function useVehicleDamagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehicleDamagesQuery,
    VehicleDamagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<VehicleDamagesQuery, VehicleDamagesQueryVariables>(
    VehicleDamagesDocument,
    options
  )
}
export type VehicleDamagesQueryHookResult = ReturnType<
  typeof useVehicleDamagesQuery
>
export type VehicleDamagesLazyQueryHookResult = ReturnType<
  typeof useVehicleDamagesLazyQuery
>
export type VehicleDamagesQueryResult = Apollo.QueryResult<
  VehicleDamagesQuery,
  VehicleDamagesQueryVariables
>
export const UpdateOperationalStatusDocument = gql`
  mutation UpdateOperationalStatus(
    $id: UUID!
    $operationalStatus: VehicleOperationalStatus
  ) {
    updateVehicle(
      input: { patch: { operationalStatus: $operationalStatus }, id: $id }
    ) {
      vehicle {
        id
        operationalStatus
      }
    }
  }
`
export type UpdateOperationalStatusMutationFn = Apollo.MutationFunction<
  UpdateOperationalStatusMutation,
  UpdateOperationalStatusMutationVariables
>

/**
 * __useUpdateOperationalStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOperationalStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOperationalStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOperationalStatusMutation, { data, loading, error }] = useUpdateOperationalStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      operationalStatus: // value for 'operationalStatus'
 *   },
 * });
 */
export function useUpdateOperationalStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOperationalStatusMutation,
    UpdateOperationalStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateOperationalStatusMutation,
    UpdateOperationalStatusMutationVariables
  >(UpdateOperationalStatusDocument, options)
}
export type UpdateOperationalStatusMutationHookResult = ReturnType<
  typeof useUpdateOperationalStatusMutation
>
export type UpdateOperationalStatusMutationResult =
  Apollo.MutationResult<UpdateOperationalStatusMutation>
export type UpdateOperationalStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateOperationalStatusMutation,
  UpdateOperationalStatusMutationVariables
>
export const CreateVehicleDocument = gql`
  mutation CreateVehicle($input: CreateVehicleInput!) {
    createVehicle(input: $input) {
      ...CreatedVehicle
    }
  }
  ${CreatedVehicleFragmentDoc}
`
export type CreateVehicleMutationFn = Apollo.MutationFunction<
  CreateVehicleMutation,
  CreateVehicleMutationVariables
>

/**
 * __useCreateVehicleMutation__
 *
 * To run a mutation, you first call `useCreateVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVehicleMutation, { data, loading, error }] = useCreateVehicleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVehicleMutation,
    CreateVehicleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateVehicleMutation,
    CreateVehicleMutationVariables
  >(CreateVehicleDocument, options)
}
export type CreateVehicleMutationHookResult = ReturnType<
  typeof useCreateVehicleMutation
>
export type CreateVehicleMutationResult =
  Apollo.MutationResult<CreateVehicleMutation>
export type CreateVehicleMutationOptions = Apollo.BaseMutationOptions<
  CreateVehicleMutation,
  CreateVehicleMutationVariables
>
export const UpdateVehicleDocument = gql`
  mutation UpdateVehicle($id: UUID!, $patch: VehiclePatch!) {
    updateVehicle(input: { patch: $patch, id: $id }) {
      vehicle {
        id
        sku
        kind
        supplier: supplierName
        hubSlug
        imageUrl
        moduleId
        provider
        operationalStatus
      }
    }
  }
`
export type UpdateVehicleMutationFn = Apollo.MutationFunction<
  UpdateVehicleMutation,
  UpdateVehicleMutationVariables
>

/**
 * __useUpdateVehicleMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleMutation, { data, loading, error }] = useUpdateVehicleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      patch: // value for 'patch'
 *   },
 * });
 */
export function useUpdateVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVehicleMutation,
    UpdateVehicleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateVehicleMutation,
    UpdateVehicleMutationVariables
  >(UpdateVehicleDocument, options)
}
export type UpdateVehicleMutationHookResult = ReturnType<
  typeof useUpdateVehicleMutation
>
export type UpdateVehicleMutationResult =
  Apollo.MutationResult<UpdateVehicleMutation>
export type UpdateVehicleMutationOptions = Apollo.BaseMutationOptions<
  UpdateVehicleMutation,
  UpdateVehicleMutationVariables
>
export const ArchiveVehicleDocument = gql`
  mutation ArchiveVehicle($id: UUID!) {
    archiveVehicleById(input: { archiveId: $id }) {
      vehicle {
        id
        archivedAt
      }
    }
  }
`
export type ArchiveVehicleMutationFn = Apollo.MutationFunction<
  ArchiveVehicleMutation,
  ArchiveVehicleMutationVariables
>

/**
 * __useArchiveVehicleMutation__
 *
 * To run a mutation, you first call `useArchiveVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveVehicleMutation, { data, loading, error }] = useArchiveVehicleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveVehicleMutation,
    ArchiveVehicleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ArchiveVehicleMutation,
    ArchiveVehicleMutationVariables
  >(ArchiveVehicleDocument, options)
}
export type ArchiveVehicleMutationHookResult = ReturnType<
  typeof useArchiveVehicleMutation
>
export type ArchiveVehicleMutationResult =
  Apollo.MutationResult<ArchiveVehicleMutation>
export type ArchiveVehicleMutationOptions = Apollo.BaseMutationOptions<
  ArchiveVehicleMutation,
  ArchiveVehicleMutationVariables
>
export const CreateDamageDocument = gql`
  mutation CreateDamage(
    $vehicleId: UUID!
    $title: String!
    $description: String!
    $code: DamageCode!
  ) {
    createDamage(
      input: {
        damage: {
          vehicleId: $vehicleId
          title: $title
          description: $description
          code: $code
          status: REPORTED
        }
      }
    ) {
      vehicle {
        id
      }
      damage {
        id
        status
        title
        code
        description
      }
    }
  }
`
export type CreateDamageMutationFn = Apollo.MutationFunction<
  CreateDamageMutation,
  CreateDamageMutationVariables
>

/**
 * __useCreateDamageMutation__
 *
 * To run a mutation, you first call `useCreateDamageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDamageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDamageMutation, { data, loading, error }] = useCreateDamageMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useCreateDamageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDamageMutation,
    CreateDamageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateDamageMutation,
    CreateDamageMutationVariables
  >(CreateDamageDocument, options)
}
export type CreateDamageMutationHookResult = ReturnType<
  typeof useCreateDamageMutation
>
export type CreateDamageMutationResult =
  Apollo.MutationResult<CreateDamageMutation>
export type CreateDamageMutationOptions = Apollo.BaseMutationOptions<
  CreateDamageMutation,
  CreateDamageMutationVariables
>
export const UpdateDamageDocument = gql`
  mutation UpdateDamage(
    $id: UUID!
    $title: String
    $description: String
    $code: DamageCode
    $status: DamageStatus
  ) {
    updateDamage(
      input: {
        patch: {
          code: $code
          description: $description
          status: $status
          title: $title
        }
        id: $id
      }
    ) {
      vehicle {
        id
        sku
        operationalStatus
      }
      damage {
        id
        status
        title
        code
        description
      }
    }
  }
`
export type UpdateDamageMutationFn = Apollo.MutationFunction<
  UpdateDamageMutation,
  UpdateDamageMutationVariables
>

/**
 * __useUpdateDamageMutation__
 *
 * To run a mutation, you first call `useUpdateDamageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDamageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDamageMutation, { data, loading, error }] = useUpdateDamageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      code: // value for 'code'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateDamageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDamageMutation,
    UpdateDamageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateDamageMutation,
    UpdateDamageMutationVariables
  >(UpdateDamageDocument, options)
}
export type UpdateDamageMutationHookResult = ReturnType<
  typeof useUpdateDamageMutation
>
export type UpdateDamageMutationResult =
  Apollo.MutationResult<UpdateDamageMutation>
export type UpdateDamageMutationOptions = Apollo.BaseMutationOptions<
  UpdateDamageMutation,
  UpdateDamageMutationVariables
>
export const VehicleOptionsDocument = gql`
  query VehicleOptions {
    allHubs {
      slug
    }
    suppliers {
      nodes {
        id
        name
        vehicleModels {
          nodes {
            id
            name
            axa
            kind
          }
        }
      }
    }
  }
`

/**
 * __useVehicleOptionsQuery__
 *
 * To run a query within a React component, call `useVehicleOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useVehicleOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    VehicleOptionsQuery,
    VehicleOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<VehicleOptionsQuery, VehicleOptionsQueryVariables>(
    VehicleOptionsDocument,
    options
  )
}
export function useVehicleOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehicleOptionsQuery,
    VehicleOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<VehicleOptionsQuery, VehicleOptionsQueryVariables>(
    VehicleOptionsDocument,
    options
  )
}
export type VehicleOptionsQueryHookResult = ReturnType<
  typeof useVehicleOptionsQuery
>
export type VehicleOptionsLazyQueryHookResult = ReturnType<
  typeof useVehicleOptionsLazyQuery
>
export type VehicleOptionsQueryResult = Apollo.QueryResult<
  VehicleOptionsQuery,
  VehicleOptionsQueryVariables
>
export const AssignedVehiclesDocument = gql`
  query AssignedVehicles($hub: String) {
    vehicles(
      filter: {
        and: [{ assigned: { equalTo: true } }, { hubSlug: { equalTo: $hub } }]
      }
    ) {
      nodes {
        hubSlug
        id
        sku
        vehicleSupplier {
          name
        }
        rider {
          firstName
          lastName
          status
        }
      }
    }
  }
`

/**
 * __useAssignedVehiclesQuery__
 *
 * To run a query within a React component, call `useAssignedVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignedVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignedVehiclesQuery({
 *   variables: {
 *      hub: // value for 'hub'
 *   },
 * });
 */
export function useAssignedVehiclesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AssignedVehiclesQuery,
    AssignedVehiclesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AssignedVehiclesQuery, AssignedVehiclesQueryVariables>(
    AssignedVehiclesDocument,
    options
  )
}
export function useAssignedVehiclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AssignedVehiclesQuery,
    AssignedVehiclesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AssignedVehiclesQuery,
    AssignedVehiclesQueryVariables
  >(AssignedVehiclesDocument, options)
}
export type AssignedVehiclesQueryHookResult = ReturnType<
  typeof useAssignedVehiclesQuery
>
export type AssignedVehiclesLazyQueryHookResult = ReturnType<
  typeof useAssignedVehiclesLazyQuery
>
export type AssignedVehiclesQueryResult = Apollo.QueryResult<
  AssignedVehiclesQuery,
  AssignedVehiclesQueryVariables
>
export const LocksByReferenceDocument = gql`
  query LocksByReference($references: [String!]!) {
    locksByReference(references: $references) {
      id
      reference
    }
  }
`

/**
 * __useLocksByReferenceQuery__
 *
 * To run a query within a React component, call `useLocksByReferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocksByReferenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocksByReferenceQuery({
 *   variables: {
 *      references: // value for 'references'
 *   },
 * });
 */
export function useLocksByReferenceQuery(
  baseOptions: Apollo.QueryHookOptions<
    LocksByReferenceQuery,
    LocksByReferenceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LocksByReferenceQuery, LocksByReferenceQueryVariables>(
    LocksByReferenceDocument,
    options
  )
}
export function useLocksByReferenceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LocksByReferenceQuery,
    LocksByReferenceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    LocksByReferenceQuery,
    LocksByReferenceQueryVariables
  >(LocksByReferenceDocument, options)
}
export type LocksByReferenceQueryHookResult = ReturnType<
  typeof useLocksByReferenceQuery
>
export type LocksByReferenceLazyQueryHookResult = ReturnType<
  typeof useLocksByReferenceLazyQuery
>
export type LocksByReferenceQueryResult = Apollo.QueryResult<
  LocksByReferenceQuery,
  LocksByReferenceQueryVariables
>
export const ConflictingVehiclesDocument = gql`
  query ConflictingVehicles($licensePlate: String!, $supplierId: UUID!) {
    vehicles(
      filter: {
        licensePlate: { equalToInsensitive: $licensePlate }
        supplierId: { equalTo: $supplierId }
        archivedAt: { isNull: true }
      }
    ) {
      nodes {
        id
        licensePlate
        hubSlug
      }
    }
  }
`

/**
 * __useConflictingVehiclesQuery__
 *
 * To run a query within a React component, call `useConflictingVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConflictingVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConflictingVehiclesQuery({
 *   variables: {
 *      licensePlate: // value for 'licensePlate'
 *      supplierId: // value for 'supplierId'
 *   },
 * });
 */
export function useConflictingVehiclesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ConflictingVehiclesQuery,
    ConflictingVehiclesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ConflictingVehiclesQuery,
    ConflictingVehiclesQueryVariables
  >(ConflictingVehiclesDocument, options)
}
export function useConflictingVehiclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConflictingVehiclesQuery,
    ConflictingVehiclesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ConflictingVehiclesQuery,
    ConflictingVehiclesQueryVariables
  >(ConflictingVehiclesDocument, options)
}
export type ConflictingVehiclesQueryHookResult = ReturnType<
  typeof useConflictingVehiclesQuery
>
export type ConflictingVehiclesLazyQueryHookResult = ReturnType<
  typeof useConflictingVehiclesLazyQuery
>
export type ConflictingVehiclesQueryResult = Apollo.QueryResult<
  ConflictingVehiclesQuery,
  ConflictingVehiclesQueryVariables
>
export const DamagesDocument = gql`
  query Damages(
    $limit: Int!
    $offset: Int!
    $supplierId: [UUID!]
    $operationalStatus: [VehicleOperationalStatus!]
    $hub: [String!]
    $status: [DamageStatus!]
  ) {
    damages(
      first: $limit
      offset: $offset
      orderBy: CREATED_AT_DESC
      filter: {
        status: { in: $status }
        vehicle: {
          and: [
            { supplierId: { in: $supplierId } }
            { operationalStatus: { in: $operationalStatus } }
            { hubSlug: { in: $hub } }
          ]
        }
      }
    ) {
      nodes {
        id
        status
        title
        code
        createdAt
        updatedAt
        archivedBy
        vehicle {
          id
          name
          sku
          hubSlug
          operationalStatus
          vehicleSupplier {
            id
            name
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

/**
 * __useDamagesQuery__
 *
 * To run a query within a React component, call `useDamagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDamagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDamagesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      supplierId: // value for 'supplierId'
 *      operationalStatus: // value for 'operationalStatus'
 *      hub: // value for 'hub'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useDamagesQuery(
  baseOptions: Apollo.QueryHookOptions<DamagesQuery, DamagesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DamagesQuery, DamagesQueryVariables>(
    DamagesDocument,
    options
  )
}
export function useDamagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DamagesQuery, DamagesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DamagesQuery, DamagesQueryVariables>(
    DamagesDocument,
    options
  )
}
export type DamagesQueryHookResult = ReturnType<typeof useDamagesQuery>
export type DamagesLazyQueryHookResult = ReturnType<typeof useDamagesLazyQuery>
export type DamagesQueryResult = Apollo.QueryResult<
  DamagesQuery,
  DamagesQueryVariables
>
export const VehiclesByIdsAndDamageStatusDocument = gql`
  query VehiclesByIdsAndDamageStatus($ids: [UUID!], $status: [DamageStatus!]) {
    vehicles(filter: { and: [{ id: { in: $ids } }] }) {
      nodes {
        id
        sku
        damages(filter: { status: { in: $status } }) {
          totalCount
        }
      }
    }
  }
`

/**
 * __useVehiclesByIdsAndDamageStatusQuery__
 *
 * To run a query within a React component, call `useVehiclesByIdsAndDamageStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehiclesByIdsAndDamageStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehiclesByIdsAndDamageStatusQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useVehiclesByIdsAndDamageStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    VehiclesByIdsAndDamageStatusQuery,
    VehiclesByIdsAndDamageStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    VehiclesByIdsAndDamageStatusQuery,
    VehiclesByIdsAndDamageStatusQueryVariables
  >(VehiclesByIdsAndDamageStatusDocument, options)
}
export function useVehiclesByIdsAndDamageStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehiclesByIdsAndDamageStatusQuery,
    VehiclesByIdsAndDamageStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    VehiclesByIdsAndDamageStatusQuery,
    VehiclesByIdsAndDamageStatusQueryVariables
  >(VehiclesByIdsAndDamageStatusDocument, options)
}
export type VehiclesByIdsAndDamageStatusQueryHookResult = ReturnType<
  typeof useVehiclesByIdsAndDamageStatusQuery
>
export type VehiclesByIdsAndDamageStatusLazyQueryHookResult = ReturnType<
  typeof useVehiclesByIdsAndDamageStatusLazyQuery
>
export type VehiclesByIdsAndDamageStatusQueryResult = Apollo.QueryResult<
  VehiclesByIdsAndDamageStatusQuery,
  VehiclesByIdsAndDamageStatusQueryVariables
>
export const VehicleAssignmentsDocument = gql`
  query VehicleAssignments($id: UUID!, $limit: Int!, $offset: Int!) {
    vehicleAssignments(
      first: $limit
      offset: $offset
      orderBy: UNASSIGNED_AT_DESC
      filter: { vehicleId: { equalTo: $id } }
    ) {
      nodes {
        id
        assignedAt
        riderId
        vehicleId
        nodeId
        unassignedAt
        rider {
          firstName
          lastName
          employeeNumber
        }
      }
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

/**
 * __useVehicleAssignmentsQuery__
 *
 * To run a query within a React component, call `useVehicleAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleAssignmentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useVehicleAssignmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    VehicleAssignmentsQuery,
    VehicleAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    VehicleAssignmentsQuery,
    VehicleAssignmentsQueryVariables
  >(VehicleAssignmentsDocument, options)
}
export function useVehicleAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehicleAssignmentsQuery,
    VehicleAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    VehicleAssignmentsQuery,
    VehicleAssignmentsQueryVariables
  >(VehicleAssignmentsDocument, options)
}
export type VehicleAssignmentsQueryHookResult = ReturnType<
  typeof useVehicleAssignmentsQuery
>
export type VehicleAssignmentsLazyQueryHookResult = ReturnType<
  typeof useVehicleAssignmentsLazyQuery
>
export type VehicleAssignmentsQueryResult = Apollo.QueryResult<
  VehicleAssignmentsQuery,
  VehicleAssignmentsQueryVariables
>
