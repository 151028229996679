interface Props<RowType> {
  headers: string[]
  rows: RowType[]
  cells: (row: RowType) => JSX.Element
  onClick?: (row: RowType) => void
  toggleAllCheckboxes?: (checked: boolean) => void
  customRowStyling?: string
}

const Table = <RowType,>({
  headers,
  rows,
  cells,
  onClick,
  toggleAllCheckboxes,
  customRowStyling = '',
}: Props<RowType>) => (
  <table
    cellSpacing="0"
    className="border-separate table-auto w-full mt-10 mb-14 border-spacing-0"
  >
    <thead>
      <tr>
        {toggleAllCheckboxes && (
          <>
            <th className="font-medium pb-3 pl-1 text-left md:w-11 text-xs flex-1 w-fit">
              <input
                data-testid="checkbox"
                className="border border-gray-300 rounded-sm focus:rounded-sm bg-white focus:outline-none mt-1 mr-2 cursor-pointer"
                type="checkbox"
                onChange={(e) => toggleAllCheckboxes(e.target.checked)}
              />
            </th>
          </>
        )}
        {headers.map((header) => (
          <th
            className="font-medium pb-3 pl-1 text-left md:w-11 text-xs flex-1 w-fit whitespace-nowrap"
            key={header}
          >
            {header !== '' && (
              <span className="bg-darkBlue rounded py-2 px-1.5">{header}</span>
            )}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {rows.map((row, idx) => (
        <tr
          key={idx}
          className={`w-full bg-charcoal text-lg ${
            onClick ? 'cursor-pointer' : 'cursor-default'
          } ${customRowStyling ?? 'border-b border-darkBlue-light'}`}
          onClick={onClick ? () => onClick(row) : () => null}
        >
          {cells(row)}
        </tr>
      ))}
    </tbody>
  </table>
)

export default Table
