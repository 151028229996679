import { Hub } from '~/graphql/generated/types'
import sdk, { HubCore } from '~/utils/hubportal-sdk'

export const formatHubs = (hubs: Hub[] | undefined): string[] => {
  if (!hubs) return []
  return hubs.map((hub) => (hub.slug ? hub.slug.toUpperCase() : '')).sort()
}

export const getSelectedHubSlug = () => {
  const hub = sdk.getHub()
  if (!hub) {
    return null
  }
  return (hub as HubCore).slug.toUpperCase()
}
