import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import DamageView from '~/components/DamageView'
import { SearchParams } from '~/components/DamageView/types'
import { Spinner } from '~/components/Spinner'
import { useDamagesQuery } from '~/graphql/generated/types'
import { Permission, usePermissions } from '~/hooks'
import { getSelectedHubSlug } from '~/utils/hubs'
import { useFilterOptions } from '~/utils/filter-options'
import { getSearchParams } from '~/utils/search-params'

const Damages = () => {
  const { permissions, isAllowed } = usePermissions()

  const portalHub = getSelectedHubSlug() as string

  const [hubOptions, supplierOptions] = useFilterOptions()
  const [searchParams, setSearchParams] = useSearchParams()

  const { limit, page, supplier, operationalStatus, hub, damageStatus } =
    getSearchParams(searchParams)

  const shouldSkipQuery = !portalHub || !isAllowed([Permission.READ_DAMAGES_ALL])

  const {
    data: damagesData,
    loading,
    previousData: prevDamagesData,
    refetch,
  } = useDamagesQuery({
    skip: shouldSkipQuery,
    variables: {
      limit,
      offset: page * limit,
      status: damageStatus.length ? damageStatus : null,
      supplierId: supplier.length ? supplier : null,
      operationalStatus: operationalStatus.length ? operationalStatus : null,
      hub: hub,
    },
    fetchPolicy: 'no-cache',
  })

  const damages = loading ? prevDamagesData?.damages : damagesData?.damages

  const setParams = (searchParams: SearchParams) =>
    setSearchParams(
      {
        limit: (searchParams.limit ?? limit).toString(),
        page: (searchParams.page ?? page).toString(),
        damageStatus: searchParams.damageStatus ?? damageStatus ?? '',
        supplier: searchParams.supplier ?? supplier ?? '',
        operationalStatus:
          searchParams.operationalStatus ?? operationalStatus ?? '',
        hub: searchParams.hub ?? hub ?? '',
      },
      { replace: true }
    )

  useEffect(() => {
    // filter vehicles by portal's hub if permissions are missing
    if (
      !isAllowed([Permission.READ_VEHICLES_ALL]) &&
      !hub.length &&
      portalHub
    ) {
      setParams({ hub: [portalHub] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions, portalHub, hub.length])

  return (
    <>
      {loading && (
        <div className="absolute inset-0 w-screen h-screen flex justify-center items-center z-50">
          <Spinner size={28} />
        </div>
      )}
      {damages && (
        <DamageView
          damages={damages}
          refetchDamages={refetch}
          setParams={setParams}
          pageLimit={limit}
          pageIndex={page}
          hubOptions={hubOptions}
          hub={hub}
          supplierOptions={supplierOptions}
          supplier={supplier}
          operationalStatus={operationalStatus}
          damageStatus={damageStatus}
        />
      )}
    </>
  )
}

export default Damages
