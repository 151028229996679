import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  VehicleAvailabilityStatus,
  VehicleOperationalStatus,
} from '~/graphql/generated/types'
import Badge from '~/components/Badge'

type Props = {
  label: string
  value?:
    | VehicleOperationalStatus
    | VehicleAvailabilityStatus
    | string
    | boolean
    | null
  isFullColumn?: boolean
}

const VehicleField = ({ label, value, isFullColumn }: Props) => {
  const { t } = useTranslation('translation')

  const hasBadge = ['operationalStatus', 'availabilityStatus'].some(
    (item) => item === label
  )

  const renderValue = () => {
    if (typeof value === 'string' && hasBadge) {
      return <Badge value={value} label={t(`vehicle.${label}.${value}`)} />
    } else if (typeof value === 'boolean') {
      return t(value.toString())
    } else {
      return value || '-'
    }
  }

  return (
    <div
      className={`border-b border-darkBlue-light p-2 ${
        isFullColumn ? 'col-span-full' : ''
      }`}
    >
      <div className="text-lg text-inherit">{t(`vehicle.model.${label}`)}</div>
      <div
        className="text-base pt-2 font-medium h-10 truncate"
        title={typeof value === 'string' ? value : ''}
      >
        {renderValue()}
      </div>
    </div>
  )
}
export default memo(VehicleField)
