import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import format from 'date-fns/format'

import Badge from '~/components/Badge'
import NewDamage from '~/components/NewDamage'
import { Damage, DamageStatus, Vehicle } from '~/graphql/generated/types'
import Table from '~/components/Table'

const DAMAGE_FIELDS = ['status', 'category', 'title', 'createdAt'] as const

type ListDamage = Pick<
  Damage,
  'id' | 'status' | 'title' | 'createdAt' | 'description' | 'code'
>

type Props = {
  vehicle: {
    id: Vehicle['id']
    operationalStatus: Vehicle['operationalStatus']
    damages?: { nodes: Pick<Damage, 'id' | 'status'>[] }
  }
  damages: ListDamage[]
  suggestStatusUpdateForEdit?: boolean
  canEdit?: boolean
}

const DamageList = ({ vehicle, damages, canEdit }: Props) => {
  const { t } = useTranslation()
  const [isEditDamageModalVisible, setEditDamageModalVisible] = useState(false)
  const [isStatusModalVisible, setStatusModalVisible] = useState(false)
  const [isArchiveModalVisible, setArchiveModalVisible] = useState(false)
  const [selectedDamage, setSelectedDamage] = useState<ListDamage | undefined>(
    undefined
  )

  const setDamageModalVisible = (value: boolean) => {
    if (!value) {
      setSelectedDamage(undefined)
    }
  }

  const onClickRow = (damage: ListDamage) => {
    if (canEdit) {
      setSelectedDamage(damage)
    }
  }

  useEffect(() => {
    setEditDamageModalVisible(!!selectedDamage)
  }, [selectedDamage])

  const headers = DAMAGE_FIELDS.map((field) =>
    t(`vehicle.damageReports.model.${field}`)
  )

  const cells = (damage: ListDamage) => (
    <>
      <td className="p-4 pl-1">
        <Badge
          value={damage.status}
          label={t(`vehicle.damageStatus.${damage.status}`)}
        />
      </td>
      <td className="p-4 pl-1 max-w-prose truncate">
        {t(`vehicle.damageCode.${damage.code}`)}
      </td>
      <td className="p-4 pl-1 max-w-prose truncate">{damage.title || '-'}</td>
      <td className="p-4 pl-1 truncate">
        {format(new Date(damage.createdAt), 'PP HH:mm')}
      </td>
    </>
  )

  return damages.length ? (
    <>
      <Table
        headers={headers}
        rows={damages}
        cells={cells}
        onClick={onClickRow}
      />
      {(isStatusModalVisible ||
        isEditDamageModalVisible ||
        isArchiveModalVisible) && (
        <NewDamage
          setDamageModalVisible={setDamageModalVisible}
          isDamageModalVisible={isEditDamageModalVisible}
          setStatusModalVisible={setStatusModalVisible}
          isStatusModalVisible={isStatusModalVisible}
          isArchiveModalVisible={isArchiveModalVisible}
          setArchiveModalVisible={setArchiveModalVisible}
          isEdit
          damage={selectedDamage}
          vehicle={vehicle}
          isDisabled={selectedDamage?.status === DamageStatus.Archived}
        />
      )}
    </>
  ) : null
}

export default DamageList
