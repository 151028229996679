import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/solid'
import { FC } from 'react'
import { ComponentType } from 'react'
import { useTranslation } from 'react-i18next'
import CustomSelect from '../CustomSelect'

type Props = {
  totalCount: number
  pageLimit: number
  pageIndex: number
  pageCount: number
  setPageSize: (size: number) => void
  gotoPage: (page: number) => void
  previousPage: () => void
  nextPage: () => void
  canPreviousPage: boolean
  canNextPage: boolean
}

const PAGE_LIMITS = [10, 20, 30, 50, 100, 250]

interface PaginationButtonProps {
  onClick: () => void
  disabled: boolean
  title: string
  Icon: ComponentType<{ className: string }>
}

const PaginationButton: FC<PaginationButtonProps> = ({
  onClick,
  disabled,
  title,
  Icon,
}) => (
  <button
    className="pagination-nav first:rounded-l last:rounded-r"
    onClick={onClick}
    disabled={disabled}
    title={title}
  >
    <Icon className="w-4 fill-current" />
  </button>
)

const Pagination = ({
  totalCount,
  pageLimit,
  pageIndex,
  pageCount,
  setPageSize,
  gotoPage,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
}: Props) => {
  const { t } = useTranslation('translation')

  const pageOptions = PAGE_LIMITS.map((pl) => ({
    value: pl.toString(),
    label: pl.toString(),
  }))

  return (
    <div className="text-sm py-2">
      <div className="inline-flex">
        <PaginationButton
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          title={t('Pagination.first')}
          Icon={ChevronDoubleLeftIcon}
        />
        <PaginationButton
          onClick={previousPage}
          disabled={!canPreviousPage}
          title={t('Pagination.previous')}
          Icon={ChevronLeftIcon}
        />
        <PaginationButton
          onClick={nextPage}
          disabled={!canNextPage}
          title={t('Pagination.next')}
          Icon={ChevronRightIcon}
        />
        <PaginationButton
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          title={t('Pagination.last')}
          Icon={ChevronDoubleRightIcon}
        />
      </div>

      <span className="my-0 mx-4 text-base">
        {t('Pagination.pageRange', {
          start: totalCount > 0 ? pageIndex * pageLimit + 1 : 0,
          end: Math.min(pageIndex * pageLimit + pageLimit, totalCount),
          total: totalCount,
        })}
      </span>

      <label className="inline-flex gap-x-1 items-center">
        <span className="font-medium text-base">{t('Pagination.show')}</span>
        <CustomSelect
          options={pageOptions}
          displayValue={pageLimit.toString()}
          onSelect={(pl) => setPageSize(parseInt(pl.value))}
          selectedOption={pageOptions.find(
            (pl) => parseInt(pl.value) === pageLimit
          )}
          variant="small"
          buttonVariant="dashboard-filter"
          placement="top"
        />
      </label>
    </div>
  )
}

export default Pagination
