import { useOutletContext, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import VehicleForm from '~/components/VehicleForm'
import { VehicleInputType } from '~/components/VehicleForm/VehicleForm'
import {
  VehicleOperationalStatus,
  useUpdateVehicleMutation,
  VehicleByIdDocument,
} from '~/graphql/generated/types'
import { VehicleById } from '../types'
import { Permission, usePermissions } from '~/hooks'

const EditVehicle = () => {
  const vehicle = useOutletContext<VehicleById>()
  const { t } = useTranslation('translation')
  const { isAllowed } = usePermissions()

  const navigate = useNavigate()
  const [updateVehicleMutation, { loading: isSubmitting, error }] =
    useUpdateVehicleMutation({
      onCompleted: () => {
        navigate(`/vehicles/${vehicle.id}`, { replace: true })
      },
      refetchQueries: [
        {
          query: VehicleByIdDocument,
          variables: {
            id: vehicle.id,
            includeDamages: isAllowed([Permission.READ_DAMAGES_ALL]),
          },
        },
      ],
    })

  const handleSubmit = (vehiclePatch: VehicleInputType) => {
    Object.entries(vehiclePatch).forEach(([key, val]) => {
      if (!val) delete vehiclePatch[key as keyof VehicleInputType]
    })

    updateVehicleMutation({
      variables: {
        id: vehicle.id,
        patch: vehiclePatch,
      },
    })
  }

  return (
    <VehicleForm
      initialValues={vehicle}
      error={error}
      isSubmitting={isSubmitting}
      onSubmit={handleSubmit}
      isDisabled={
        vehicle.operationalStatus !== VehicleOperationalStatus.InTransfer ||
        !isAllowed([Permission.WRITE_VEHICLES_ALL])
      }
      isDisabledHint={
        vehicle.operationalStatus !== VehicleOperationalStatus.InTransfer
          ? t('VehicleForm.isDisabledHintOffline')
          : ''
      }
    />
  )
}

export default EditVehicle
