import { FocusEvent, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { ApolloError } from '@apollo/client'

import CustomSelect from '~/components/CustomSelect'
import { VehicleKind, useVehicleOptionsQuery } from '~/graphql/generated/types'
import { vehicleKinds } from './helpers'
import { formatHubs } from '~/utils/hubs'
import { GraphileError, knownErrorCodes } from '~/utils/errors'
import { VehicleById } from '~/pages/vehicles/types'

export type VehicleInputType = {
  hubSlug: string
  imageUrl?: string | null | undefined
  kind: VehicleKind
  licensePlate: string
  supplierId: string
  modelId: string
}

type Props = {
  initialValues?: VehicleById
  isCreationForm?: boolean
  error?: ApolloError
  isSubmitting?: boolean
  isDisabled?: boolean
  isDisabledHint?: string
  onSubmit: SubmitHandler<VehicleInputType>
}

const useVehicleOptions = (kind: string, supplierId: string) => {
  const { data, loading } = useVehicleOptionsQuery()

  return useMemo(() => {
    const hubs = formatHubs(data?.allHubs)
    // display only suppliers that have vehicles for the selected type
    const suppliers =
      data?.suppliers?.nodes.filter(
        (s) =>
          s.vehicleModels?.nodes.length > 0 &&
          !!s.vehicleModels.nodes.find((m) => m.kind === kind)
      ) || []
    const vehicleModels =
      suppliers
        .find((s) => s.id === supplierId)
        ?.vehicleModels.nodes.filter((vm) => vm.kind === kind) || []

    return { hubs, suppliers, vehicleModels, loading }
  }, [data, loading, supplierId, kind])
}

const getErrorMessage = (error: ApolloError): string => {
  const knownError = (error.graphQLErrors as GraphileError[]).find((e) =>
    knownErrorCodes.includes(e.errcode)
  )

  if (knownError) {
    return `VehicleForm.error.${knownError.errcode}`
  }
  return 'VehicleForm.error.unkown'
}

const VehicleForm = ({
  initialValues,
  isCreationForm,
  error,
  isSubmitting,
  isDisabled,
  isDisabledHint,
  onSubmit,
}: Props) => {
  const { t } = useTranslation('translation')

  const { register, handleSubmit, setValue, watch, control } =
    useForm<VehicleInputType>({
      defaultValues: {
        kind: initialValues?.vehicleModel?.kind ?? VehicleKind.Bicycle,
        licensePlate: initialValues?.licensePlate ?? '',
        imageUrl: initialValues?.imageUrl ?? '',
        supplierId: initialValues?.supplierId ?? '',
        modelId: initialValues?.modelId ?? '',
        hubSlug: initialValues?.hubSlug ?? '',
      },
    })

  const [hubSlug, supplierId, modelId, kind] = watch([
    'hubSlug',
    'supplierId',
    'modelId',
    'kind',
  ])

  const { loading, hubs, suppliers, vehicleModels } = useVehicleOptions(
    kind,
    supplierId
  )

  const supplierOptions = useMemo(() => {
    if (!loading && suppliers?.length) {
      return suppliers.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    }
    return []
  }, [suppliers, loading])

  const modelOptions = useMemo(() => {
    if (!loading && vehicleModels?.length) {
      return vehicleModels.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    }
    return []
  }, [vehicleModels, loading])

  const hubOptions = useMemo(() => {
    if (!loading && hubs) {
      const allHubs = hubs.map((item) => ({
        value: item,
        label: item,
      }))

      // NOTE: not all hub slugs that are guaranteed to exist in the list
      if (
        initialValues?.hubSlug &&
        !allHubs.some((e) => e.label === initialValues.hubSlug)
      ) {
        allHubs.push({
          value: initialValues.hubSlug,
          label: initialValues.hubSlug,
        })
      }

      return allHubs
    }
    return []
  }, [hubs, loading, initialValues])

  useEffect(() => {
    let newModelId = modelId

    if (
      vehicleModels.length === 0 ||
      !vehicleModels.some((vm) => vm.id == modelId)
    ) {
      newModelId = ''
    }

    if (!newModelId && vehicleModels.length > 0) {
      newModelId = vehicleModels[0].id
    }

    if (newModelId !== modelId) {
      setValue('modelId', newModelId)
    }
  }, [setValue, modelId, vehicleModels])

  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    const type = e.target.getAttribute('type')
    const field = e.target.getAttribute('name') as 'licensePlate' | 'imageUrl'
    type === 'text' && setValue(field, e.target.value.trim())
  }

  const selectedKind = useMemo(
    () => vehicleKinds.find((item) => item.value === kind),
    [kind]
  )

  const selectedSupplier = useMemo(
    () => supplierOptions.find((item) => item.value === supplierId),
    [supplierId, supplierOptions]
  )
  const selectedHubOption = useMemo(
    () => hubOptions.find((item) => item.value === hubSlug),
    [hubOptions, hubSlug]
  )
  const selectedModel = useMemo(
    () => modelOptions.find((item) => item.value === modelId),
    [modelId, modelOptions]
  )
  if (loading) return null

  return (
    <form onSubmit={handleSubmit(onSubmit)} name="vehicle-form">
      <fieldset
        disabled={isSubmitting || isDisabled}
        className="w-full max-w-xl"
      >
        <h3 className="font-medium text-2xl mb-4">
          {t('VehicleForm.vehicleInformation')}
        </h3>
        {isDisabled && (
          <div className="text-primary font-bold mb-4">{isDisabledHint}</div>
        )}
        {!isDisabled && !isCreationForm && (
          <div className="text-primary font-bold mb-4">
            {t('VehicleForm.isDisabledHintArchive')}
          </div>
        )}

        <label className="form-field">
          <span className="form-label">
            {t('vehicle.model.kind')}
            {isCreationForm && ' *'}
          </span>
          <Controller
            control={control}
            name="kind"
            rules={{ required: true }}
            render={({ field: { onChange, name } }) => {
              return (
                <CustomSelect
                  name={name}
                  isRequired
                  placeholder={
                    selectedKind?.label ??
                    t('VehicleForm.emptySelectLabel', {
                      fieldType: t('vehicle.model.kind'),
                    })
                  }
                  options={vehicleKinds}
                  onSelect={(option) => onChange(option.value)}
                  selectedOption={selectedKind}
                  variant="full"
                  isDisabled={!isCreationForm}
                />
              )
            }}
          />
        </label>

        <label className="form-field">
          <span className="form-label">
            {t('vehicle.model.supplier')}
            {isCreationForm && ' *'}
          </span>
          <Controller
            control={control}
            name="supplierId"
            rules={{ required: true }}
            render={({ field: { onChange, name } }) => {
              return (
                <CustomSelect
                  name={name}
                  isRequired
                  placeholder={
                    selectedSupplier?.label ??
                    t('VehicleForm.emptySelectLabel', {
                      fieldType: t('vehicle.model.supplier'),
                    })
                  }
                  options={supplierOptions}
                  onSelect={(option) => onChange(option.value)}
                  selectedOption={selectedSupplier}
                  variant="full"
                  isDisabled={!isCreationForm}
                />
              )
            }}
          />
        </label>

        <label className="form-field">
          <span className="form-label">
            {t('vehicle.model.model')}
            {isCreationForm && ' *'}
          </span>
          <Controller
            control={control}
            name="modelId"
            rules={{ required: true }}
            render={({ field: { onChange, name } }) => {
              return (
                <CustomSelect
                  name={name}
                  isRequired
                  placeholder={
                    selectedModel?.label ??
                    t('VehicleForm.emptySelectLabel', {
                      fieldType: t('vehicle.model.model'),
                    })
                  }
                  options={modelOptions}
                  onSelect={(option) => onChange(option.value)}
                  selectedOption={selectedModel}
                  variant="full"
                  isDisabled={!isCreationForm}
                />
              )
            }}
          />
        </label>

        <label className="form-field">
          <span className="form-label">
            {t('vehicle.model.licensePlate')}
            {isCreationForm && ' *'}
          </span>
          <input
            {...register('licensePlate')}
            className="form-input"
            type="text"
            disabled={!isCreationForm}
            required
            onBlur={onBlur}
          />
        </label>

        <label className="form-field">
          <span className="form-label">{t('vehicle.model.imageUrl')}</span>
          <input
            {...register('imageUrl')}
            className="form-input"
            type="text"
            onBlur={onBlur}
          />
        </label>

        <label className="form-field">
          <span className="form-label">
            {t('vehicle.model.hubSlug')}
            {isCreationForm && ' *'}
          </span>
          <Controller
            control={control}
            name="hubSlug"
            rules={{ required: true }}
            render={({ field: { onChange, name } }) => {
              return (
                <CustomSelect
                  name={name}
                  isRequired
                  placeholder={
                    selectedHubOption?.label ??
                    t('VehicleForm.emptySelectLabel', {
                      fieldType: t('vehicle.model.hubSlug'),
                    })
                  }
                  hasSearch
                  options={hubOptions}
                  onSelect={(option) => onChange(option.value)}
                  selectedOption={selectedHubOption}
                  variant="full"
                  isDisabled={isDisabled}
                />
              )
            }}
          />
        </label>

        {error && (
          <div
            className="text-primary font-bold mb-4"
            data-testid="vehicle-error"
          >
            {t(getErrorMessage(error))}
          </div>
        )}

        <button className="primary-btn mb-10" type="submit">
          {isCreationForm
            ? t('VehicleForm.createButton')
            : t('VehicleForm.saveButton')}
        </button>
      </fieldset>
    </form>
  )
}

export default VehicleForm
