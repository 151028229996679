import {
  DamageStatus,
  VehicleOperationalStatus,
} from '~/graphql/generated/types'

const DEFAULT_LIMIT = 100
const DEFAULT_PAGE = 0

export const getSearchParams = (searchParams: URLSearchParams) => {
  const limit = Number(searchParams.get('limit') ?? DEFAULT_LIMIT)
  const page = Number(searchParams.get('page') ?? DEFAULT_PAGE)
  const search = searchParams.get('search')
  const bluetooth = searchParams.get('bluetooth')
  const hub = searchParams.getAll('hub')

  const allOperationalStatuses = searchParams.getAll(
    'operationalStatus'
  ) as VehicleOperationalStatus[]
  const operationalStatus = allOperationalStatuses?.length
    ? Array.from(
        new Set(
          allOperationalStatuses.filter((item) =>
            Object.values(VehicleOperationalStatus).includes(item)
          )
        )
      )
    : []

  const allDamageStatuses = searchParams.getAll(
    'damageStatus'
  ) as DamageStatus[]
  const damageStatus = allDamageStatuses?.length
    ? Array.from(
        new Set(
          allDamageStatuses.filter((item) =>
            Object.values(DamageStatus).includes(item)
          )
        )
      )
    : []

  const supplier = searchParams.getAll('supplier')

  return {
    limit,
    page,
    operationalStatus,
    damageStatus,
    search,
    supplier,
    bluetooth,
    hub,
  }
}
