import { useEffect, useRef } from 'react'
import { useKeyPress, useOutsideClick } from '~/hooks'

type Props = {
  isOpen?: boolean
  onConfirm?: () => void
  onCancel: () => void
  children: React.ReactNode
  submitText?: string
  cancelText?: string
  isDisabled?: boolean
}

const Modal = ({
  isOpen,
  onConfirm,
  onCancel,
  children,
  submitText,
  cancelText,
  isDisabled,
}: Props) => {
  const ref = useRef(null)
  const escape = useKeyPress('Escape')

  useEffect(() => {
    if (escape) {
      onCancel()
    }
  }, [escape, onCancel])

  useOutsideClick(ref, () => {
    if (isOpen) {
      onCancel()
    }
  })

  return isOpen ? (
    <div
      className="fixed z-10 inset-0 overflow-y-auto"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-start justify-center min-h-screen pt-4 pb-20">
        <div
          className="fixed inset-0 bg-black bg-opacity-30 transition-opacity"
          aria-hidden="true"
        ></div>

        <div
          ref={ref}
          className="flex flex-col mx-auto mt-40 w-full align-bottom bg-charcoal rounded-lg text-left overflow-hidden shadow-xl transform transition-all max-w-2xl py-12 text-white"
        >
          {children}
          {(cancelText || submitText) && (
            <div className="w-full flex flex-row justify-end px-6">
              <button
                type="button"
                className="font-semibold py-2 px-4 text-sm rounded text-gray-800 bg-gray-100  min-w-48 w-auto text-center mr-4"
                onClick={onCancel}
              >
                {cancelText}
              </button>
              <button
                type="submit"
                className="primary-btn min-w-48 w-auto text-center"
                disabled={isDisabled}
                onClick={onConfirm}
              >
                {submitText}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : null
}

export default Modal
