import { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Pagination from '~/components/Pagination'
import VehicleTable from '~/components/VehicleTable'
import MultiSelect from '~/components/MultiSelect'
import CustomSelect, { Option } from '~/components/CustomSelect'
import { VehiclesQuery } from '~/graphql/generated/types'
import { operationalStatusOptions, bluetoothOptions } from './helpers'
import { Filters, SearchParams } from './types'
import { usePermissions, Permission } from '~/hooks'

interface Props extends Filters {
  vehicles: NonNullable<VehiclesQuery['vehicles']>
  hubOptions: Option[]
  supplierOptions: Option[]
  setParams: (searchParams: SearchParams) => void
  refetchVehicles: () => void
}

const VehicleView = ({
  vehicles,
  refetchVehicles,
  setParams,
  pageLimit,
  pageIndex,
  operationalStatus,
  supplier,
  supplierOptions,
  hubOptions,
  bluetooth,
  hub,
}: Props) => {
  const { t } = useTranslation('translation')
  const { isAllowed } = usePermissions()

  const pageCount = Math.ceil(vehicles.totalCount / pageLimit)

  const selectedSuppliers = useMemo(() => {
    if (supplier) {
      return supplierOptions.filter((option) => supplier.includes(option.value))
    }
  }, [supplierOptions, supplier])

  const selectedOperationalStatuses = useMemo(() => {
    if (operationalStatus) {
      return operationalStatusOptions(t).filter((option) =>
        operationalStatus.includes(option.value)
      )
    }
  }, [operationalStatus, t])

  const selectedHubs = useMemo(() => {
    if (hubOptions.length) {
      return hub?.length
        ? hubOptions.filter((option) => hub.includes(option.value))
        : []
    }
  }, [hub, hubOptions])

  const selectedBluetoothOption = useMemo(() => {
    return (
      bluetoothOptions(t).find((option) => bluetooth == option.value) ||
      bluetoothOptions(t)[0]
    )
  }, [bluetooth, t])

  return (
    <div>
      <div className="flex gap-x-2 md:justify-between">
        <div className="flex gap-x-2">
          {isAllowed([Permission.READ_VEHICLES_ALL]) && selectedHubs && (
            <MultiSelect
              options={hubOptions}
              placeholder={t('vehicle.filters.hub.placeholder')}
              onSelect={(value) => setParams({ hub: value, page: 0 })}
              selectedOptions={selectedHubs}
              hasSearch
            />
          )}
          <MultiSelect
            options={supplierOptions}
            placeholder={t('vehicle.filters.supplier.placeholder')}
            onSelect={(value) => setParams({ supplier: value, page: 0 })}
            selectedOptions={selectedSuppliers}
          />
          <MultiSelect
            options={operationalStatusOptions(t)}
            placeholder={t('vehicle.filters.operationalStatus.placeholder')}
            onSelect={(value) =>
              setParams({ operationalStatus: value, page: 0 })
            }
            selectedOptions={selectedOperationalStatuses}
          />
          <CustomSelect
            options={bluetoothOptions(t)}
            displayValue={`${t('vehicle.filters.bluetooth.placeholder')}: ${
              selectedBluetoothOption?.label
            }`}
            onSelect={(option) =>
              setParams({ bluetooth: option.value, page: 0 })
            }
            selectedOption={selectedBluetoothOption}
            variant="medium"
            buttonVariant="dashboard-filter"
          />
        </div>
      </div>
      <VehicleTable
        vehicles={vehicles.nodes}
        refetchVehicles={refetchVehicles}
      />
      <Pagination
        totalCount={vehicles.totalCount}
        pageLimit={pageLimit}
        pageIndex={pageIndex}
        pageCount={pageCount}
        setPageSize={(size) => setParams({ page: 0, limit: size })}
        gotoPage={(page) => setParams({ page })}
        previousPage={() => setParams({ page: pageIndex - 1 })}
        nextPage={() => setParams({ page: pageIndex + 1 })}
        canPreviousPage={pageIndex > 0}
        canNextPage={pageIndex < pageCount - 1}
      />
    </div>
  )
}

export default memo(VehicleView)
