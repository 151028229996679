import { BADGE_COLOR } from './helpers'

type Props = {
  value: string
  label: string
}

const Badge = ({ value, label }: Props) => (
  <span
    className={`${BADGE_COLOR[value]} text-base p-2 rounded font-medium text-white uppercase select-none`}
    data-testid="badge"
  >
    {label}
  </span>
)

export default Badge
