import { SearchIcon } from '@heroicons/react/outline'

type Props = {
  value?: string
  placeholder?: string
  onChange?: (value: string) => void
}

const SearchField = ({ value, placeholder, onChange }: Props) => {
  return (
    <div className="rounded-lg bg-zinc-800 pl-5 pr-5 h-12 flex items-center w-fit md:w-1/5 border-none">
      <input
        id="search"
        className="border-none focus:outline-none focus:ring-0 focus:ring-offset-0 focus:text-base w-full h-full placeholder:text-gray-light bg-inherit placeholder:opacity-20 font-normal"
        type="text"
        value={value}
        onChange={(e) => onChange && onChange(e.target.value)}
        placeholder={placeholder}
      />
      <SearchIcon className="h-6 w-6 text-white flex-shrink-0 opacity-20" />
    </div>
  )
}

export default SearchField
