import { GraphQLError } from 'graphql'

export type GraphileError = GraphQLError & { detail: string; errcode: string }

const conflictErrorCode = '23505'

export const missingBlutoothLockErrorCode = 'MISSING_BLUETOOTH_LOCK'
export const alreadyUnassignedError = 'P0001'
export const FORBIDDEN = 'FORBIDDEN'

export const knownErrorCodes = [
  conflictErrorCode,
  missingBlutoothLockErrorCode,
  alreadyUnassignedError,
  FORBIDDEN,
]
