import { useTranslation } from 'react-i18next'
import {
  ArrowSmLeftIcon,
  CogIcon,
  DocumentReportIcon,
  ExclamationIcon,
} from '@heroicons/react/outline'
import { useNavigate } from 'react-router-dom'

import SidebarLink from './SidebarLink'

const SIDEBAR_LINKS = [
  { name: 'monitoring', to: '.', Icon: DocumentReportIcon },
  { name: 'settings', to: 'edit', Icon: CogIcon },
  { name: 'damageReports', to: 'damage-reports', Icon: ExclamationIcon },
]

type Props = {
  title: string
  hideSidebarLinks?: boolean
}

const Sidebar = ({ title, hideSidebarLinks }: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className="w-fit md:w-72 md:sticky md:h-full md:border-r md:border-darkBlue-light pr-4 pt-10 font-medium text-sm">
      <button
        onClick={() => navigate(-1)}
        className="h-5 mb-6 hover:text-primary"
      >
        <ArrowSmLeftIcon className="w-5 h-5 inline" />
        <span className="inline-block align-middle text-base">
          {t('vehicle.sidebar.back')}
        </span>
      </button>
      <p className="text-xl mb-8 truncate" title={title}>
        {title}
      </p>
      {!hideSidebarLinks && (
        <ul className="flex flex-row md:flex-col justify-between">
          {SIDEBAR_LINKS.map((link) => (
            <li key={link.name}>
              <SidebarLink
                to={link.to}
                icon={link.Icon}
                title={t(`vehicle.sidebar.${link.name}`)}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
export default Sidebar
