import { Outlet, useParams } from 'react-router-dom'

import Sidebar from '~/components/Sidebar'
import { useVehicleByIdQuery } from '~/graphql/generated/types'
import { Permission, usePermissions } from '~/hooks'

const VehicleDetails = () => {
  const { id } = useParams()
  const { isAllowed } = usePermissions()

  const { data, loading, error } = useVehicleByIdQuery({
    skip: !isAllowed([
      Permission.READ_VEHICLES_ALL,
      Permission.READ_VEHICLES_OWN,
    ]),
    variables: {
      id,
      includeDamages: isAllowed([Permission.READ_DAMAGES_ALL]),
    },
    fetchPolicy: 'network-only',
  })

  if (loading || error || !data?.vehicle) return <div />

  return (
    <div className="flex flex-col md:flex-row h-full text-inherit bg-charcoal px-10">
      <Sidebar title={data.vehicle.sku ?? ''} />
      <div className="w-full px-4 md:px-8 py-10">
        <Outlet context={data.vehicle} />
      </div>
    </div>
  )
}

export default VehicleDetails
