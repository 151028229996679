import { useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { gql, ApolloError, useApolloClient } from '@apollo/client'
import { RiderStatus } from '~/graphql/generated/types'
import Table from '~/components/Table'
import { UnassignVehicle } from './types'
import { GraphileError, alreadyUnassignedError } from '~/utils/errors'
import { formatRiderName } from '~/utils/riders'
import { Permission, usePermissions } from '~/hooks'

const COLUMNS = ['rider', 'status', 'vehicle', 'hub']

type Props = {
  vehicles: UnassignVehicle[]
}

type State = {
  id: string
}

export enum UnassignVehicleOperationalStatus {
  Add = 'ADD',
  Remove = 'REMOVE',
  SelectAll = 'SELECT_ALL',
  DeselectAll = 'DESELECT_ALL',
}

export type action =
  | { type: UnassignVehicleOperationalStatus.Add; payload: string }
  | { type: UnassignVehicleOperationalStatus.Remove; payload: string }
  | {
      type: UnassignVehicleOperationalStatus.SelectAll
      payload: State[]
    }
  | { type: UnassignVehicleOperationalStatus.DeselectAll }

export const initialState = []

export function unassignVehicleReducer(
  state: State[],
  action: action
): State[] {
  switch (action.type) {
    case UnassignVehicleOperationalStatus.Add:
      return [...state, { id: action.payload }]
    case UnassignVehicleOperationalStatus.Remove:
      return state.filter((vehicle) => vehicle.id !== action.payload)
    case UnassignVehicleOperationalStatus.SelectAll:
      return action.payload
    case UnassignVehicleOperationalStatus.DeselectAll:
      return initialState
    default:
      return state
  }
}

const unassignVehicleDoc = (vehicle: State) => `
_${vehicle.id.replace(/-/g, '_')}: unassignVehicle(
  input: {
    vehicleId: "${vehicle.id}"
  }
) {
  vehicle {
    id
  }
}
`
export const getMutationDoc = (vehicles: State[]) => gql`
mutation UnassignVehicles {
  ${vehicles.map(unassignVehicleDoc)}
}`

const UnassignVehicleTable = ({ vehicles }: Props) => {
  const { t } = useTranslation()
  const { isAllowed } = usePermissions()

  const apolloClient = useApolloClient()

  const headers = [
    '',
    ...COLUMNS.map((column) => t(`VehicleUnassign.${column}`)),
  ]
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const [selectedUnassignVehicles, setSelectedUnassignVehicles] = useReducer(
    unassignVehicleReducer,
    initialState
  )

  const onChange = (vehicleId: string) => {
    selectedUnassignVehicles?.some((vehicle: State) => vehicle.id === vehicleId)
      ? setSelectedUnassignVehicles({
          type: UnassignVehicleOperationalStatus.Remove,
          payload: vehicleId,
        })
      : setSelectedUnassignVehicles({
          type: UnassignVehicleOperationalStatus.Add,
          payload: vehicleId,
        })
  }

  const checked = (vehicleId: string) => {
    return selectedUnassignVehicles.some((vehicle) => vehicle.id === vehicleId)
  }

  const selectAllVehicle = () => {
    const vehicleIds: State[] = vehicles.map((vehicle) => ({
      id: vehicle.id,
    }))
    setSelectedUnassignVehicles({
      type: UnassignVehicleOperationalStatus.SelectAll,
      payload: vehicleIds,
    })
  }

  const deselectAllVehicle = () => {
    setSelectedUnassignVehicles({
      type: UnassignVehicleOperationalStatus.DeselectAll,
    })
  }

  const unassignSelectedVehicles = async () => {
    if (!selectedUnassignVehicles.length) return
    setLoading(true)

    try {
      const mutationDoc = getMutationDoc(selectedUnassignVehicles)

      await apolloClient.mutate({
        mutation: mutationDoc,
        refetchQueries: ['AssignedVehicles'],
        awaitRefetchQueries: true,
      })
    } catch (error) {
      const errors = (error as ApolloError)?.graphQLErrors as GraphileError[]
      if (errors.length) {
        // show no error if vehicle list was just out-of-date
        if (errors.some((err) => err.errcode !== alreadyUnassignedError)) {
          setError(`VehicleForm.error.unkown`)
        }
        // refresh to clear out-of-date vehicle assignments
        await apolloClient.refetchQueries({
          include: ['AssignedVehicles'],
        })
      }
    } finally {
      // clear list of vehicles after mutation to prevent failing retry
      deselectAllVehicle()
      setLoading(false)
    }
  }

  const cells = (vehicle: UnassignVehicle) => (
    <>
      <td>
        <input
          type="checkbox"
          value={vehicle.id}
          className="rounded border-transparent focus:border-transparent bg-darkBlue checked:bg-primary checked:hover:bg-primary checked:focus:bg-primary"
          checked={checked(vehicle.id)}
          onChange={() => onChange(vehicle.id)}
          data-testid={`unassign-checkbox-${vehicle.id}`}
        />
      </td>
      <td>
        <div className="p-4 pl-1 block relative">
          {vehicle.rider?.firstName && vehicle.rider?.lastName
            ? formatRiderName(vehicle.rider.firstName, vehicle.rider.lastName)
            : '-'}
          <span
            className={`absolute top-0 right-25 h-4 w-4 inline-block rounded-full ${
              vehicle.rider?.status === RiderStatus.Online
                ? 'bg-green'
                : 'bg-red'
            }`}
          ></span>
        </div>
      </td>
      <td>
        <div className="p-4 pl-1 block">
          <div>{vehicle.rider?.status ? t(vehicle.rider.status) : '-'}</div>
        </div>
      </td>
      <td>
        <div className="p-4 pl-1 block">
          <div>{vehicle.sku}</div>
          <div>{vehicle.vehicleSupplier?.name || '-'}</div>
        </div>
      </td>
      <td>
        <div className="p-4 pl-1 block">{vehicle.hubSlug}</div>
      </td>
    </>
  )

  return (
    <div className="max-w-full overflow-x-auto overflow-y-hidden relative">
      <Table headers={headers} rows={vehicles} cells={cells} />

      {!!error && (
        <p className="text-red text-sm mb-2" data-testid="unassign-error">
          {t(error)}
        </p>
      )}

      {vehicles.length > 0 && (
        <>
          <button
            className="text-sm text-white text-center hover:cursor-pointer mr-7"
            onClick={() => selectAllVehicle()}
          >
            {t('VehicleUnassign.selectAll')}
          </button>

          <button
            className="text-sm text-white text-center hover:cursor-pointer"
            onClick={() => deselectAllVehicle()}
          >
            {t('VehicleUnassign.deselectAll')}
          </button>
          <div className="mt-7">
            <button
              className="primary-btn font-bold text-base"
              onClick={unassignSelectedVehicles}
              disabled={
                loading ||
                !selectedUnassignVehicles.length ||
                !isAllowed([
                  Permission.WRITE_VEHICLE_ASSIGNMENTS_ALL,
                  Permission.WRITE_VEHICLE_ASSIGNMENTS_OWN,
                ])
              }
            >
              {t('VehicleUnassign.unassignSelectedVehicles')}
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default UnassignVehicleTable
