// https://github.com/goflink/hub-portal/blob/main/src/hubportal-sdk.ts

export type HubCore = {
  slug: string
  city: string
  countryCode: string
  name: string
}

export type StorageEventT = 'token' | 'country' | 'hub' | 'locale'
export type CountryT = 'DE' | 'FR' | 'AT' | 'NL'
export type LocaleT = 'en' | 'de' | 'fr' | 'nl'
export type TokenT = string
export type StorageDataT = string | object | null
type StorageDataCallbackT = (e: StorageDataT) => void

const getStorage = (
  key: StorageEventT,
  initialValue: StorageDataT = null
): StorageDataT => {
  if (typeof window === 'undefined') {
    return initialValue
  }
  try {
    const item = window.localStorage.getItem(key)

    return item ? JSON.parse(item) : initialValue
  } catch (error) {
    console.error(error)

    return initialValue
  }
}

const setStorage = (key: StorageEventT, data: StorageDataT) => {
  try {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(key, JSON.stringify(data))
    }
  } catch (error) {
    console.error(error)
  }
}

const dispatch = (event: StorageEventT, data: StorageDataT) => {
  window.dispatchEvent(new CustomEvent(event, { detail: data }))
}

const subscribe = (event: StorageEventT, callback: StorageDataCallbackT) => {
  window.addEventListener(event, (e: CustomEventInit) => callback(e.detail))
}

const unsubscribe = (event: StorageEventT, eventListener: EventListener) => {
  window.removeEventListener(event, eventListener)
}

const SDK = {
  getToken: () => getStorage('token'),
  setToken: (token: string | null) => {
    setStorage('token', token)
    dispatch('token', token)
  },
  onTokenChange: (callback: StorageDataCallbackT) => {
    subscribe('token', callback)
  },
  offTokenChange: (eventListener: EventListener) => {
    unsubscribe('token', eventListener)
  },

  getHub: () => getStorage('hub'),
  setHub: (hub: HubCore) => {
    setStorage('hub', hub)
    dispatch('hub', hub)
  },
  onHubChange: (callback: StorageDataCallbackT) => {
    subscribe('hub', callback)
  },
  offHubChange: (eventListener: EventListener) => {
    unsubscribe('hub', eventListener)
  },

  getCountry: () => getStorage('country'),
  setCountry: (country: CountryT) => {
    setStorage('country', country)
    dispatch('country', country)
  },
  onCountryChange: (callback: StorageDataCallbackT) => {
    subscribe('country', callback)
  },
  offCountryChange: (eventListener: EventListener) => {
    unsubscribe('country', eventListener)
  },

  getLocale: () => getStorage('locale'),
  setLocale: (locale: LocaleT) => {
    setStorage('locale', locale)
    dispatch('locale', locale)
  },
  onLocaleChange: (callback: StorageDataCallbackT) => {
    subscribe('locale', callback)
  },
  offLocaleChange: (callback: StorageDataCallbackT) => {
    unsubscribe('locale', callback)
  },
}

export default SDK
