import {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
  type ChangeEvent,
} from 'react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { useTranslation } from 'react-i18next'

import { Option } from '~/components/CustomSelect'
import { useOutsideClick, useKeyPress } from '~/hooks'

type Props = {
  options: Option[]
  onChange: (option: Option) => void
  isDisabled?: boolean
  checked: (option: Option) => boolean | undefined
  variant?: string
  buttonVariant?: string
  displayValue: string
  hasSearch?: boolean
}

const CheckboxSelect = ({
  options,
  displayValue,
  hasSearch,
  onChange,
  checked,
  isDisabled,
  variant = 'medium',
  buttonVariant = 'dashboard-filter',
}: Props) => {
  const { t } = useTranslation()
  const ref = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const escape = useKeyPress('Escape')
  const [isOpen, setOpen] = useState(false)
  const [filteredOptions, setOptions] = useState<Option[]>([])
  const [searchText, setSearchText] = useState<string | null>(null)

  useEffect(() => {
    if (!searchText && options.length) {
      setOptions(options)
    }
  }, [options, searchText])

  const onClickButton = useCallback(() => {
    if (!hasSearch) {
      setOpen(!isOpen)
    } else if (!isOpen) {
      setSearchText('')
      setOptions(options)
      setOpen(true)
    }
  }, [hasSearch, isOpen, options])

  const onClose = useCallback(() => {
    if (hasSearch && !!searchText) {
      setSearchText(null)
      setOptions(options)
      inputRef.current?.blur()
    }
    setOpen(false)
  }, [hasSearch, options, searchText])

  useEffect(() => {
    if (escape && isOpen) {
      onClose()
    }
  }, [escape, isOpen, onClose])

  useOutsideClick(ref, () => {
    if (isOpen) {
      onClose()
    }
  })

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    if (!hasSearch) return
    setSearchText(e.target.value)

    if (e.target.value) {
      const normalizedValue = e.target.value.trim().toUpperCase()
      setOptions(
        options.filter((item) =>
          item.label.toUpperCase().includes(normalizedValue)
        )
      )
    }
  }

  return (
    <div className={`${variant} relative`} ref={ref}>
      <button
        className={`relative cursor-default ${buttonVariant}`}
        onClick={onClickButton}
        disabled={isDisabled || !options.length}
        type="button"
      >
        <input
          ref={inputRef}
          className={`block truncate text-base border-0 py-3 pr-8 w-full text-left placeholder-gray-light cursor-default disabled:cursor-not-allowed ${
            !hasSearch ? 'caret-transparent' : ''
          }`}
          value={searchText ?? displayValue}
          placeholder={displayValue}
          onChange={onSearch}
          disabled={isDisabled || !options.length}
          readOnly={!hasSearch}
        />
        <span className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
          <ChevronDownIcon className="w-5 h-5" />
        </span>
      </button>
      {isOpen && (
        <ul className="absolute w-full z-10 bg-darkBlue max-h-56 rounded-md py-1 border-0 overflow-auto text-sm">
          {filteredOptions?.length ? (
            filteredOptions.map((option: Option) => (
              <li key={option.value} className="hover:bg-charcoal">
                <label className="flex items-center p-2 pr-4 my-0.5 cursor-default">
                  <input
                    type="checkbox"
                    value={option.value}
                    className="rounded border-transparent focus:border-transparent bg-charcoal hover:bg-darkBlue checked:bg-primary checked:hover:bg-primary checked:focus:bg-primary"
                    checked={checked(option)}
                    onChange={() => onChange(option)}
                  />
                  <span className="ml-3 block truncate text-base">
                    {option.label}
                  </span>
                </label>
              </li>
            ))
          ) : (
            <li className="text-center p-2">{t('noOptions')}</li>
          )}
        </ul>
      )}
    </div>
  )
}

export default memo(CheckboxSelect)
