import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  AssignedVehiclesQuery,
  useAssignedVehiclesQuery,
} from '~/graphql/generated/types'
import { Spinner } from '~/components/Spinner'
import UnassignVehicleView from '~/components/UnassignVehicleView'
import { RiderStatusFilter } from '~/components/UnassignVehicleView/helpers'
import Sidebar from '~/components/Sidebar'
import { getSelectedHubSlug } from '~/utils/hubs'
import { Permission, usePermissions } from '~/hooks'
import { FeatureFlags } from '~/utils/eppo'
import { getInstance } from '@eppo/js-client-sdk'

type UnassignVehicles = AssignedVehiclesQuery['vehicles']
type FilteredVehicles = NonNullable<AssignedVehiclesQuery['vehicles']>['nodes']

const filterVehicles = (
  vehicles: UnassignVehicles,
  riderStatusFilter: RiderStatusFilter,
  skuSearchText: string
): FilteredVehicles | null => {
  if (!vehicles || !vehicles?.nodes) {
    return null
  }

  const filteredVehicles = riderStatusFilter
    ? vehicles.nodes.filter(
        (vehicle) => vehicle.rider?.status == riderStatusFilter
      )
    : vehicles.nodes

  return skuSearchText
    ? filteredVehicles.filter((vehicle) =>
        (vehicle.sku ?? '')
          .toLocaleLowerCase()
          .includes(skuSearchText.toLocaleLowerCase())
      )
    : filteredVehicles
}

const Unassign = () => {
  const { t } = useTranslation('translation')
  const { isAllowed } = usePermissions()
  const eppoClient = getInstance()

  const hubSlug = getSelectedHubSlug()
  const [riderStatusFilter, setRiderStatusFilter] =
    useState<RiderStatusFilter>('')
  const [skuSearchText, setSkuSearchText] = useState<string>('')

  const { data, loading } = useAssignedVehiclesQuery({
    skip: !isAllowed([
      Permission.READ_VEHICLE_ASSIGNMENTS_ALL,
      Permission.READ_VEHICLE_ASSIGNMENTS_OWN,
    ]),
    variables: {
      hub: hubSlug,
    },
    fetchPolicy: 'cache-and-network',
  })

  const isMandatoryBikeAssigmentActive = useMemo(() => {
    return eppoClient.getBooleanAssignment(
      FeatureFlags.MANDATORY_BIKE_ASSIGNMENT_EPPO,
      hubSlug?.toLowerCase() || '',
      {
        hub_slug: hubSlug?.toLowerCase() || '',
      },
      false
    )
  }, [])

  // NOTE: there is no pagination, filter in the frontend
  const filteredVehicles = filterVehicles(
    data?.vehicles,
    riderStatusFilter,
    skuSearchText
  )

  if (isMandatoryBikeAssigmentActive) {
    return <div></div>
  }

  return (
    <div className="flex flex-col md:flex-row h-full px-10">
      <Sidebar title={t('VehicleUnassign.heading')} hideSidebarLinks />
      <div className="w-full px-4 md:px-8 py-10">
        <h1 className="font-medium text-2xl mb-3">
          {`${t('VehicleUnassign.heading')} ${hubSlug ?? ''}`}
        </h1>
        <h3>{t('VehicleUnassign.subheader')}</h3>

        {loading && (
          <div className="absolute inset-0 w-screen h-screen flex justify-center items-center z-50">
            <Spinner size={28} />
          </div>
        )}
        {filteredVehicles && (
          <UnassignVehicleView
            vehicles={filteredVehicles}
            riderStatus={riderStatusFilter}
            search={skuSearchText}
            setRiderStatusFilter={setRiderStatusFilter}
            setSkuSearchText={setSkuSearchText}
          />
        )}
      </div>
    </div>
  )
}

export default Unassign
