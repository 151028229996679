import { ImportVehicle } from '~/components/Import/types'

export const validateVehicle = (vehicle: ImportVehicle, hubs: string[]) => {
  let errors: string[] = []
  // Note: If these validations get more complex, consider including yup or similar lib
  errors = !vehicle.licensePlate
    ? [...errors, 'VehicleImport.errors.missingLicensePlate']
    : errors
  errors = !vehicle.supplier
    ? [...errors, 'VehicleImport.errors.missingSupplier']
    : errors
  errors =
    vehicle.supplier && !vehicle.supplierId
      ? [...errors, 'VehicleImport.errors.unknownSupplier']
      : errors
  errors = !vehicle.model
    ? [...errors, 'VehicleImport.errors.missingModel']
    : errors
  errors =
    vehicle.model && vehicle.supplierId && !vehicle.modelId
      ? [...errors, 'VehicleImport.errors.unknownModel']
      : errors
  errors = !vehicle.hubSlug
    ? [...errors, 'VehicleImport.errors.missingHubSlug']
    : errors
  errors =
    !!vehicle.hubSlug && !hubs.includes(vehicle.hubSlug)
      ? [...errors, 'VehicleImport.errors.unknownHubSlug']
      : errors
  errors =
    vehicle.bluetooth && !vehicle.moduleId // bluetooth vehicle without module id invalid
      ? [...errors, 'VehicleImport.errors.missingModuleId']
      : errors
  return errors
}
