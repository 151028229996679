import { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Option } from '~/components/CustomSelect'
import CheckboxSelect from '~/components/CheckboxSelect'

type Props = {
  options: Option[]
  selectedOptions?: Option[]
  onSelect: (value: string[]) => void
  isDisabled?: boolean
  variant?: string
  buttonVariant?: string
  placeholder?: string
  hasSearch?: boolean
}

const MultiSelect = ({
  options,
  selectedOptions = [],
  onSelect,
  isDisabled,
  variant,
  buttonVariant,
  hasSearch,
  placeholder = 'Select...',
}: Props) => {
  const { t } = useTranslation()

  const onChange = (option: Option) => {
    const newOptions = (
      selectedOptions?.some(
        (selectedOpt) => selectedOpt.value === option?.value
      )
        ? selectedOptions.filter(
            (selectedOpt) => selectedOpt.value !== option.value
          )
        : [...selectedOptions, option]
    ).map((item) => item.value)

    onSelect(newOptions)
  }

  const displayValue = useMemo(() => {
    switch (selectedOptions.length) {
      case 0:
        return placeholder
      case 1:
        return selectedOptions[0].label
      default:
        return t('MultiSelect.more', {
          name: selectedOptions[0].label,
          count: selectedOptions.length - 1,
        })
    }
  }, [placeholder, selectedOptions, t])

  return (
    <CheckboxSelect
      options={options}
      displayValue={displayValue}
      onChange={onChange}
      checked={(option) =>
        selectedOptions.some((item) => item.value === option.value)
      }
      hasSearch={hasSearch}
      isDisabled={isDisabled}
      variant={variant}
      buttonVariant={buttonVariant}
    />
  )
}

export default memo(MultiSelect)
