import { RefObject, useEffect } from 'react'

type Handler = (event: MouseEvent | TouchEvent) => void

const useOutsideClick = (
  ref: RefObject<HTMLElement> | null,
  handler: Handler
) => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (
        !ref ||
        !ref.current ||
        ref.current.contains(event.target as HTMLElement)
      ) {
        return
      }
      handler(event)
    }
    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}
export default useOutsideClick
