import fetch from 'cross-fetch'
import { Auth0Client } from '@auth0/auth0-spa-js'
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  defaultDataIdFromObject,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { StorageDataT } from '~/utils/hubportal-sdk'
import jwtDecode, { JwtPayload as BaseJwtPayload } from 'jwt-decode'

const AUDIENCE = process.env.AUTH_AUDIENCE
const DEVELOPMENT_MODE = process.env.NODE_ENV === 'development'
const DEVELOPMENT_PERMISSIONS = process.env.DEVELOPMENT_PERMISSIONS
const GRAPHQL_ENDPOINT = `${process.env.BASE_API_ENDPOINT}/graphql`

type JwtPayload = BaseJwtPayload & {
  permissions: string
  allowed_hubs: string
}

const createAuthLink = (authClient?: Auth0Client, token?: StorageDataT) => {
  if (!authClient) {
    // send headers for standalone mode & E2E test (from hardcoded env)
    return setContext(async () => ({
      headers: {
        authorization: token ? `Bearer ${token}` : '',
        ...(DEVELOPMENT_MODE && {
          'x-token-c-permissions': DEVELOPMENT_PERMISSIONS,
        }),
      },
    }))
  }

  return setContext(async () => {
    try {
      const clientToken = await authClient.getTokenSilently({
        audience: AUDIENCE,
      })
      // decode token in development env
      let permissions = null
      let allowed_hubs = null
      if (DEVELOPMENT_MODE) {
        const decodedToken = jwtDecode<JwtPayload>(clientToken) || {}
        permissions = decodedToken.permissions
        allowed_hubs = decodedToken.allowed_hubs
      }

      return {
        headers: {
          authorization: `Bearer ${clientToken}`,
          // send headers on integrated mode in development (from token)
          ...(DEVELOPMENT_MODE && { 'x-token-c-permissions': permissions }),
          ...(DEVELOPMENT_MODE && { 'x-token-c-allowedhubs': allowed_hubs }),
        },
      }
    } catch (error) {
      console.error('failed to get accessToken', error)
      return {
        headers: {
          authorization: '',
        },
      }
    }
  })
}

export const httpLink = new HttpLink({ uri: GRAPHQL_ENDPOINT, fetch })
export const cache = new InMemoryCache({
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      case 'Damage':
        return `Damage:${responseObject.id}`
      case 'Vehicle':
        return `Vehicle:${responseObject.id}`
      case 'Hub':
        return `Hub:${responseObject.slug}`

      default:
        return defaultDataIdFromObject(responseObject)
    }
  },
})

const createClient = (authClient?: Auth0Client, token?: StorageDataT) =>
  new ApolloClient({
    link: createAuthLink(authClient, token).concat(httpLink),
    cache,
  })

export default createClient
