import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type ChangeEvent,
} from 'react'
import { ChevronDownIcon, CheckIcon } from '@heroicons/react/solid'
import { useTranslation } from 'react-i18next'

import { VehicleOperationalStatus } from '~/graphql/generated/types'
import { useKeyPress, useOutsideClick } from '~/hooks'

export type Option = {
  value: string | VehicleOperationalStatus
  label: string
  isDisabled?: boolean
}

type Props = {
  name?: string
  placeholder?: string
  options: Option[]
  selectedOption?: Option
  onSelect?: (option: Option) => void
  isDisabled?: boolean
  variant?: string
  buttonVariant?: string
  displayValue?: string
  hasSearch?: boolean
  isRequired?: boolean
  placement?: 'top' | 'bottom'
}

const CustomSelect = ({
  name,
  placeholder,
  isRequired,
  hasSearch,
  options,
  selectedOption,
  onSelect,
  isDisabled,
  variant = 'large',
  buttonVariant = 'form-input',
  displayValue = selectedOption?.label || '',
  placement = 'bottom',
}: Props) => {
  const { t } = useTranslation()
  const ref = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const escape = useKeyPress('Escape')
  const [isOpen, setOpen] = useState(false)
  const [filteredOptions, setFilteredOptions] = useState(options)
  const [searchText, setSearchText] = useState<string | null>(null)

  useEffect(() => {
    setFilteredOptions(options)
  }, [options])

  const onClickButton = useCallback(() => {
    if (!hasSearch) {
      setOpen(!isOpen)
    } else if (!isOpen) {
      setSearchText('')
      setFilteredOptions(options)
      setOpen(true)
    }
  }, [hasSearch, isOpen, options])

  const onClose = useCallback(() => {
    if (hasSearch) {
      setSearchText(null)
      setFilteredOptions(options)
      inputRef.current?.blur()
    }
    setOpen(false)
  }, [hasSearch, options])

  useEffect(() => {
    if (escape && isOpen) {
      onClose()
    }
  }, [escape, isOpen, onClose])

  useOutsideClick(ref, () => {
    if (isOpen) {
      onClose()
    }
  })

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    if (!hasSearch) return
    setSearchText(e.target.value)
    if (!e.target.value) return setFilteredOptions(options)

    const normalizedValue = e.target.value.trim().toUpperCase()
    setFilteredOptions(
      options.filter((item) =>
        item.label.toUpperCase().includes(normalizedValue)
      )
    )
  }
  const onSelectOption = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    option: Option
  ) => {
    event.preventDefault()
    onClose()
    onSelect && onSelect(option)
  }

  return (
    <div className={`${variant} relative`} ref={ref}>
      <button
        className={`relative cursor-default ${buttonVariant}`}
        onClick={onClickButton}
        disabled={isDisabled || !options.length}
        type="button"
      >
        <input
          ref={inputRef}
          className={`block truncate border-0 pr-8 w-full text-left placeholder-gray-light cursor-default disabled:cursor-not-allowed ${
            !hasSearch ? 'caret-transparent' : ''
          } ${variant === 'small' ? 'py-1' : 'py-3'}`}
          value={searchText ?? displayValue}
          onChange={onSearch}
          disabled={isDisabled || !options.length}
          name={name}
          placeholder={placeholder}
          required={isRequired}
        />
        <span className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
          <ChevronDownIcon className="w-5 h-5 text-gray-400" />
        </span>
      </button>
      {isOpen && (
        <ul
          className={`absolute w-full z-10 bg-darkBlue max-h-56 rounded-md py-1 border-0 overflow-auto text-sm ${
            placement == 'top' ? 'bottom-full' : ''
          }`}
        >
          {filteredOptions?.length ? (
            filteredOptions.map((option: Option) => (
              <li key={option.value} className="hover:bg-charcoal">
                <button
                  className="flex items-center justify-between p-2 my-0.5 cursor-default hover:bg-charcoal w-full border-b-0"
                  onClick={(event) => onSelectOption(event, option)}
                  type="button"
                  disabled={option.isDisabled}
                >
                  <span className="ml-3 block truncate">{option.label}</span>
                  {selectedOption?.value === option.value && (
                    <span className="text-primary">
                      <CheckIcon className="h-5 w-5 fill-current" />
                    </span>
                  )}
                </button>
              </li>
            ))
          ) : (
            <li className="text-center p-2">{t('noOptions')}</li>
          )}
        </ul>
      )}
    </div>
  )
}

export default CustomSelect
