import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { CheckCircleIcon, ExclamationIcon } from '@heroicons/react/outline'
import { ImportVehicleWrapper } from '../types'
import Table from '~/components/Table'

interface Props {
  vehicles: ImportVehicleWrapper[]
}

const TABLE_COLUMNS = [
  'vehicle.model.licensePlate',
  'vehicle.model.model',
  'vehicle.model.supplier',
  'vehicle.model.hubSlug',
  '',
]

const ImportVehicleTable: FC<Props> = ({ vehicles }) => {
  const { t } = useTranslation('translation')
  const headers = TABLE_COLUMNS.map((column) => t(column))
  const cells = ({ vehicle, errors }: ImportVehicleWrapper) => (
    <>
      <td className="p-4 pl-1">{vehicle.licensePlate}</td>
      <td className="p-4 pl-1">{vehicle.model}</td>
      <td className="p-4 pl-1">{vehicle.supplier}</td>
      <td className="p-4 pl-1">{vehicle.hubSlug}</td>
      <td className="p-4 pl-1">
        {errors.length > 0 ? (
          <span>
            <ExclamationIcon className="w-5 h-5 text-gray-400 inline mr-2" />
            {errors.map((message: string) => t(message)).join(', ')}
          </span>
        ) : (
          <CheckCircleIcon role="img" className="w-5 h-5 text-gray-400" />
        )}
      </td>
    </>
  )

  return <Table headers={headers} rows={vehicles} cells={cells} />
}

export default ImportVehicleTable
