import { useMemo, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import VehicleField from '~/components/VehicleField'
import Select from '~/components/Select'
import { operationalStatusOptions } from '~/components/VehicleView/helpers'
import ArchiveVehicle from '~/components/ArchiveVehicle'
import Modal from '~/components/Modal'

import {
  DamageStatus,
  useUpdateDamageMutation,
  useUpdateOperationalStatusMutation,
  VehicleOperationalStatus,
} from '~/graphql/generated/types'
import { ExclamationIcon } from '@heroicons/react/outline'
import { Permission, usePermissions } from '~/hooks'
import { VehicleById } from '../types'

import AssignmentHistoryTable from '~/components/AssignmentHistoryTable'

const Monitoring = () => {
  const { t } = useTranslation()
  const { isAllowed } = usePermissions()
  const vehicle = useOutletContext<VehicleById>()

  const [isDamagesModalVisible, setDamagesModalVisible] = useState(false)

  const [updateDamageMutation] = useUpdateDamageMutation()
  const [updateOperationalStatusMutation] = useUpdateOperationalStatusMutation()
  const { imageUrl, archivedAt } = vehicle

  const vehicleFields = useMemo(() => {
    return Object.entries({
      ...(!vehicle.archivedAt && {
        operationalStatus: vehicle.operationalStatus,
        availabilityStatus: vehicle.availabilityStatus,
      }),
      sku: vehicle.sku,
      kind: vehicle.vehicleModel?.kind,
      supplier: vehicle.vehicleSupplier?.name,
      hubSlug: vehicle.hubSlug,
      model: vehicle.vehicleModel?.name,
      provider: vehicle.provider,
      moduleId: vehicle.moduleId,
      bluetooth: vehicle.bluetooth,
    })
  }, [vehicle])

  const updateOperationalStatus = async (value: string) => {
    await updateOperationalStatusMutation({
      variables: {
        id: vehicle.id,
        operationalStatus: value as VehicleOperationalStatus,
      },
    })
  }

  const onChangeOperationalStatus = async (value: string) => {
    if (
      value === VehicleOperationalStatus.Operational &&
      (vehicle.damages?.nodes || []).filter(
        (damage) => damage.status === DamageStatus.Reported
      ).length > 0
    ) {
      setDamagesModalVisible(true)
      return
    }

    await updateOperationalStatus(value)
  }

  const fixDamagesAndUpdateOperationalStatus = async () => {
    const mutations = (vehicle.damages?.nodes || [])
      .filter((damage) => damage.status === DamageStatus.Reported)
      .map(async (damage) => {
        await updateDamageMutation({
          variables: {
            id: damage?.id,
            status: DamageStatus.Fixed,
          },
        })
      })

    await Promise.all(mutations)
    await updateOperationalStatus(VehicleOperationalStatus.Operational)

    setDamagesModalVisible(false)
  }

  return (
    <>
      <div className="flex md:flex-row flex-col relative">
        <h2 className="md:w-4/12 font-medium text-2xl">
          {t('vehicle.monitoring.header')}
        </h2>

        <div className="flex md:flex-row-reverse flex-wrap-reverse">
          <ArchiveVehicle
            vehicle={vehicle}
            isDisabled={
              !!archivedAt || !isAllowed([Permission.WRITE_VEHICLES_ALL])
            }
          />
          <form>
            <label
              htmlFor="operationalStatus"
              className="flex flex-col w-fit ml-4 md:ml-0 md:mr-4"
            >
              <span className="text-base font-medium mb-1">
                {t('vehicle.monitoring.changeStatus')}
              </span>
              <Select
                name="operationalStatus"
                value={vehicle.operationalStatus}
                options={operationalStatusOptions(t)}
                onChange={onChangeOperationalStatus}
                isDisabled={!isAllowed([Permission.WRITE_VEHICLES_ALL])}
              />
            </label>
          </form>
        </div>

        {imageUrl && (
          <div
            className="bg-contain bg-no-repeat bg-center md:w-4/12 md:h-96 w-full h-60 md:absolute md:top-0 md:right-0"
            data-testid="image"
            style={{ backgroundImage: `url(${imageUrl})` }}
          />
        )}
      </div>

      <div className="flex flex-col-reverse md:flex-row">
        <div className="w-full md:w-8/12 mt-6">
          <div className="grid grid-cols-2" data-testid="vehicle-fields">
            {!!archivedAt && (
              <div className="col-span-full min-w-max mb-2 rounded p-4 border border-gray-200 font-semibold">
                <ExclamationIcon className="w-5 h-5 inline text-primary mr-2" />
                <span className="inline align-middle text-xs md:text-sm">
                  {t('vehicle.monitoring.archived', {
                    date: new Date(archivedAt),
                  })}
                </span>
              </div>
            )}
            {vehicleFields.map(([key, value]) => (
              <VehicleField key={key} label={key} value={value} />
            ))}
          </div>
        </div>
      </div>
      <div className="w-full">
        <h3 className="my-10 font-medium text-2xl">
          {t('vehicle.monitoring.assignmentHistory')}
        </h3>
        {isAllowed([
          Permission.READ_VEHICLE_ASSIGNMENTS_ALL,
          Permission.READ_VEHICLE_ASSIGNMENTS_OWN,
        ]) && <AssignmentHistoryTable vehicleId={vehicle.id} />}
      </div>
      <Modal
        isOpen={isDamagesModalVisible}
        onCancel={() => setDamagesModalVisible(false)}
        submitText={t('VehicleEdit.damagesModal.fixed.submit')}
        cancelText={t('cancel')}
        onConfirm={fixDamagesAndUpdateOperationalStatus}
      >
        <div className="px-6 pb-12 flex items-start flex-col text-left">
          <h2 className="text-lg font-bold">
            {t('VehicleEdit.damagesModal.fixed.header')}
          </h2>
          <div className="mt-2">
            <p className="text-sm">
              {t('VehicleEdit.damagesModal.fixed.message')}
            </p>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Monitoring
