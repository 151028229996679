import { useTranslation } from 'react-i18next'

import Sidebar from '~/components/Sidebar'
import { Spinner } from '~/components/Spinner'
import ImportVehicleTable from '~/components/Import/ImportVehicleTable'
import VehicleUpload from '~/components/Import/VehicleUpload'
import { useVehicleImport } from '~/hooks'
import { useVehicleOptionsQuery } from '~/graphql/generated/types'
import Modal from '~/components/Modal'

const Import = () => {
  const { t } = useTranslation('translation')

  const { data, loading } = useVehicleOptionsQuery()
  const {
    vehicles,
    importing,
    onImport,
    pending,
    cancelImport,
    continuePendingImport,
    conflictingVehicles,
  } = useVehicleImport(data)

  const createdVehicles = vehicles
    ? Object.values(vehicles).filter((v) => v.errors.length === 0)
    : []
  const errorVehicles = vehicles
    ? Object.values(vehicles).filter((v) => v.errors.length > 0)
    : []

  return (
    <div className="flex flex-col md:flex-row h-full text-inherit px-10">
      <Sidebar title={t('VehicleImport.heading')} hideSidebarLinks />
      <div className="w-full px-4 md:px-8 py-10 bg-charcoal">
        <h1 className="font-medium text-2xl mb-3">
          {t('VehicleImport.heading')}
        </h1>

        {loading ? (
          <div className="flex flex-auto max-h-48 w-2/5 mx-auto justify-center">
            <Spinner size={28} />
          </div>
        ) : (
          <>
            <VehicleUpload onChange={onImport} submitting={importing} />

            <Modal
              isOpen={pending}
              onCancel={cancelImport}
              submitText={t('true')}
              cancelText={t('false')}
              onConfirm={continuePendingImport}
            >
              <div className="px-6 pb-12">
                <div className="text-left">
                  <h2 className="text-lg font-bold">
                    {t('VehicleImport.heading')}
                  </h2>
                  <div className="my-2">
                    {conflictingVehicles.map((conflictingVehicle) => (
                      <div key={conflictingVehicle.id} className="my-0.5">
                        {t('ImportVehicle.conflictModal.vehicle', {
                          licensePlate: conflictingVehicle.licensePlate,
                          hubSlug: conflictingVehicle.hubSlug,
                        })}
                      </div>
                    ))}
                  </div>
                  <div className="font-bold my-2">
                    {t('VehicleImport.conflictModal.message', {
                      count: conflictingVehicles.length,
                    })}
                  </div>
                  <div>{t('VehicleImport.conflictModal.hint')}</div>
                </div>
              </div>
            </Modal>

            {vehicles && (
              <>
                {!importing && (
                  <>
                    <b>{t('VehicleImport.summary')}</b>
                    <ul className="mt-1 list-disc list-outside">
                      <li>
                        {t('VehicleImport.createdVehicles', {
                          count: createdVehicles.length,
                        })}
                      </li>
                      {errorVehicles.length > 0 && (
                        <li>
                          {t('VehicleImport.errorVehicles', {
                            count: errorVehicles.length,
                          })}
                        </li>
                      )}
                    </ul>
                    <ImportVehicleTable vehicles={Object.values(vehicles)} />
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default Import
