import { ImportVehicle } from '~/components/Import/types'
import { gql } from '@apollo/client'
import { CreatedVehicleFragmentDoc } from '~/graphql/generated/types'

const conflictingVehiclesDoc = (vehicle: ImportVehicle) => `
_${vehicle.id.replace(/-/g, '_')}: vehicles(
  filter: {licensePlate: {equalToInsensitive: "${
    vehicle.licensePlate
  }"}, supplierId: {equalTo: "${
  vehicle.supplierId
}"}, archivedAt: {isNull: true} }
) {
  nodes {
    id
    licensePlate
    hubSlug
    supplierId
  }
}
`

export const getConflictQueryDoc = (importVehicles: ImportVehicle[]) => gql`
query ConflictingVehiclesList {
${importVehicles.map(conflictingVehiclesDoc)}
}`

const updateVehicleDoc = (vehicle: ImportVehicle) => `
_${vehicle.mutationId?.replace(/-/g, '_')}: updateVehicle(
  input: {
    id: "${vehicle.id}",
    patch: {
      licensePlate: "${vehicle.licensePlate}",
      hubSlug: "${vehicle.hubSlug}",
      modelId: "${vehicle.modelId}",
      ${vehicle.bluetooth ? `bluetooth: ${vehicle.bluetooth}` : ''}
      ${vehicle.provider ? `provider: ${vehicle.provider}` : ''}
      ${vehicle.moduleId ? `moduleId: "${vehicle.moduleId}"` : ''}
    }
  }
  ) {
    clientMutationId
  }
`

const createVehicleDoc = (vehicle: ImportVehicle) => `
_${vehicle.id.replace(/-/g, '_')}: createVehicle(
  input: {
    vehicle: {
      id: "${vehicle.id}",
      kind: ${vehicle.kind},
      licensePlate: "${vehicle.licensePlate}",
      hubSlug: "${vehicle.hubSlug}",
      modelId: "${vehicle.modelId}",
      supplierId: "${vehicle.supplierId}",
      ${vehicle.bluetooth ? `bluetooth: ${vehicle.bluetooth}` : ''}
      ${vehicle.provider ? `provider: ${vehicle.provider}` : ''}
      ${vehicle.moduleId ? `moduleId: "${vehicle.moduleId}"` : ''}
    }
  }
) {
  ...CreatedVehicle
}
`

export const getMutationDoc = (
  importVehicles: ImportVehicle[],
  updateVehicles: ImportVehicle[]
) => gql`
  ${importVehicles.length ? CreatedVehicleFragmentDoc : ''}
  mutation ImportVehicles {
    ${importVehicles.length ? importVehicles.map(createVehicleDoc) : ''}
    ${updateVehicles.length ? updateVehicles.map(updateVehicleDoc) : ''}
  }`
