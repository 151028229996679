import { useEffect, useMemo, useState, type FC } from 'react'
import { ApolloProvider } from '@apollo/client'
import { Auth0Client } from '@auth0/auth0-spa-js'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import sdk, { LocaleT, StorageDataT } from '~/utils/hubportal-sdk'

import Index from '~/pages/vehicles'
import VehiclesIndex from '~/pages/vehicles/vehiclesIndex'
import VehiclesNew from '~/pages/vehicles/new'
import VehicleDetails from '~/pages/vehicles/details'
import Monitoring from '~/pages/vehicles/details/monitoring'
import DamageReports from '~/pages/vehicles/details/damageReports'
import EditVehicle from '~/pages/vehicles/details/edit'
import Import from '~/pages/vehicles/import'
import Unassign from '~/pages/vehicles/unassign'
import Damages from '~/pages/vehicles/damages'
import { AuthClientContextManager } from '~/context/AuthClientContext'

import i18n from '~/i18n'
import createClient from '~/graphql/client'
import '@hubportal/components/index.css'
import './app.css'
import EppoProvider from './EppoProvider'

const Container: FC = ({ children }) => (
  <div className="bg-charcoal text-gray-light h-full min-h-screen">
    {children}
  </div>
)

const Root = ({ authClient }: { authClient: Auth0Client }) => {
  const [token, setToken] = useState(sdk.getToken() as StorageDataT)
  const [locale, setLocale] = useState(sdk.getLocale())

  useEffect(() => {
    sdk.onLocaleChange(setLocale)
    sdk.onTokenChange(setToken)

    return () => {
      sdk.offLocaleChange(setLocale)
      sdk.offTokenChange(setToken)
    }
  }, [])

  useEffect(() => {
    i18n.changeLanguage(locale as LocaleT)
  }, [locale])

  const apolloClient = useMemo(
    () => createClient(authClient, token),
    [authClient, token]
  )

  return (
    <ApolloProvider client={apolloClient}>
      <AuthClientContextManager authClient={authClient}>
        <I18nextProvider i18n={i18n}>
          <EppoProvider>
            <BrowserRouter>
              <Container>
                <Routes>
                  <Route
                    path="/"
                    element={<Navigate replace to="/vehicles" />}
                  />
                  <Route path="vehicles" element={<Index />}>
                    <Route index element={<VehiclesIndex />} />
                    <Route path="damages" element={<Damages />} />
                  </Route>
                  <Route path="vehicles/:id" element={<VehicleDetails />}>
                    <Route index element={<Monitoring />} />
                    <Route path="damage-reports" element={<DamageReports />} />
                    <Route path="edit" element={<EditVehicle />} />
                  </Route>
                  <Route path="vehicles/new" element={<VehiclesNew />} />
                  <Route path="vehicles/import" element={<Import />} />
                  <Route path="vehicles/unassign" element={<Unassign />} />
                </Routes>
              </Container>
            </BrowserRouter>
          </EppoProvider>
        </I18nextProvider>
      </AuthClientContextManager>
    </ApolloProvider>
  )
}

export default Root
