import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { Permission, usePermissions } from '~/hooks'
import { Damage, DamageStatus } from '~/graphql/generated/types'
import Table from '~/components/Table'
import Badge from '~/components/Badge'
import UpdateDamages from '~/components/UpdateDamage'

export type TableDamage = Pick<
  Damage,
  | 'id'
  | 'status'
  | 'title'
  | 'code'
  | 'vehicle'
  | 'createdAt'
  | 'updatedAt'
  | 'vehicle'
>

type Props = {
  damages: TableDamage[]
  refetchDamages: () => void
}

export const COLUMNS = [
  'vehicle.model.supplier',
  'vehicle.model.sku',
  'vehicle.model.operationalStatus',
  'vehicle.damageReports.model.status',
  'vehicle.damageReports.model.category',
  'vehicle.damageReports.model.title',
  'vehicle.model.hubSlug',
  'vehicle.damageReports.model.createdAt',
  'vehicle.damageReports.model.updatedAt',
]

const DamageTable = ({ damages, refetchDamages }: Props) => {
  const { t } = useTranslation()
  const { isAllowed } = usePermissions()

  const [selectedDamages, setSelectedDamages] = useState<Array<TableDamage>>([])

  const headers = COLUMNS.map((column) => t(column))

  const toggleAllCheckboxes = (checked: boolean) => {
    if (checked) {
      setSelectedDamages(
        damages.filter((d) => d.status !== DamageStatus.Archived)
      )
    } else {
      setSelectedDamages([])
    }
  }

  const toggleCheckbox = (damage: TableDamage, checked: boolean) => {
    if (checked) {
      setSelectedDamages([...selectedDamages, damage])
    } else {
      setSelectedDamages(
        selectedDamages.filter(
          (selectedDamage: TableDamage) => selectedDamage.id !== damage.id
        )
      )
    }
  }

  const isChecked = (damage: TableDamage) =>
    !!selectedDamages.find(
      (selectedDamage: TableDamage) => selectedDamage.id === damage.id
    )

  const refetchAndClear = () => {
    setSelectedDamages([])
    refetchDamages()
  }

  const cells = (damage: TableDamage) => {
    const url = `/vehicles/${damage.vehicle?.id}/damage-reports`
    return (
      <>
        <td className="p-1">
          <input
            className="border border-gray-300 rounded-sm focus:rounded-sm bg-white focus:outline-none mt-1 mr-2 cursor-pointer"
            type="checkbox"
            title={t('ArchiveVehicles.checkbox.tooltip')}
            onChange={(e) => {
              toggleCheckbox(damage, e.target.checked)
            }}
            checked={isChecked(damage)}
            disabled={damage.status === DamageStatus.Archived}
            data-testid={`checkbox-${damage.id}`}
          />
        </td>
        <td>
          <Link
            to={url}
            className="p-4 pl-1 block"
            title={damage.vehicle?.sku ?? ''}
          >
            {damage.vehicle?.vehicleSupplier?.name || '-'}
          </Link>
        </td>
        <td className="max-w-32">
          <Link to={url} className="p-4 pl-1 block truncate">
            {damage.vehicle?.sku || '-'}
          </Link>
        </td>
        <td>
          <Link to={url} className="p-4 pl-1 block">
            {t(
              `vehicle.operationalStatus.${damage.vehicle?.operationalStatus}`
            )}
          </Link>
        </td>
        <td className="max-w-prose">
          <Link to={url} className="p-4 pl-1 block truncate">
            <Badge
              value={damage.status}
              label={t(`vehicle.damageStatus.${damage.status}`)}
            />
          </Link>
        </td>
        <td className="max-w-prose">
          <Link to={url} className="p-4 pl-1 block truncate">
            {t(`vehicle.damageCode.${damage.code}`)}
          </Link>
        </td>
        <td className="w-full max-w-prose min-w-80">
          <Link to={url} className="p-4 pl-1 block">
            {damage.title || '-'}
          </Link>
        </td>
        <td>
          <Link to={url} className="p-4 pl-1 block">
            {damage?.vehicle?.hubSlug || '-'}
          </Link>
        </td>
        <td>
          <Link to={url} className="p-4 pl-1 block truncate">
            {damage.createdAt
              ? format(new Date(damage.createdAt), 'PP HH:mm')
              : '-'}
          </Link>
        </td>
        <td>
          <Link to={url} className="p-4 pl-1 block truncate">
            {damage.updatedAt
              ? format(new Date(damage.updatedAt), 'PP HH:mm')
              : '-'}
          </Link>
        </td>
      </>
    )
  }

  return (
    <div className="mt-8 max-w-full overflow-x-auto overflow-y-hidden relative">
      <div className="flex gap-x-2 items-center">
        <UpdateDamages
          action={DamageStatus.Fixed}
          labels={{
            button: 'FixDamages.button',
            title: 'FixDamages.title',
            message: 'FixDamages.fix.message',
            submit: 'FixDamages.fix.submit',
          }}
          damages={selectedDamages}
          isDisabled={
            !selectedDamages.length ||
            !isAllowed([Permission.WRITE_DAMAGES_ALL])
          }
          refetchDamages={refetchAndClear}
        />
        <UpdateDamages
          action={DamageStatus.Archived}
          labels={{
            button: 'ArchiveDamages.button',
            title: 'ArchiveDamages.title',
            message: 'ArchiveDamages.archive.message',
            submit: 'ArchiveDamages.archive.submit',
          }}
          damages={selectedDamages}
          isDisabled={
            !selectedDamages.length ||
            !isAllowed([Permission.WRITE_DAMAGES_ALL])
          }
          refetchDamages={refetchAndClear}
        />
      </div>
      <Table
        headers={headers}
        rows={damages}
        cells={cells}
        toggleAllCheckboxes={toggleAllCheckboxes}
      />
    </div>
  )
}

export default DamageTable
