import { Link, useMatch, useResolvedPath } from 'react-router-dom'

type Props = {
  to: string
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
  title: string
}

const SidebarLink = ({ to, icon: Icon, title }: Props) => {
  const resolved = useResolvedPath(to)
  const isMatch = useMatch({ path: resolved.pathname, end: true })

  return (
    <Link
      replace
      className={`border-b-2 md:border-b-0 rounded-none hover:text-primary py-2 pr-2 md:py-3 md:px-2 transition-all inline-flex items-center md:w-full ${
        isMatch
          ? 'border-primary md:bg-darkBlue md:rounded text-primary'
          : 'border-transparent'
      }`}
      to={to}
    >
      <Icon className="w-5 h-5 inline flex-shrink-0" />
      <span className="inline-block ml-1 md:ml-2 text-lg">{title}</span>
    </Link>
  )
}
export default SidebarLink
