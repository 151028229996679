import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import format from 'date-fns/format'
import { useForm, SubmitHandler } from 'react-hook-form'

import CustomSelect, { type Option } from '~/components/CustomSelect'
import Modal from '~/components/Modal'
import { damageCodeOptions, damageStatusOptions } from './helpers'
import {
  Damage,
  DamageCode,
  DamageStatus,
  useCreateDamageMutation,
  useUpdateDamageMutation,
  useUpdateOperationalStatusMutation,
  Vehicle,
  VehicleDamagesDocument,
  VehicleOperationalStatus,
} from '~/graphql/generated/types'

type EditDamage = Pick<
  Damage,
  'id' | 'title' | 'description' | 'code' | 'status' | 'createdAt'
>

type Props = {
  setDamageModalVisible: (value: boolean) => void
  isDamageModalVisible: boolean
  setStatusModalVisible: (value: boolean) => void
  isStatusModalVisible: boolean
  setArchiveModalVisible?: (value: boolean) => void
  isArchiveModalVisible?: boolean
  isEdit?: boolean
  damage?: EditDamage
  vehicle: {
    id: Vehicle['id']
    operationalStatus: Vehicle['operationalStatus']
    damages?: { nodes: Pick<Damage, 'id' | 'status'>[] }
  }
  isDisabled?: boolean
}
type InputFields = {
  title: string
  description: string
}

const NewDamage = ({
  setDamageModalVisible,
  isDamageModalVisible,
  setStatusModalVisible,
  isStatusModalVisible,
  isArchiveModalVisible = false,
  setArchiveModalVisible,
  isEdit,
  vehicle,
  damage,
  isDisabled,
}: Props) => {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    setValue,
    reset: resetForm,
    formState,
  } = useForm<InputFields>({
    defaultValues: isEdit
      ? { title: damage?.title, description: damage?.description }
      : {},
  })
  const [createDamageMutation] = useCreateDamageMutation({
    onCompleted: () => {
      setDamageModalVisible(false)
      resetForm()
      if (
        vehicle.operationalStatus !==
        VehicleOperationalStatus.MaintenanceRequired
      ) {
        setStatusModalVisible(true)
      }
    },
    refetchQueries: [
      { query: VehicleDamagesDocument, variables: { id: vehicle.id } },
    ],
  })
  const [updateDamageMutation] = useUpdateDamageMutation({
    onCompleted: (data) => {
      setDamageModalVisible(false)
      resetForm()

      if (
        data.updateDamage?.damage?.status === DamageStatus.Fixed &&
        vehicle.operationalStatus !== VehicleOperationalStatus.Operational
      ) {
        setStatusModalVisible(true)
      }
    },
  })

  const [updateOperationalStatusMutation] = useUpdateOperationalStatusMutation({
    variables: {
      id: vehicle.id,
      operationalStatus: isEdit
        ? VehicleOperationalStatus.Operational
        : VehicleOperationalStatus.MaintenanceRequired,
    },
  })

  const [code, setCode] = useState<Option>(
    isEdit
      ? damageCodeOptions(t).find((option) => option.value === damage?.code) ||
          damageCodeOptions(t)[0]
      : damageCodeOptions(t)[0]
  )
  const [status, setStatus] = useState<Option | undefined>(
    isEdit
      ? damageStatusOptions(t).find(
          (option) => option.value === damage?.status
        ) || damageStatusOptions(t)[0]
      : undefined
  )

  const onSubmit: SubmitHandler<InputFields> = (data) => {
    const commonFields = {
      title: data.title,
      description: data.description,
      code: code.value as DamageCode,
    }

    // show popup if user is archiving bike for confirmation
    if (status?.value == DamageStatus.Archived) {
      setArchiveDamageVariables({
        ...commonFields,
        id: damage?.id,
        status: status?.value as DamageStatus,
      } as EditDamage)
      setArchiveModalVisible && setArchiveModalVisible(true)
      setDamageModalVisible(false)
      return
    }

    return isEdit
      ? updateDamageMutation({
          variables: {
            id: damage?.id,
            status: status?.value as DamageStatus,
            ...commonFields,
          },
        })
      : createDamageMutation({
          variables: {
            vehicleId: vehicle.id,
            ...commonFields,
          },
        })
  }

  const [archiveDamageVariables, setArchiveDamageVariables] =
    useState<EditDamage>()

  const cancelArchiveDamage = () => {
    resetForm()
    setArchiveModalVisible && setArchiveModalVisible(false)
  }

  const archiveDamage = () => {
    archiveDamageVariables &&
      updateDamageMutation({
        variables: {
          ...archiveDamageVariables,
        },
      })
    setArchiveModalVisible && setArchiveModalVisible(false)
  }

  const updateOperationalStatus = async (
    operationalStatus: VehicleOperationalStatus
  ) => {
    if (operationalStatus === VehicleOperationalStatus.Operational) {
      await updateDamages()
    }
    await updateOperationalStatusMutation({
      variables: {
        id: vehicle.id,
        operationalStatus,
      },
    })
    setStatusModalVisible(false)
  }

  const updateDamages = async () => {
    const mutations = (vehicle.damages?.nodes || [])
      .filter((damage) => damage.status === DamageStatus.Reported)
      .map(async (damage) => {
        await updateDamageMutation({
          variables: {
            id: damage?.id,
            status: DamageStatus.Fixed,
          },
        })
      })

    await Promise.all(mutations)
  }

  const onBlur = (e: ChangeEvent<HTMLInputElement>) => {
    const field = e.target.getAttribute('name') as 'title' | 'description'
    setValue(field, e.target.value.trim())
  }

  return (
    <div>
      <form name="damage" onSubmit={handleSubmit(onSubmit)}>
        <Modal
          isOpen={isDamageModalVisible}
          onCancel={() => setDamageModalVisible(false)}
          submitText={t('NewDamage.save')}
          cancelText={t('cancel')}
          isDisabled={isDisabled || formState.isSubmitting}
        >
          <div className="px-6 pb-16">
            <div className="flex flex-row justify-between items-center pb-10">
              <h2 className="text-lg font-bold">{t('NewDamage.header')}</h2>
              <span className="text-sm">
                {format(
                  isEdit && damage ? new Date(damage.createdAt) : new Date(),
                  'PP HH:mm'
                )}
              </span>
            </div>
            <label className="form-field">
              <span className="form-label">
                {t('vehicle.damageReports.model.category')}
              </span>
              <CustomSelect
                options={damageCodeOptions(t)}
                selectedOption={code}
                onSelect={setCode}
                name="code"
                isDisabled={isDisabled}
              />
            </label>
            <label className="form-field">
              <span className="form-label">
                {t('vehicle.damageReports.model.title')}
              </span>
              <input
                {...register('title')}
                className="form-input"
                type="text"
                required
                disabled={isDisabled}
                onBlur={onBlur}
              />
            </label>
            <label className="form-field">
              <span className="form-label">
                {t('vehicle.damageReports.model.description')}
              </span>
              <input
                {...register('description')}
                className="form-input"
                type="text"
                required
                disabled={isDisabled}
                onBlur={onBlur}
              />
            </label>
            {isEdit && status && (
              <label className="form-field">
                <span className="form-label">
                  {t('vehicle.damageReports.model.status')}
                </span>
                <CustomSelect
                  options={damageStatusOptions(t, damage?.status)}
                  selectedOption={status}
                  onSelect={setStatus}
                  name="status"
                  isDisabled={isDisabled}
                />
              </label>
            )}
          </div>
        </Modal>
      </form>

      <Modal
        isOpen={isStatusModalVisible && isEdit}
        onCancel={() => setStatusModalVisible(false)}
        submitText={t('NewDamage.statusModal.operational.submit')}
        cancelText={t('cancel')}
        onConfirm={() =>
          updateOperationalStatus(VehicleOperationalStatus.Operational)
        }
      >
        <div className="px-6 pb-12 flex items-start flex-col text-left">
          <h2 className="text-lg font-bold">
            {t('NewDamage.statusModal.operational.header')}
          </h2>
          <div className="mt-2">
            <p className="text-sm">
              {t('NewDamage.statusModal.operational.message')}
              {isEdit &&
                (vehicle.damages?.nodes || []).filter(
                  (damage) => damage.status === DamageStatus.Reported
                ).length > 0 && (
                  <p>{t('NewDamage.statusModal.operational.damagesMessage')}</p>
                )}
            </p>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isStatusModalVisible && !isEdit}
        onCancel={() => setStatusModalVisible(false)}
      >
        <>
          <div className="px-6 pb-12 flex items-start flex-col text-left">
            <h2 className="text-lg font-bold">
              {t('NewDamage.statusModal.maintenance.header')}
            </h2>
            <div className="mt-2">
              <p className="text-sm">
                {t('NewDamage.statusModal.maintenance.message')}
              </p>
            </div>
          </div>
          <div className="w-full flex flex-row justify-end px-6 gap-4">
            <button
              type="button"
              className="font-semibold py-2 px-4 text-sm rounded text-gray-800 bg-gray-100  min-w-48 w-auto text-center"
              onClick={() => setStatusModalVisible(false)}
            >
              {t('cancel')}
            </button>
            {vehicle.operationalStatus !==
              VehicleOperationalStatus.PartlyOperational && (
              <button
                className="primary-btn min-w-48 w-auto text-center ml-auto"
                onClick={() =>
                  updateOperationalStatus(
                    VehicleOperationalStatus.PartlyOperational
                  )
                }
              >
                {t('NewDamage.statusModal.partlyOperational.submit')}
              </button>
            )}
            <button
              className="primary-btn min-w-48 w-auto text-center"
              onClick={() =>
                updateOperationalStatus(
                  VehicleOperationalStatus.MaintenanceRequired
                )
              }
            >
              {t('NewDamage.statusModal.maintenance.submit')}
            </button>
          </div>
        </>
      </Modal>

      <Modal
        isOpen={isArchiveModalVisible}
        onCancel={cancelArchiveDamage}
        submitText={t('ArchiveVehicle.archive.submit')}
        cancelText={t('cancel')}
        onConfirm={archiveDamage}
      >
        <div className="px-6 pb-12">
          <div className="flex items-start">
            <div className="text-left">
              <h2 className="text-lg font-bold">
                {t('NewDamage.archive.title')}
              </h2>
              <div className="mt-2">
                <p className="text-sm">{t('NewDamage.archive.message')}</p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default NewDamage
