import { TFunction } from 'i18next'
import { RiderStatus } from '~/graphql/generated/types'

export type RiderStatusFilter = RiderStatus | ''

export const riderStatusOptions = (t: TFunction) =>
  ['', ...Object.values(RiderStatus)].map((value) => ({
    value: value,
    label: value === '' ? t('all') : t(`${value}`),
  }))
