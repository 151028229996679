import React, { createContext } from 'react'
import { Auth0Client } from '@auth0/auth0-spa-js'

export const AuthClientContext = createContext<Auth0Client | null>(null)

export const AuthClientContextManager = ({
  authClient,
  children,
}: {
  authClient: Auth0Client
  children: React.ReactNode
}) => {
  return (
    <AuthClientContext.Provider value={authClient}>
      {children}
    </AuthClientContext.Provider>
  )
}
