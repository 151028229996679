import { TFunction } from 'i18next'
import { DamageCode, DamageStatus } from '~/graphql/generated/types'
import { type Option } from '~/components/CustomSelect'

export const damageCodeOptions = (t: TFunction): Option[] =>
  Object.entries(DamageCode).map(([, value]) => ({
    value,
    label: t(`vehicle.damageCode.${value}`),
  }))

export const damageStatusOptions = (
  t: TFunction,
  damageStatus?: DamageStatus
): Option[] =>
  Object.entries(DamageStatus).map(([, value]) => ({
    value,
    label: t(`vehicle.damageStatus.${value}`),
    isDisabled:
      (damageStatus === DamageStatus.FixedPendingApproval &&
        value === DamageStatus.Archived) ||
      (damageStatus !== DamageStatus.FixedPendingApproval &&
        value === DamageStatus.FixedPendingApproval) ||
      (damageStatus === DamageStatus.FixedPendingApproval &&
        value === DamageStatus.Reported),
  }))
