import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Modal from '~/components/Modal'
import { Checkbox } from '@hubportal/components'
import { TableDamage } from '~/components/DamageTable/DamageTable'
import {
  DamageStatus,
  useUpdateDamageMutation,
  useUpdateOperationalStatusMutation,
  useVehiclesByIdsAndDamageStatusQuery,
  Vehicle,
  VehicleOperationalStatus,
} from '~/graphql/generated/types'

type Props = {
  damages: TableDamage[]
  isDisabled?: boolean
  refetchDamages: () => void
  action: DamageStatus
  labels: {
    button: string
    title: string
    message: string
    submit: string
  }
}

type StatusVehicles = {
  withoutReportedDamages: Vehicle[]
  withReportedDamages: Vehicle[]
}

const UpdateDamages = ({
  damages,
  isDisabled,
  refetchDamages,
  action,
  labels,
}: Props) => {
  const { t } = useTranslation()
  const [isModalVisible, showModal] = useState(false)
  const [isStatusModalVisible, setStatusModalVisible] = useState(false)
  const [statusVehicles, setStatusVehicles] = useState<StatusVehicles | null>()
  const [selectedVehicleIds, setSelectedVehiclesIds] = useState<string[]>([])
  const [isUpdating, setUpdating] = useState(false)
  const [updateDamageMutation] = useUpdateDamageMutation()
  const [updateOperationalStatusMutation] = useUpdateOperationalStatusMutation()

  const { refetch: refetchVehicles } = useVehiclesByIdsAndDamageStatusQuery({
    skip: true,
    fetchPolicy: 'no-cache',
  })

  const updateSelectedDamages = async () => {
    setUpdating(true)
    const mutations = damages.map(async (damage: TableDamage) => {
      const { data } = await updateDamageMutation({
        variables: {
          id: damage?.id,
          status: action,
        },
      })

      if (
        data?.updateDamage?.damage?.status === DamageStatus.Fixed &&
        data?.updateDamage?.vehicle?.operationalStatus !==
          VehicleOperationalStatus.Operational
      ) {
        return data?.updateDamage?.vehicle
      }
    })

    try {
      const vehicles = (await Promise.all(mutations)).filter(
        Boolean
      ) as Vehicle[]

      const uniqueVehicles = Array.from(new Set(vehicles.map((v) => v.id))).map(
        (id) => {
          return vehicles.find((v) => v.id === id)
        }
      ) as Vehicle[]

      if (uniqueVehicles.length > 0) {
        const { data } = await refetchVehicles({
          ids: vehicles.map((v) => v.id),
          status: DamageStatus.Reported,
        })

        const statusVehicles = data?.vehicles?.nodes.reduce(
          (arr, v) => {
            const key =
              v.damages?.totalCount === 0
                ? 'withoutReportedDamages'
                : 'withReportedDamages'
            return {
              ...arr,
              [key]: [...arr[key], v],
            }
          },
          { withReportedDamages: [], withoutReportedDamages: [] }
        )

        setStatusVehicles(statusVehicles as StatusVehicles)
        setStatusModalVisible(true)
      }
    } catch (err) {
      console.error(err)
    } finally {
      setUpdating(false)
      showModal(false)
      refetchDamages()
    }
  }

  const handleUpdateSelectedVehicles = async (confirmed: boolean) => {
    setUpdating(true)
    const mutations = (statusVehicles?.withoutReportedDamages || []).map(
      async (v) => {
        await updateOperationalStatusMutation({
          variables: {
            id: v.id,
            operationalStatus: VehicleOperationalStatus.Operational,
          },
        })
      }
    )

    if (confirmed) {
      const partlyOperationalMutations = (selectedVehicleIds || []).map(
        async (id: string) => {
          await updateOperationalStatusMutation({
            variables: {
              id,
              operationalStatus: VehicleOperationalStatus.PartlyOperational,
            },
          })
        }
      )
      mutations.concat(partlyOperationalMutations)
    }

    try {
      await Promise.all(mutations)
    } catch (err) {
      console.error(err)
    } finally {
      setUpdating(false)
      setStatusModalVisible(false)
      setStatusVehicles(null)
      setSelectedVehiclesIds([])
      refetchDamages()
    }
  }

  const toggleSelectedVehicle = (id: string) => (checked: boolean) => {
    if (checked) {
      setSelectedVehiclesIds([...selectedVehicleIds, id])
    } else {
      setSelectedVehiclesIds(
        selectedVehicleIds.filter((vehicleId) => vehicleId !== id)
      )
    }
  }

  return (
    <>
      <div className="text-sm flex flex-col w-fit">
        <button
          className="primary-btn font-bold text-base"
          onClick={() => showModal(true)}
          disabled={isDisabled}
          data-testid="button"
        >
          {t(labels.button)}
        </button>
      </div>
      <Modal
        isOpen={isModalVisible}
        onCancel={() => showModal(false)}
        submitText={t(labels.submit)}
        cancelText={t('cancel')}
        onConfirm={updateSelectedDamages}
        isDisabled={isUpdating}
      >
        <div className="px-6 pb-12">
          <div className="flex items-start">
            <div className="text-left">
              <h2 className="text-lg font-bold">
                {t(labels.title, { count: damages.length })}
              </h2>
              <div className="mt-2">
                <p className="text-sm">
                  {t(labels.message, {
                    count: damages.length,
                  })}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isStatusModalVisible}
        onCancel={() => handleUpdateSelectedVehicles(false)}
        submitText={t('FixDamages.statusModal.submit')}
        cancelText={t('cancel')}
        onConfirm={() => handleUpdateSelectedVehicles(true)}
        isDisabled={isUpdating}
      >
        <div className="px-6 pb-12 flex items-start flex-col text-left gap-8">
          {(statusVehicles?.withoutReportedDamages || []).length > 0 && (
            <div>
              <h2 className="text-lg font-bold">
                {t('NewDamage.statusModal.operational.submit')}
              </h2>
              <div className="my-2">
                <p className="text-sm">
                  {t('FixDamages.statusModal.operational.message')}
                </p>
              </div>
              <div className="flex flex-col gap-4 mt-4">
                {statusVehicles?.withoutReportedDamages.map((v) => (
                  <Checkbox
                    id={v.id}
                    key={v.id}
                    checked={true}
                    disabled={true}
                    onChange={() => null}
                  >
                    {v.sku}
                  </Checkbox>
                ))}
              </div>
            </div>
          )}
          {(statusVehicles?.withReportedDamages || []).length > 0 && (
            <div>
              <h2 className="text-lg font-bold">
                {t('NewDamage.statusModal.partlyOperational.submit')}
              </h2>
              <div className="my-2">
                <p className="text-sm">
                  {t('FixDamages.statusModal.partlyOperational.message')}
                </p>
              </div>
              <div className="flex flex-col gap-4 mt-4">
                {statusVehicles?.withReportedDamages.map((v) => (
                  <Checkbox
                    id={v.id}
                    key={v.id}
                    checked={selectedVehicleIds.includes(v.id)}
                    onChange={toggleSelectedVehicle(v.id)}
                  >
                    {`${v.sku} ${` ( ${v.damages.totalCount} ${t(
                      'reportedDamages'
                    )})`}`}
                  </Checkbox>
                ))}
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}

export default UpdateDamages
