import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'
import Root from './root.component'

import singleSpaCss from 'single-spa-css'

const cssLifecycles = singleSpaCss({
  cssUrls: [],
  webpackExtractedCss: process.env.NODE_ENV === 'production',
  shouldUnmount: true,
})

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  // TODO: Add error boundries
  /* eslint-disable */
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return <div>-</div>
  },
})

export const bootstrap = [cssLifecycles.bootstrap, lifecycles.bootstrap]

export const mount = [cssLifecycles.mount, lifecycles.mount]

export const unmount = [lifecycles.unmount, cssLifecycles.unmount]
