import { useCallback, useEffect, useMemo } from 'react'
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Header, Input, Tab, Tabs } from '@hubportal/components'
import { useDebounce } from '~/hooks'
import { getSearchParams } from '~/utils/search-params'
import { DamageStatus } from '~/graphql/generated/types'

const FleetTabs = {
  vehicles: 'vehicles',
  damages: 'damages',
}

const Index = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [searchParams] = useSearchParams()
  const { search } = getSearchParams(searchParams)

  const [debouncedSearchText, searchText, setDebouncedSearchText] =
    useDebounce<string>(search ?? '', 250)

  useEffect(() => {
    setDebouncedSearchText(search ?? '')
  }, [search, setDebouncedSearchText])

  const changeTab = useCallback((id: string) => {
    if (id === FleetTabs.damages) {
      navigate(`damages?damageStatus=${DamageStatus.Reported}`)
    } else {
      navigate('')
    }
  }, [])

  const currentTab = useMemo(() => {
    if (location.pathname === '/vehicles/damages') {
      return FleetTabs.damages
    }
    return FleetTabs.vehicles
  }, [location.pathname])

  return (
    <>
      <Header title={t('title')}>
        <div className="flex gap-6">
          <Tabs value={currentTab} onChange={changeTab}>
            <Tab id={FleetTabs.vehicles} icon="bicycle">
              {t('tabs.vehicles')}
            </Tab>
            <Tab id={FleetTabs.damages} icon="infoSquare">
              {t('tabs.damages')}
            </Tab>
          </Tabs>

          {currentTab === FleetTabs.vehicles && (
            <div className="w-52">
              <Input
                value={searchText}
                onChange={(e) => setDebouncedSearchText(e.target.value)}
                icon="magnify"
                placeholder={t('vehicle.search')}
              />
            </div>
          )}
        </div>
      </Header>
      <div className="mt-4 relative px-10">
        <Outlet context={{ search: debouncedSearchText }} />
      </div>
    </>
  )
}

export default Index
