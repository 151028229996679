import { Hub, useVehicleOptionsQuery } from '~/graphql/generated/types'
import { type Option } from '~/components/CustomSelect'

export const useFilterOptions = () => {
  const { data: filterOptions } = useVehicleOptionsQuery()

  const hubSlugs = filterOptions?.allHubs
    ? (filterOptions.allHubs.map((item: Hub) => item.slug) as string[])
    : []

  const hubOptions: Option[] = hubSlugs.map((item) => ({
    value: item,
    label: item,
  }))

  const supplierOptions: Option[] = filterOptions?.suppliers
    ? filterOptions.suppliers.nodes.map((s) => ({ value: s.id, label: s.name }))
    : []

  return [hubOptions, supplierOptions]
}
