import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Modal from '~/components/Modal'
import { TableVehicle } from '~/components/VehicleTable/VehicleTable'
import { useArchiveVehicleMutation } from '~/graphql/generated/types'

type Props = {
  vehicles: TableVehicle[]
  isDisabled?: boolean
  refetchVehicles: () => void
}

const ArchiveVehicles = ({ vehicles, isDisabled, refetchVehicles }: Props) => {
  const { t } = useTranslation()
  const [isModalVisible, showModal] = useState(false)
  const [isArchiving, setArchiving] = useState(false)
  const [archiveVehicleMutation] = useArchiveVehicleMutation({
    onCompleted: (res) => {
      const id = res.archiveVehicleById?.vehicle?.id
      const vehiclesLastIndex = vehicles.length - 1
      const index = vehicles.findIndex(
        (vehicle: TableVehicle) => vehicle.id === id
      )

      if (index === vehiclesLastIndex) {
        setArchiving(false)
        showModal(false)
        refetchVehicles()
      }
    },
  })

  const archiveSelectedVehicles = () => {
    setArchiving(true)
    vehicles.forEach((vehicle: TableVehicle) => {
      archiveVehicleMutation({ variables: { id: vehicle.id } })
    })
  }

  return (
    <>
      <div className="text-sm flex flex-col w-fit">
        <button
          className="primary-btn font-bold text-base"
          onClick={() => showModal(true)}
          disabled={isDisabled}
          data-testid="button"
        >
          {t('ArchiveVehicles.button')}
        </button>
      </div>
      <Modal
        isOpen={isModalVisible}
        onCancel={() => showModal(false)}
        submitText={t('ArchiveVehicle.archive.submit')}
        cancelText={t('cancel')}
        onConfirm={archiveSelectedVehicles}
        isDisabled={isArchiving}
      >
        <div className="px-6 pb-12">
          <div className="flex items-start">
            <div className="text-left">
              <h2 className="text-lg font-bold">
                {t('ArchiveVehicles.title', { count: vehicles.length })}
              </h2>
              <div className="mt-2">
                <p className="text-sm">
                  {t('ArchiveVehicles.archive.message', {
                    count: vehicles.length,
                  })}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ArchiveVehicles
