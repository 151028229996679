import { useState } from 'react'
import { useMutation, useApolloClient, ApolloError } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
  CreateVehicleDocument,
  VehicleInput,
  useVehicleOptionsQuery,
  LocksByReferenceDocument,
  ConflictingVehiclesDocument,
} from '~/graphql/generated/types'
import { VehicleInputType } from '~/components/VehicleForm/VehicleForm'
import Modal from '~/components/Modal/Modal'
import VehicleForm from '~/components/VehicleForm'
import Sidebar from '~/components/Sidebar'
import { isBluetoothBike } from '~/pages/vehicles/helpers'
import { BluetoothInputType, ConflictingVehicle } from '~/pages/vehicles/types'
import { GraphileError, missingBlutoothLockErrorCode } from '~/utils/errors'
import { Permission, usePermissions } from '~/hooks'

export default function AddVehicle() {
  const { t } = useTranslation('translation')
  const { isAllowed } = usePermissions()

  const navigate = useNavigate()
  const [customError, setCustomError] = useState<ApolloError | undefined>()
  const [conflictingVehicle, setConflictingVehicle] = useState<
    ConflictingVehicle | undefined
  >()
  const [isConflictModalVisible, setConflictModalVisible] = useState(false)

  const apolloClient = useApolloClient()
  const [createVehicle, action] = useMutation(CreateVehicleDocument, {
    onCompleted: (data) =>
      navigate(`/vehicles/${data.createVehicle.vehicle.id}`, {
        replace: true,
      }),
  })
  const { data: vehicleOptions } = useVehicleOptionsQuery()

  const isAllowedToCreate = async (vehicle: VehicleInputType) => {
    try {
      const conflictingVehicles = await apolloClient.query({
        query: ConflictingVehiclesDocument,
        variables: {
          licensePlate: vehicle.licensePlate,
          supplierId: vehicle.supplierId,
        },
        fetchPolicy: 'no-cache',
      })
      if (conflictingVehicles.data.vehicles.nodes.length) {
        setConflictingVehicle(conflictingVehicles.data.vehicles.nodes[0])
        setConflictModalVisible(true)
        return false
      }
    } catch (error) {
      setCustomError(error as ApolloError)
      return false
    }
    return true
  }

  const getBluetoothData = async (vehicle: VehicleInputType) => {
    const bluetoothData: BluetoothInputType = {
      moduleId: null,
      bluetooth: null,
      provider: null,
    }
    const [bluetooth, provider] = isBluetoothBike(
      vehicle.supplierId,
      vehicle.modelId,
      vehicleOptions?.suppliers?.nodes
    )

    if (bluetooth) {
      try {
        const locks = await apolloClient.query({
          query: LocksByReferenceDocument,
          variables: { references: [vehicle.licensePlate] },
          fetchPolicy: 'no-cache',
        })
        if (locks.data.locksByReference.length === 0) {
          setCustomError(
            new ApolloError({
              graphQLErrors: [
                {
                  errcode: missingBlutoothLockErrorCode,
                  detail: 'Cannot find Bluetooth lock',
                } as GraphileError,
              ],
            })
          )
          return null
        }
        bluetoothData.moduleId = locks.data.locksByReference[0].id
        bluetoothData.bluetooth = true
        bluetoothData.provider = provider
      } catch (error) {
        setCustomError(error as ApolloError)
        return null
      }
    }

    return bluetoothData
  }

  const handleSubmit = async (vehicle: VehicleInputType) => {
    const bluetoothData: BluetoothInputType | null = await getBluetoothData(
      vehicle
    )
    if (!(await isAllowedToCreate(vehicle)) || bluetoothData === null) {
      return
    }

    const vehicleInput: VehicleInput = {
      ...vehicle,
      ...bluetoothData,
    }
    Object.entries(vehicleInput).forEach(([key, val]) => {
      if (!val) delete vehicleInput[key as keyof VehicleInput]
    })

    createVehicle({
      variables: {
        input: {
          vehicle: vehicleInput,
        },
      },
    })
  }

  return (
    <div className="flex flex-col md:flex-row h-full px-10">
      <Sidebar title={t('VehicleForm.newVehicle')} hideSidebarLinks />
      <div className="w-full px-4 md:px-8 py-10">
        <Modal
          isOpen={isConflictModalVisible}
          onCancel={() => setConflictModalVisible(false)}
          submitText={t('AddVehicle.conflictModal.goToVehicle')}
          cancelText={t('cancel')}
          onConfirm={() =>
            navigate(`/vehicles/${conflictingVehicle?.id}`, { replace: true })
          }
        >
          <div className="px-6 pb-12">
            {t('AddVehicle.conflictModal.message', {
              licensePlate: conflictingVehicle?.licensePlate,
              hubSlug: conflictingVehicle?.hubSlug,
            })}
          </div>
        </Modal>
        <VehicleForm
          error={customError ?? action.error}
          isSubmitting={action.loading}
          isCreationForm
          onSubmit={handleSubmit}
          isDisabled={!isAllowed([Permission.WRITE_VEHICLES_ALL])}
        />
      </div>
    </div>
  )
}
